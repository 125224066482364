import React from 'react';
import PropTypes from 'prop-types';

// HOC
import withField from 'hoc/withField';

interface FieldProps {
  field: any,
  meta: any, 
}

interface WidgetProps extends FieldProps {
  name: string,
  placeholder: string | any,
  help: string | any,
  disabled: boolean | any,
}

const EmailInput:React.FC<WidgetProps> = ({
  field,
  meta,
  name,
  placeholder,
  help,
  disabled,
}) => {

  return (
    <React.Fragment>
      <input
        {...field}
        type="email"
        id={name}
        placeholder={placeholder}
        disabled={disabled}
        className={`form-control ${meta.touched && meta.error && 'is-invalid'}`}
      />
      {
        meta.touched && meta.error && (
          <div className="invalid-feedback">
            { meta.error }
          </div>
        )
      }
      { 
        help && (
          <small className="form-text text-muted">
            { help }
          </small>
        )
      }
    </React.Fragment>
  )
};

EmailInput.propTypes = {
  field: PropTypes.shape({
    name: PropTypes.string.isRequired,
    onBlur: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    value: PropTypes.any 
  }),
  meta: PropTypes.shape({
    error: PropTypes.string,
    touched: PropTypes.bool,
  }),
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  help: PropTypes.string,
  disabled: PropTypes.bool,
};

EmailInput.defaultProps = {
  placeholder: null,
  help: null,
  disabled: false,
};

export default withField(EmailInput);
