import React from 'react';
import PropTypes from 'prop-types';
import DOMPurify from 'dompurify';

// Constants
import htmlRestrictions from 'constants/htmlRestrictions';

interface htmlProps {
  type:string,
  html:string,
  element?: string | any,
  rest?: any
}

const RestrictedHtml: React.FC<htmlProps> = ({type, html, element, ...rest }) => {
  const restrictions = htmlRestrictions.find(el => el[type]);
  const safeHtml = DOMPurify.sanitize(html, restrictions[type]);
  return React.createElement(element, { dangerouslySetInnerHTML: { __html: safeHtml }, ...rest });
};

RestrictedHtml.propTypes = {
  html: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  element: PropTypes.string
};

RestrictedHtml.defaultProps = {
  element: 'div',
};

export default RestrictedHtml;