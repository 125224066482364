import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import { useFormikContext } from 'formik';

// Components 
import Spinner from 'components/common/Spinner';

interface IProps {
  settings: any,
  className?: string | any,
  children: React.ReactNode | any
}

const SubmitButton:React.FC<IProps> = ({ settings, className, children }) => {
  const { show, disabled } = settings;
  const classes = classNames(className);
  const { isSubmitting, isValid } = useFormikContext(); 
  return (
    <React.Fragment>
      {isSubmitting ? (
        <Spinner
          loader="Oval"
          position="center"
          width="40"
          height="40"
        />
      ) : show && (
        <button 
          type="submit"
          className={classes} 
          disabled={isSubmitting || (!isValid && disabled)}
        >
          {children}
        </button>
      )}
    </React.Fragment>
   
  )
};

SubmitButton.propTypes = {
  settings: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired, 
  className: PropTypes.string,
}

SubmitButton.defaultProps = {
  className: 'btn btn-sm-block btn-primary btn-lg',
};

export default SubmitButton;
