import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types'; 
import { isEmpty } from 'ramda';
import { pick as stripePick } from 'query-string';
import { useLocation } from 'react-router-dom';
import { loadStripe } from '@stripe/stripe-js';

// Fetching
import { fetchPayments } from 'api/dataFetching';

// Components
import Spinner from 'components/common/Spinner';

// HoC
import withField from 'hoc/withField';

interface FieldProps {
  field: any;
  meta: any;
  helpers: any;
}

interface WidgetProps extends FieldProps {
  help: string | any;
  name: string;
  popover: string | any;
  references: [] | any;
  widget: any;
}

const Stripe: React.FC<WidgetProps> = ({
  field,
  help,
  helpers,
  meta,
  widget,
}) => {
  const {
    settings: {
      publishableKey,
      stripeAccount,
      sessionId,
      currency,
      price,
      orderNumber,
    },
  } = widget;
  const { search: queryParams } = useLocation();
  const redirect = stripePick(queryParams, ['redirect']);
  const [pay, setPay] = useState(true);
  const [stripe, setStripe] = useState({}); 
  useEffect(() => {
    (async () => {
      const res = await loadStripe(publishableKey, {
        stripeAccount: stripeAccount,
      });
      setStripe(res);
    })();
  }, []);

  const stripeCheckout = () => {
    setPay(false);
    return stripe.redirectToCheckout({ sessionId: sessionId });
  };

  // Todo: Move to enums
  enum PaymentStatus {
    CONFIRMED = 'CONFIRMED',
    PENDING = 'PENDING',
    COMPLETED = 'REJECTED',
    CANCELLED = 'CANCELLED',
  }

  const multitry = (time, tries) => {
    let counter = 0;
    const timer = setInterval(async function() {
      counter++;
      try {
        const res = await fetchPayments(orderNumber);
        const {
          data: {
            payment: { status },
          },
        } = res;
        if (status === PaymentStatus.CONFIRMED) {
          helpers.setValue(true, true);
          setPay(false);
          clearInterval(timer);
        } else if (status === PaymentStatus.PENDING) {
          setPay(false);
        } else {
          setPay(true);
          clearInterval(timer);
        }
      } catch (error) {
        console.log(error);
      }

      if (counter >= tries) {
        clearInterval(timer);
        setPay(true);
      }
    }, time);
  };

  useEffect(() => {
    if (!isEmpty(redirect)) {
      setPay(false);
      multitry(2000, 5);
    }
  }, []);

  return (
    <React.Fragment>
      <div className='row no-gutters'>
        <div
          className={`col-lg-9 col-xl-6 box mb-2
                        ${meta.touched && meta.error && 'box--error'}`}
        >
          <div className='box__body'>
            <div className='d-flex justify-content-between align-items-center'>
              <span className='h4 text-primary'>Total a pagar</span>
              <span className='form-text text-muted'>
                ${price} {currency}
              </span>
            </div>
            <div className='box__footer mt-3 pt-3'>
              {!field.value ? (
                pay ? (
                  <button
                    type='button'
                    className='btn btn-success btn-block'
                    onClick={() => stripeCheckout()}
                  >
                    Pagar con Stripe
                  </button>
                ) : (
                  <Spinner
                    loader='Oval'
                    position='center'
                    width='40'
                    height='40'
                  />
                )
              ) : (
                <p className='text-success text-center'>Tu pago fue exitoso!</p>
              )}
            </div>
          </div>
        </div>
      </div>

      {meta.touched && meta.error && (
        <div className='invalid-feedback d-block'>{meta.error}</div>
      )}
      {help && <small className='form-text text-muted'>{help}</small>}
    </React.Fragment>
  );
};

Stripe.propTypes = {
  field: PropTypes.shape({
    name: PropTypes.string.isRequired,
    onBlur: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    value: PropTypes.any,
  }),
  meta: PropTypes.shape({
    error: PropTypes.string,
    touched: PropTypes.bool,
  }),
  helpers: PropTypes.shape({
    setValue: PropTypes.func.isRequired,
  }),
  name: PropTypes.string.isRequired,
  widget: PropTypes.object.isRequired,
  help: PropTypes.string,
};

Stripe.defaultProps = {
  help: null,
};

export default withField(Stripe);
