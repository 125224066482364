import thunk from 'redux-thunk';
import logger from 'redux-logger';
import reduxMulti from 'redux-multi';
import { 
  createStore,
  compose,
  applyMiddleware,
} from 'redux';

import rootReducer from 'store/reducers/rootReducer';

const env = process.env.NODE_ENV;
let middlewares = [] as any;

const composeEnhancers = env !== 'production' &&
  typeof window === 'object' &&
 (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ||
  compose;

if(env !== 'production') {
  middlewares = [...middlewares, thunk, reduxMulti];
} else {
  middlewares = [...middlewares, thunk, reduxMulti];
}

const middleware = applyMiddleware(...middlewares);

const enhancer = composeEnhancers(middleware);

const store = createStore(rootReducer, enhancer);

export type RootState = ReturnType<typeof store.getState>

export type rootStore = ReturnType<typeof rootReducer>

export default store;