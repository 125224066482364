import React from 'react';
import { BrowserRouter,
  Switch,
  Route,
  Redirect
} from 'react-router-dom';
import PropTypes from 'prop-types';
import FlowRouter from './FlowRouter';

const Router = ({children}) => {
  return(
    <BrowserRouter>
      <Route
        render={({location: { pathname } }) => {
          const hasSlash= pathname.slice(-1) === '/' && pathname !== '/'
          return hasSlash ? <Redirect to={`${pathname.slice(0, -1)}`} /> : children
        }}
      />
    </BrowserRouter>
  )
}

const AppRouter: React.FC = () => {
  return (
    <Router>
      <Switch>
        <Route path='/:orgId/:flowId/' component={ FlowRouter } />
        <Route path='/*' component={() => { 
          window.location.href = 'https://pefai.com/';
          return null;
        }}/>
      </Switch>
    </Router>
  );
};

Router.propTypes = {
  children: PropTypes.node,
}
export default AppRouter;
