import React from 'react';
import PropTypes from 'prop-types';
import { isNil } from 'ramda';

interface IProps {
  src: string,
  altText: string,
  alignment: string | any
}

const Image: React.FC<IProps> = ({ src, altText, alignment, ...rest }) => {
  return (
    <div 
      style={!isNil(alignment) 
        ? { textAlign: alignment } 
        : { display: 'inline' }
      } 
      id="org-logo" 
      {...rest}
    >
      <img src={src} alt={altText} />
    </div>
    
  )
};

Image.propTypes = {
  src: PropTypes.string.isRequired,
  altText: PropTypes.string.isRequired,
  alignment: PropTypes.string 
};

Image.defaultProps = {
  alignment: null
};

export default Image;
