import React from 'react';
import Loader from 'react-loader-spinner';
import PropTypes from 'prop-types';

interface IProps {
  loader: string | any,
  position: string | any,
  width: string | any,
  height: string | any
}

const Spinner:React.FC<IProps> = ({
  loader,
  position,
  width,
  height,
}) => {
  return (
    <div className={`spinner ${position}`}>
      <Loader type={loader} width={width} height={height} />
    </div>
  );
};

Spinner.propTypes = {
  loader: PropTypes.string,
  position: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string
};

Spinner.defaultProps={
  loader: 'Oval',
  position: 'middle',
  width: '40',
  height: '40',
};

export default Spinner;
