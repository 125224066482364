import React from 'react';
import PropTypes from 'prop-types';

// HoC
import withField from 'hoc/withField';
interface FieldProps {
  field: any;
  meta: any;
}

interface WidgetProps extends FieldProps {
  name: string; 
  disabled: boolean | any;
  options: [] | any; 
  help: string | any; 
}

const ToggleField: React.FC<WidgetProps> = ({
  disabled,
  field,
  help,
  meta,
  name,  
}) => {

  return (
    <React.Fragment>
      <div
        className={`form-check ${meta.touched && meta.error && 'is-invalid'}`}
      >
        <input
          {...field}
          type='checkbox'
          id={`checkbox-${name}`}
          disabled={disabled}
          autoComplete='off'
          checked={field.value}
          className='form-check-input'
        />

        {help && <label htmlFor={`checkbox-${name}`}>{help}</label>}
      </div>
      {meta.touched && meta.error && (
        <div className='invalid-feedback'>{meta.error}</div>
      )}
    </React.Fragment>
  );
};

ToggleField.propTypes = {
  field: PropTypes.shape({
    name: PropTypes.string.isRequired,
    onBlur: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    value: PropTypes.any,
  }),
  meta: PropTypes.shape({
    error: PropTypes.string,
    touched: PropTypes.bool,
  }),
  name: PropTypes.string.isRequired,  
  help: PropTypes.string,
  disabled: PropTypes.bool, 
};

ToggleField.defaultProps = {
  help: null,
  disabled: false,
};

export default withField(ToggleField);
 