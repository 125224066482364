import React from 'react';
import PropTypes from 'prop-types';
import { isNil } from 'ramda';

// HOC
import withField from 'hoc/withField';

interface FieldProps {
  field: any;
  meta: any;
}

interface WidgetProps extends FieldProps {
  disabled: boolean | any;
  help: string | any;
  label: string;
  multiple: [] | any;
  name: string;
  options: [] | any;
  placeholder: string | any;
  popover: string | any;
}

const SelectField: React.FC<WidgetProps> = ({
  disabled,
  field,
  help,
  meta,
  multiple,
  name,
  options,
  placeholder,
}) => {
  return (
    <React.Fragment>
      <select
        {...field}
        id={name}
        disabled={disabled}
        multiple={!isNil(multiple) ? true : false}
        className={`custom-select ${
          meta.touched && meta.error && 'is-invalid'
        }`}
      >
        <option value=''>{placeholder ? placeholder : '- Selecciona -'}</option>
        {options.map((optn, i) => (
          <option
            key={`${name}-${i}`}
            value={optn.value}
            disabled={!isNil(optn.disabled) ? optn.disabled : false}
          >
            {optn.label || optn.value}
          </option>
        ))}
      </select>
      {meta.touched && meta.error && (
        <div className='invalid-feedback'>{meta.error}</div>
      )}
      {help && <small className='form-text text-muted'>{help}</small>}
    </React.Fragment>
  );
};

SelectField.propTypes = {
  field: PropTypes.shape({
    name: PropTypes.string.isRequired,
    onBlur: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    value: PropTypes.any 
  }),
  meta: PropTypes.shape({
    error: PropTypes.string,
    touched: PropTypes.bool,
  }),
  name: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
  multiple: PropTypes.array,
  placeholder: PropTypes.string,
  help: PropTypes.string,
  disabled: PropTypes.bool,
};

SelectField.defaultProps = {
  multiple: null,
  placeholder: null,
  popover: null,
  help: null,
  disabled: false,
};

export default withField(SelectField); 
