import React, { useEffect } from 'react';
import { UncontrolledAlert as UncotrolledAlertWrapper } from 'reactstrap';
import PropTypes from 'prop-types';
import { scrollToTop } from 'utils/sharedFuncs';

interface IProps {
  color: string;
  children: React.ReactNode | any;
}
const UncontrolledAlert: React.FC<IProps> = ({ color, children, ...rest }) => {

  useEffect(()=>{
    scrollToTop();
  },[])

  return (
    <>
      <UncotrolledAlertWrapper color={color} {...rest}>
        {children}
      </UncotrolledAlertWrapper>
    </>
  );
};

UncontrolledAlert.propTypes = {
  color: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

export default UncontrolledAlert;
