import React from 'react';
import { useSelector } from 'react-redux';
import { filter, compose, not, propEq } from 'ramda';

// Store
import { rootStore } from 'store/store';

// Components
import SectionItem from './SectionItem';

const SectionsNav: React.FC = () => {
  const { flowSections } = useSelector(({ sections }: rootStore) => sections);

  const sections = filter(compose(not, propEq('showNav', false)), flowSections.sections)

  return (
    <ol className="nav flex-column mt-3" id="sections-nav">
      {
        sections.map(section => {
          return (
            <li 
              className="nav-item"
              key={section.id}
            >
              <SectionItem section={section} />
            </li>
          )
        })
      }
    </ol>
  )
};

export default SectionsNav;