import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { where, is } from 'ramda';
import { GoogleMap, Marker, useLoadScript } from '@react-google-maps/api';

// HOC
import withField from 'hoc/withField';

interface FieldProps {
  field: any,
  meta: any,
  helpers: any,
  clearValue:() => void
}

interface WidgetProps extends FieldProps {
  name: string,
  widget: any,
  placeholder: string | any,
  help: string | any,
}

const GmapsPinpointField:React.FC<WidgetProps> = ({
  field,
  meta,
  helpers,
  name,
  widget,
  help,
}) => {
  
  const { settings: { draggableMap, scrollwheel, zoom, draggableMarker}  } = widget;

  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GM
  });

  const [center, setCenter] = useState({
    lat: 19.441262,
    lng: -99.192483,
  })

  const mapStyles = {
    width: '100%',
    height: '300px',
  }

  const isLocation = where({
    lat: is(Number),
    lng: is(Number)
  });

  const currentPosition = () => {
    if (isLocation(field.value)) {
      setCenter(field.value);
    } else if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        position => { 
          const pos = {
            lat: position.coords.latitude, 
            lng: position.coords.longitude
          };
          helpers.setValue(pos, true);
          setCenter(pos);
        },
        error => {
          !draggableMarker && helpers.setTouched(true, true);
          if (error.code === error.PERMISSION_DENIED){
            // Next phase: show modal asking for geolocation services
            console.log('Geolocation services denied by user');
          }
        }
      );
    }
  }

  return (
    <React.Fragment>
      {
        isLoaded ? (
          <GoogleMap
            id={name}
            zoom={zoom}
            center={center}
            onLoad={() => currentPosition()}
            mapContainerStyle={mapStyles}
            options={{
              draggable: draggableMap,
              scrollwheel: scrollwheel,
            }}
            mapContainerClassName={`${meta.touched && meta.error && 'is-invalid'}`}
          >
            <Marker
              draggable={draggableMarker}
              onDrag={e => {
                helpers.setValue({...field.value, 
                  lat: e.latLng.lat(), 
                  lng: e.latLng.lng()
                }, true);
              }}
              position={center}
            />
          </GoogleMap>
        ) : loadError && (
          <div>
            El mapa pudo cargarse.
          </div>
        )
      }
      {
        meta.touched && meta.error && (
          <div className="invalid-feedback">
            Tu ubicación es requerida, habilita el servicio de geolocalización de tu dispositivo.
          </div>
        )
      }
      { 
        help && (
          <small className="form-text text-muted">
            { help }
          </small>
        )
      }
    </React.Fragment>
                
  );
};

GmapsPinpointField.propTypes = {
  field: PropTypes.shape({
    name: PropTypes.string.isRequired,
    onBlur: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    value: PropTypes.any 
  }),
  meta: PropTypes.shape({
    error: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.object,
      PropTypes.array
    ]),
    touched: PropTypes.bool,
  }),
  helpers: PropTypes.shape({
    setValue: PropTypes.func.isRequired,
    setTouched: PropTypes.func.isRequired,
  }),
  clearValue: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  widget: PropTypes.object.isRequired,
  help: PropTypes.string,
};

GmapsPinpointField.defaultProps = {
  help: null,
};

export default withField(GmapsPinpointField);
