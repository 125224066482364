/** @jsxRuntime classic */
/** @jsx jsx */
import React from 'react'
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import { jsx, css } from '@emotion/react'

interface IProps {
  size: number | any,
  children: React.ReactNode | any,
  onClick: React.MouseEventHandler<HTMLButtonElement>,
}

const BtnIcon: React.FC<IProps> = ({ size, children, ...rest }) => {
  const classes = classNames(
    'btn-icon',
    rest.className,
  );

  const style = css`
    width: ${size}px;
    height: ${size}px;
    > i, span {
      font-size: ${size*0.6}px;
      line-height: ${size*0.6}px;
    }
  `;

  return (
    <button type="button" {...rest} className={classes} css={style}>
      { children }
    </button>
  );
};

BtnIcon.propTypes = {
  size: PropTypes.number,
  children: PropTypes.node.isRequired,
};

BtnIcon.defaultProps = {
  size: 30,
};

export default BtnIcon;
