import React, { useEffect, useReducer } from 'react';
import PropTypes from 'prop-types';

// Fetching : axios instance for SAT's data fetch
import { postSAT } from 'api/dataFetching';

// Components
import Spinner from 'components/common/Spinner';

// HOC
import withField from 'hoc/withField';
import { formatValue } from 'utils/sharedFuncs';

interface FieldProps {
  field: any;
  helpers: any;
  meta: any;
}

interface WidgetProps extends FieldProps {
  name: string;
  widget: any;
  label: string;
  popover: string | any;
  help: string | any;
  references: any;
}

const SAT: React.FC<WidgetProps> = ({
  field,
  help,
  helpers,
  meta,
  name,
  widget,
}) => {
  const {
    settings: { credentialType, formatField },
  } = widget;

  // * Component form initial State
  const defaultSATFormState = {
    type: '',
    rfc: '',
    password: '',
    certificate: '',
    privateKey: '',
  };

  // * Component action Reducer
  const SATReducer = (state, action) => {
    switch (action.type) {
      case 'INIT_SAT_FORM':
        return {
          ...state,
          type: action.value,
        };
      case 'UPDATE_SAT_FORM':
        return {
          ...state,
          [action.field]: action.value,
        };
      case 'CLEAR_SAT_FORM':
        return {
          ...state,
          rfc: '',
          password: '',
          certificate: '',
          privateKey: '',
        };
      default:
        return {
          ...state,
        };
    }
  };

  // * Component state : SATState, Ccmponent reducers : SATReducer
  const [SATState, dispatch] = useReducer(SATReducer, defaultSATFormState);

  // Set component's (initial) default state on component mount
  useEffect(() => {
    dispatch({
      type: 'INIT_SAT_FORM',
      value: credentialType,
    });
  }, []);

  /**
   * onChange - SAT Form field local state update;
   * @event : event invocation scope
   */
  const handleFieldChange = (event) => {
    const fieldName = event.target.name;
    let fieldValue = '';
    // Check if input type is 'file' to extract its file data
    if (event.target.type === 'file') {
      fieldValue = event.currentTarget.files.length
        ? event.currentTarget.files[0]
        : '';
    } else {
      // otherwise get plain field text
      fieldValue = event.target.value;
    }

    // Dispatch : SATState - Update SAT Form local state
    dispatch({
      type: 'UPDATE_SAT_FORM',
      field: fieldName,
      value: fieldValue,
    });
  };

  /**
   * onClick - SAT Login Submit Handler
   * @event : event invocation scope
   */
  const handleSATLoginClick = async (event: any): Promise<void> => {
    event.preventDefault();
    try {
      const SATFormData = new FormData();
      // Appending SATState mixed data to FormData instance to be used as API call payload
      for (const formField in SATState) {
        SATFormData.append(formField, SATState[formField]);
      }

      helpers.setValue(null, true);
      await postSAT(SATFormData);
      // A "200" response will let the user proceed into next step
      // This sets Formik's form field value to true, an effect of this is that this will enable next steps
      helpers.setValue(true, true);
      dispatch({ type: 'CLEAR_SAT_FORM' });
    } catch (e) {
      // A status code of "400" or "403" will set field value to false, blocking the user from proceeding
      // This will prevent the user to navigate to next steps
      helpers.setValue(false, true);
    }
  };

  return (
    <React.Fragment>
      <div className='row no-gutters'>
        <div
          className={`col-lg-9 col-xl-6 box mb-2
                        ${meta.touched && meta.error && 'box--error'}`}
        >
          <div className='box__body'>
            {credentialType === 'ciec' ? (
              <React.Fragment>
                <div className='form-group'>
                  <label htmlFor='rfc'>RFC:</label>
                  <input
                    type='text'
                    name='rfc'
                    id='rfc'
                    className='form-control'
                    value={ formatValue('uppercase', SATState.rfc) }

                    onChange={(event) => handleFieldChange(event)}
                  />
                  {meta.touched && meta.error && (
                    <div className='invalid-feedback'>
                      Formato de código incorrecto
                    </div>
                  )}
                  <small className='form-text text-muted'>RFC</small>
                </div>
                <div className='form-group'>
                <label htmlFor='password'>Contraseña CIEC:</label>
                  <input
                    type='password'
                    name='password'
                    id='password'
                    className='form-control'
                    value={SATState.password}
                    onChange={(event) => handleFieldChange(event)}
                  />
                  {meta.touched && meta.error && (
                    <div className='invalid-feedback'>
                      EL formato no cumple con las reglas
                    </div>
                  )}
                  <small className='form-text text-muted'>Password</small>
                </div>
              </React.Fragment>
            ) : (
              <React.Fragment>
                <div className='form-group'>
                  <label htmlFor='certificate'>Certificado:</label>
                  <div className='custom-file'>
                    <input
                      type='file'
                      name='certificate'
                      id='certificate'
                      className='custom-file-input'
                      onChange={(event) => handleFieldChange(event)}
                      disabled={field.value === true}
                    />
                    <label className='custom-file-label' htmlFor='cer'>
                      {SATState.certificate.name
                        ? SATState.certificate.name
                        : 'Seleccionar archivo...'}
                    </label>
                    {meta.touched && meta.error && (
                      <div className='invalid-feedback'>
                        Archivo inconrrecto
                      </div>
                    )}
                  </div>
                  <small className='form-text text-muted'>Certificado</small>
                </div>
                <div className='form-group'>
                  <label htmlFor='privateKey'>Llave privada:</label>
                  <div className='custom-file'>
                    <input
                      type='file'
                      name='privateKey'
                      id='privateKey'
                      className='custom-file-input'
                      onChange={(event) => handleFieldChange(event)}
                    />
                    <label className='custom-file-label' htmlFor='privateKey'>
                      {SATState.privateKey.name
                        ? SATState.privateKey.name
                        : 'Seleccionar archivo...'}
                    </label>
                    {meta.touched && meta.error && (
                      <div className='invalid-feedback'>
                        Archivo inconrrecto
                      </div>
                    )}
                  </div>
                  <small className='form-text text-muted'>Llave privada</small>
                </div>
                <div className='form-group'>
                  <label htmlFor='password'>
                    Contraseña de la llave privada:
                  </label>
                  <input
                    type='password'
                    name='password'
                    id='password'
                    className='form-control'
                    value={SATState.password}
                    onChange={(event) => handleFieldChange(event)}
                    disabled={field.value === true}
                  />
                  {meta.touched && meta.error && (
                    <div className='invalid-feedback'>
                      EL formato no cumple con las reglas
                    </div>
                  )}
                  <small className='form-text text-muted'>Contraseña</small>
                </div>
              </React.Fragment>
            )}
            <div className='box__footer mt-3 pt-3'>
              {field.value !== null ? (
                <button
                  type='button'
                  className='btn btn-success btn-block'
                  onClick={(event) => handleSATLoginClick(event)}
                  disabled={field.value === true}
                >
                  Login
                </button>
              ) : (
                <Spinner
                  loader='Oval'
                  position='center'
                  width='40'
                  height='40'
                />
              )}

              {
                {
                  true: (
                    <p className='text-success mt-3 text-center'>
                      Login Exitoso
                    </p>
                  ),
                  false: (
                    <p className='text-danger mt-3 text-center'>
                      Ocurrio un problema, volver a intentar
                    </p>
                  ),
                }[field.value]
              }
            </div>
          </div>
        </div>
      </div>
      {meta.touched && meta.error && (
        <div className='invalid-feedback d-block'>{meta.error}</div>
      )}
      {help && <small className='form-text text-muted'>{help}</small>}
    </React.Fragment>
  );
};

SAT.propTypes = {
  field: PropTypes.shape({
    name: PropTypes.string.isRequired,
    onBlur: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    value: PropTypes.any,
  }),
  meta: PropTypes.shape({
    error: PropTypes.string,
    touched: PropTypes.bool,
  }),
  helpers: PropTypes.shape({
    setValue: PropTypes.func.isRequired,
  }),
  name: PropTypes.string.isRequired,
  widget: PropTypes.object.isRequired,
  help: PropTypes.string,
};

SAT.defaultProps = {
  popover: null,
  help: null,
};

export default withField(SAT);
 
