import * as Yup from "yup";
import moment from 'moment';
import 'moment/locale/es';
import { isEmpty, isNil } from 'ramda';

// Utils
import { chkNil } from 'utils/object'

// Validations
import { test, equal } from 'components/form/validation/test'

Yup.addMethod(Yup.mixed, 'optional', function(isOptional = true, defaultValue = undefined) {
  const type = this.type;
  return this.transform(value => {   
    if (!isOptional) return value;
    if (!isEmpty(value) && (type !== 'number' && type !== 'date' && type !== 'object' && type !== 'boolean')) {
      return value;
    } else if (type === 'number' && !isNaN(value)){
      return value;
    } else if (type === 'date' && !isNaN(Date.parse(value))) {
      return value;
    } else if (type === 'object' && !chkNil(value)) {
      return value;
    } else {
      return defaultValue;
    }
  }).default(defaultValue);
});

const minValidation = (elementSchema, { params, error }) => {
  const schemaType = Yup.reach(elementSchema).type;
  switch (schemaType) {
    case 'string':
      return elementSchema.min(params, error ? error : ({ min }) => `Mínimo ${min} caracteres`);
    case 'number':
      return elementSchema.min(params, error ? error : ({ min }) => `Mínimo ${min}`);
    case 'date':
      return elementSchema.min(params, error ? error : ({ min }) => 
        `Fecha mínima: ${moment(min).format('DD-MM-YYYY')}`);
    case 'array':
      return elementSchema.min(params, error ? error : ({ min }) => `Mínimo ${min} elemento(s)`);
    default:
      return elementSchema;
  }
}

const maxValidation = (elementSchema, { params, error }) => {
  const schemaType = Yup.reach(elementSchema).type;
  switch (schemaType) {
    case 'string':
      return elementSchema.max(params, error ? error : ({ max }) => `Máximo ${max} caracteres`);
    case 'number':
      return elementSchema.max(params, error ? error : ({ max }) => `Máximo ${max}`);
    case 'date':
      return elementSchema.max(params, error ? error : ({ max }) => 
        `Fecha máxima: ${moment(max).format('DD-MM-YYYY')}`);
    case 'array':
      return elementSchema.max(params, error ? error : ({ max }) => `Máximo ${max} elemento(s)`);
    default:
      return elementSchema;
  }
}

const lengthValidation = (elementSchema, { params, error }) => {
  const schemaType = Yup.reach(elementSchema).type;
  switch (schemaType) {
    case 'string':
      return elementSchema.length(params, error ? error : ({ length }) => `${length} caracteres requeridos`);
    case 'array':
      return elementSchema.length(params, error ? error : ({ length }) => `${length} elementos requeridos`);
    default:
      return elementSchema;
  }
}

const elementValidations = (elementSchema, validation) => {
  const { type, params, error, reference } = validation;
  switch (type) {
    case 'optional':
      elementSchema = elementSchema.optional();
      break;
    case 'required':
      elementSchema = elementSchema.required(error ? error : 'Requerido');
      break;
    case 'min':
      elementSchema = minValidation(elementSchema, validation);
      break;
    case 'max':
      elementSchema = maxValidation(elementSchema, validation);
      break;
    case 'length':
      elementSchema = lengthValidation(elementSchema, validation);
      break;
    case 'matches':
      elementSchema = elementSchema.matches(params, error ? error : 'Formato incorrecto');
      break;
    case 'uuid':
      elementSchema = elementSchema.uuid(params, error ? error : 'Formato incorrecto');
      break;
    case 'flag':
      elementSchema = elementSchema.oneOf([params], error ? error : 'Requerido');
      break;
    case 'reference':
      elementSchema = elementSchema.when(reference.name, {
        is: val => test(reference.type, val, reference.params),
        then: elementSchema.required(error ? error : 'Requerido'),
        otherwise: elementSchema.optional(),
      });
      break;
    default:
      return elementSchema;
  }
  return elementSchema;
}

const conditionalValidations = (elementSchema, path) => {
  elementSchema = elementSchema.when(`$validations`, function(value, schema){
    return value[path].reduce((accum, validation) => {
      return elementValidations(accum, validation)
    }, schema);
  }); 
  return elementSchema;
}

const widgetValidations = (elementSchema, elementProps) => {
  const { widget, error } = elementProps;
  if(widget){
    switch (widget.type) {
      case 'email':
        elementSchema = elementSchema.email('Formato de correo electrónico no válido');
        break;
      case 'url':
        elementSchema = elementSchema.url('Formato de URL no válido');
        break;
      case 'radio':
        elementSchema = elementSchema.oneOf(elementProps.options.map(o => o.value),
          error ? error : 'Selecciona una opción'
        );
        break;
      case 'select':
        elementSchema = elementSchema.oneOf(elementProps.options.map(o => o.value),
          error ? error : 'Selecciona una opción'
        );
        break;
      case 'buroCredito':
        elementSchema = elementSchema.test(
          'nip',
          'El NIP no coincide',
          val => equal( val, widget.settings.nip)
        );
        break;
      default:
        return elementSchema;
    }
  }
  return elementSchema;
}

const requireness = (elementSchema, elementProps) => {
  const { multiple, required, error } = elementProps;
  if (required) {
    if (isNil(multiple)) {
      elementSchema = elementSchema.required(error ? error : 'Requerido');
    } else {
      elementSchema = elementSchema.min(1, error ? error : 'Al menos un elemento debe ser ingresado');
    }
  } else {
    elementSchema = elementSchema.optional();
  }

  return elementSchema;
}

const validationSchema = (vSchema, { name, type, ...elementProps }) => {
  const { validations, multiple, widget} = elementProps;
  let elementSchema = {};
  
  switch (type) {
    case 'string':
      elementSchema = Yup.string();
      break;
    case 'number':
      elementSchema = Yup.number();
      break;  
    case 'date':
      elementSchema = Yup.date('Ingresa una fecha válida');
      break;
    case 'boolean':
      elementSchema = Yup.boolean();
      break;
    case 'object':
      elementSchema = Yup.object().shape(widget.settings.objectElements.reduce(validationSchema, {}));
      break;
    default:
      return null;
  }

  elementSchema = widgetValidations(elementSchema, elementProps);

  if (validations.default) {
    elementSchema = validations.default.reduce(elementValidations, elementSchema);
  }

  if (validations.conditionals) {
    elementSchema = conditionalValidations(elementSchema, elementProps.path);
  }
  
  if (!isNil(multiple)) {
    elementSchema = Yup.array().of(elementSchema);
    elementSchema = requireness(elementSchema, elementProps); 
    vSchema[name] = multiple.reduce(elementValidations, elementSchema);
  } else {
    elementSchema = requireness(elementSchema, elementProps);
    vSchema[name] = elementSchema;
  }

  return vSchema;
};

export default validationSchema;