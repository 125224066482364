import React, { useEffect } from 'react';
import {
  Route,
  Switch,
  Redirect,
  useParams,
  useLocation,
} from 'react-router-dom';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { isEmpty, isNil } from 'ramda';
import TagManager from 'react-gtm-module';

// Store
import { rootStore } from 'store/store';

// Actions
import { getInit } from 'store/actions/initActions';

// Components
import Flow from 'pages/Flow';
import Auth from 'pages/Auth';
import Welcome from 'pages/Welcome';
import Success from 'pages/Success';
import Spinner from 'components/common/Spinner';

interface ParamTypes {
  orgId: string;
  flowId: string;
}

const FlowRouter: React.FC = () => {
  const dispatch = useDispatch();
  const { init } = useSelector(
    ({ initial: { init } }: rootStore) => ({ init }),
    shallowEqual
  );
  const { status } = useSelector(
    ({ initial: { status } }: rootStore) => ({ status }),
    shallowEqual
  );
  const { orgId, flowId } = useParams<ParamTypes>();
  const { search: queryParams } = useLocation();

  useEffect(() => {
    dispatch(getInit(flowId, orgId, queryParams));
  }, [dispatch]);

  useEffect(() => {
    if (!isEmpty(init)) {
      const {
        flow: { tagId },
      } = init;

      const tagManagerArgs = {
        gtmId: tagId
      }
      TagManager.initialize(tagManagerArgs);
    }
  }, [init]);

  const flowStatus = () => {
    if (status === 404) {
      window.location = 'https://pefai.com';
    } else if (status === 409) {
      window.location.reload();
    }
    return null;
  };

  return (
    <React.Fragment>
      {isNil(status) ? (
        <div className='absolute-center'>
          <div className='d-flex flex-column align-items-center'>
            <Spinner
              loader='ThreeDots'
              position='inline'
              width='40'
              height='40'
            />
          </div>
        </div>
      ) : !isEmpty(init) ? (
        <Switch>
          <Route exact path='/:orgId/:flowId' component={Flow} />
          <Route exact path='/:orgId/:flowId/welcome' component={Welcome} />
          <Route exact path='/:orgId/:flowId/success' component={Success} />
          <Route exact path='/:orgId/:flowId/auth' component={Auth} />
          <Route render={() => <Redirect to={`/${orgId}/${flowId}`} />} />
        </Switch>
      ) : (
        <React.Fragment>{flowStatus()}</React.Fragment>
      )}
    </React.Fragment>
  );
};

export default FlowRouter;
