/* eslint-disable max-len */
import React, { useEffect, useReducer } from 'react';
import PropTypes from 'prop-types';
import FormField from '../FormField';
import { useFormikContext } from 'formik';
import { isNil } from 'ramda';
import { fetchCatalog } from 'utils/object';
import { catalogAdapter } from 'utils/adapters';
import elementTypeMappings from 'utils/elementTypeMappings';

interface FieldProps {
  name: string;
  type: string;
  label: string;
  widget: {
    type: string | undefined;
    values?: any | null | undefined;
  };
  placeholder: string;
  options: any;
  help: string;
  popover: string;
  disabled: boolean;
  multiple: boolean;
  references: any;
  validations: any;
  className: string;
  elements: any;
  value: any;
}

const CatalogGroupDefaultState = {
  groupFormValues: {},
  components: [],
  lastComponentUpdated: {},
};

const CatalogGroupReducer = (state, action) => {
  switch (action.type) {
    case 'INIT_CATALOG_GROUP':
      return {
        components: action.components.map((component, index) => {
          const comp = component;
          if (index > 0) {
            comp.disabled = true;
          }
          return comp;
        }),
        groupFormValues: action.groupFormValues,
        lastComponentUpdated: action.lastComponentUpdated,
      };
    case 'UPDATE_FORM_CATALOG_GROUP':
      return {
        ...state,
        groupFormValues: {
          ...state.groupFormValues,
          ...action.groupFormValues,
        },
        lastComponentUpdated: state.components.find(
          comp => comp.name === Object.keys(action.groupFormValues)[0]
        ),
        components: (() => {
          const modifiedField = state.components.find(
            comp => comp.name === Object.keys(action.groupFormValues)[0]
          );

          return state.components
            .sort((a, b) => (a.weight > b.weight ? 1 : -1))
            .map((component, index) => {
              const comp = component;
              if (index > modifiedField.weight) {
                const {
                  widget: { type },
                } = comp;
                const keyAt = elementTypeMappings(type);
                comp.disabled = true;
                comp[keyAt] = [];
              }
              return comp;
            });
        })(),
      };
    case 'LOAD_NEXT_CATALOG':
      return {
        ...state,
        components: (() => {
          return state.components.map(comp => {
            const {
              weight,
              widget: { type },
            } = comp;

            if (weight === action.component.weight) {
              const keyAt = elementTypeMappings(type);
              comp.disabled = false;
              comp[keyAt] = action.payload;
            }
            return comp;
          });
        })(),
      };
    default:
      return {
        ...state,
      };
  }
};

const getChangedFieldName = (previousState, currentState) => {
  const controlKeys = Object.keys(previousState);
  let discrepancy = '';
  try {
    controlKeys.forEach(key => {
      if (previousState[key] !== currentState[key]) {
        throw { discrepancy: { [key]: currentState[key] } };
      }
    });
  } catch (exception: any) {
    discrepancy = exception.discrepancy;
  }
  const key = Object.keys(discrepancy)[0];
  return Object.entries(previousState).some(item => item.name !== key)
    ? key
    : '';
};

const CatalogGroup: React.FC<FieldProps> = props => {
  const { value: componentsFromProps } = props;
  const [CatalogGroupState, dispatch] = useReducer(
    CatalogGroupReducer,
    CatalogGroupDefaultState
  );
  const {
    values: formikFormValues,
    initialValues: initialFormikValues,
    dirty,
    values,
    setFieldValue,
  } = useFormikContext();

  const { lastComponentUpdated, components, groupFormValues } =
    CatalogGroupState;

  async function loadCatalog(nextCatalogField) {
    const {
      widget: {
        actions: { filter },
        actions: {
          filter: { optionsSchema },
        },
      },
    } = nextCatalogField;

    const payload = await fetchCatalog(filter, values) || []; 
    const adaptee = catalogAdapter(payload, optionsSchema);

    dispatch({
      type: 'LOAD_NEXT_CATALOG',
      component: nextCatalogField,
      payload: adaptee,
    });
  }

  // * Init : Component mount
  useEffect(() => {
    const areInitialValuesEmpty = Object.values(initialFormikValues).every(
      value => value === ''
    );

    const initialFormValues = componentsFromProps.map(e => {
      return { [e.name]: '' };
    });
    const initialGroupFormValues = Object.assign({}, ...initialFormValues);

    dispatch({
      type: 'INIT_CATALOG_GROUP',
      components: componentsFromProps,
      groupFormValues: areInitialValuesEmpty
        ? initialGroupFormValues
        : initialFormikValues,
      lastComponentUpdated: areInitialValuesEmpty
        ? componentsFromProps[0]
        : componentsFromProps[componentsFromProps.length],
    }); 
    if (areInitialValuesEmpty) {
      const nextCatalogField = componentsFromProps.find(
        component => component.weight === 0
      ); 
      loadCatalog(nextCatalogField);
    } else {
      componentsFromProps.forEach(async component => {
        await loadCatalog(component);
      });
    }
  }, []);

  // * On "formik values" Change
  useEffect(() => {
    const changedFieldName = getChangedFieldName(
      groupFormValues,
      formikFormValues
    );

    if (dirty && changedFieldName.length) {
      dispatch({
        type: 'UPDATE_FORM_CATALOG_GROUP',
        groupFormValues: {
          [changedFieldName]: formikFormValues[changedFieldName],
        },
      });
    }
  }, [formikFormValues]);

  // * On "groupFormValues" Change : Next in line gets changed
  useEffect(() => {
    if (dirty) {
      const nextCatalogField = components.find(
        component => component.weight === lastComponentUpdated.weight + 1
      );

      if (!isNil(nextCatalogField)) {
        loadCatalog(nextCatalogField);
      }
    }
  }, [groupFormValues]);

  return (
    <>
      {CatalogGroupState.components.map((item, i) => {
        return <FormField key={i} {...item} />;
      })}
    </>
  );
};

CatalogGroup.propTypes = {
  value: PropTypes.array.isRequired,
  name: PropTypes.string.isRequired,
};

CatalogGroup.defaultProps = { value: [] };
export default CatalogGroup;
