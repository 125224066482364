interface Branding {
  primary: string,
  secondary: string,
  bodyColor: string,
  hyperlink: string,
  headings: string,
  buttonPrimaryBg: string,
  buttonPrimaryHoverBg: string,
  buttonPrimaryColor: string,
  buttonPrimaryBorder: string
}

const themeBuilder = (branding: Branding): string => {
  const theme = `
   .custom-theme {
    --primary: ${branding?.primary};
    --secondary: ${branding?.secondary};
    --body-color: ${branding?.bodyColor};
    --link-color: ${branding?.hyperlink};
    --headings: ${branding?.headings};
    --button-primary-bg-color: ${branding?.buttonPrimaryBg};
    --button-primary-bg-color-hover: ${branding?.buttonPrimaryHoverBg};
    --button-primary-color: ${branding?.buttonPrimaryColor};
    --button-primary-border-color: ${branding?.buttonPrimaryBorder};
    }
  `;

  return theme;
};

export const styleElement = (): HTMLElement => {
  const style = document.createElement('style');
  style.appendChild(document.createTextNode(''));
  document.head.appendChild(style);
  
  return style;
};

export default themeBuilder;
