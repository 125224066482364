import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

// Componentes
import Modal from 'components/common/Modal';

// react-pdf
import { pdfjs, Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import { shallowEqual, useSelector } from 'react-redux';
import { rootStore } from 'store/store';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

interface IProps {
  file: string;
}

const Pdfbook: React.FC<IProps> = ({ file, ...rest }) => {
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [modal, setModal] = useState(false);
  const { recordId } = useSelector(
    ({
      initial: {
        init: { recordId },
      },
    }: rootStore) => ({ recordId }),
    shallowEqual
  );

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
    setPageNumber(1);
  }

  function changePage(offset) {
    setPageNumber((prevPageNumber) => prevPageNumber + offset);
  }

  function previousPage() {
    changePage(-1);
  }

  function nextPage() {
    changePage(1);
  }

  return (
    <React.Fragment>
      <div className="pdf-book">
        <Document
          file={{
            url: file,
            httpHeaders: {
              'Content-type': 'application/pdf',
              'Access-Control-Allow-Origin': '*',
              'recordId': recordId
            },
            withCredentials: true,
          }} onLoadSuccess={onDocumentLoadSuccess}
          className="pdf-book__document pdf-book__document--preview"
        >
          <Page pageNumber={pageNumber} className="pdf-book__page" />
        </Document>
        <div className="pdf-book__nav" role="group" aria-label="Navegación de documento PDF">
          <button
            type="button"
            className="button button--reset button--primary"
            disabled={pageNumber <= 1}
            onClick={previousPage}
          >
            <i className="material-icons-outlined">navigate_before</i>
          </button>
          <button type="button" className="button button--reset button--primary">
            {pageNumber} de {numPages} <span className="sr-only">(Página actual)</span>
          </button>
          <button
            type="button"
            className="button button--reset button--primary"
            disabled={pageNumber >= numPages}
            onClick={nextPage}
          >
            <i className="material-icons-outlined">navigate_next</i>
          </button>
          <button
            type="button"
            className="button button--reset button--primary"
            onClick={() => setModal(true)}
          >
            <i className="material-icons-outlined">zoom_in</i>
          </button>
        </div>
      </div>
      {
        modal && (
          <Modal modal={modal} setModal={setModal} size="xl">
            {({ toggle }) => (
              <React.Fragment>
                <ModalHeader toggle={toggle} tag="h2">
                  Contrato
                </ModalHeader>
                <ModalBody>
                  <Document
                    file={{
                      url: file,
                      httpHeaders: {
                        'Content-type': 'application/pdf',
                        'Access-Control-Allow-Origin': '*',
                        'recordId': recordId
                      },
                      withCredentials: true,
                    }}
                    onLoadSuccess={onDocumentLoadSuccess}
                    className="pdf-book__document pdf-book__document--full"
                  >
                    {Array.from(new Array(numPages), (el, index) => (
                      <Page
                        key={`page_${index + 1}`}
                        pageNumber={index + 1}
                        className="pdf-book__page"
                      />
                    ))}
                  </Document>
                </ModalBody>
                <ModalFooter>
                  <button type="button" className="btn btn-primary" onClick={toggle}>
                    Cerrar
                  </button>
                </ModalFooter>
              </React.Fragment>
            )}
          </Modal>
        )
      }

    </React.Fragment>
  );
};

Pdfbook.propTypes = {
  file: PropTypes.string.isRequired,
};

export default Pdfbook;
