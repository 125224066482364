import React  from 'react'
import {
  UncontrolledDropdown,
  DropdownToggle,
  /* DropdownMenu,
  DropdownItem,*/
} from 'reactstrap';

const LanguageSwitcher: React.FC = () => {
  return (
    <UncontrolledDropdown id="language-switcher">
      <DropdownToggle 
        aria-haspopup 
        aria-expanded="false" 
        tag="button" 
        className="btn btn-link" 
        style={{padding: 0, textDecoration: "none"}}
      >
        <i className="material-icons color-blue">language</i> ES
      </DropdownToggle>
      {
        /*
          <DropdownMenu right style={{padding: 0}}>
            <DropdownItem tag="span">
              <input type="radio" /> <label style={{margin: 0}}>English</label>
            </DropdownItem>
            <DropdownItem tag="span">
              <input type="radio" /> <label style={{margin: 0}}>Deutsch</label>
            </DropdownItem>
          </DropdownMenu>
        */
      }
    </UncontrolledDropdown>
  )
};

export default LanguageSwitcher;
