import React from 'react';
import PropTypes from 'prop-types';
import DOMPurify from 'dompurify';

interface htmlProps {
  type:string,
  html:string,
  element?: string | any,
  rest?: any
}

const FullHtml: React.FC<htmlProps> = ({ html, element, ...rest }) => {
  const fullSafeHtml = DOMPurify.sanitize(html);
  return React.createElement(element, { dangerouslySetInnerHTML: { __html: fullSafeHtml }, ...rest });
};

FullHtml.propTypes = {
  html: PropTypes.string.isRequired,
  element: PropTypes.string
};

FullHtml.defaultProps = {
  element: 'div',
}

export default FullHtml;