import React from 'react';
import PropTypes from 'prop-types';

// HoC
import withField from 'hoc/withField';
import NumberFormat from 'react-number-format';

interface FieldProps {
  field: any;
  meta: any;
}

interface WidgetProps extends FieldProps {
  name: string;
  widget: any;
  label: string;
  popover: string | any;
  help: string | any;
  disabled: boolean | any;
  references: [] | any;
}

const RangeField: React.FC<WidgetProps> = ({
  name,
  field,
  meta,
  widget,
  help,
  disabled,
}) => {
  const {
    settings: { min, max, step, prefix, suffix },
  } = widget;

  return (
    <React.Fragment>
      <div className='mb-2'>
        {prefix && <span className='h3 text-muted'>{prefix}</span>}
        {prefix && ' '}
        <span className='h3 text-primary'>
          <NumberFormat
            value={field.value ? field.value : 0}
            displayType={'text'}
            thousandSeparator={true}
          />
        </span>
        {suffix && ' '}
        {suffix && <span className='h3 text-muted'>{suffix}</span>}
      </div>
      <input
        {...field}
        type='range'
        min={min}
        max={max}
        step={step}
        disabled={disabled}
        id={name}
        className={`form-control-range ${
          meta.touched && meta.error && 'is-invalid'
        }`}
      />
      {min && max && (
        <div className='d-flex align-items-center justify-content-between'>
          <span className='text-muted'>
            <NumberFormat
              value={min}
              displayType={'text'}
              thousandSeparator={true}
            />
          </span>
          <span className='text-muted'>
            <NumberFormat
              value={max}
              displayType={'text'}
              thousandSeparator={true}
            />
          </span>
        </div>
      )}
      {meta.touched && meta.error && (
        <div className='invalid-feedback'>{meta.error}</div>
      )}
      {help && <small className='form-text text-muted'>{help}</small>}
    </React.Fragment>
  );
};

RangeField.propTypes = {
  field: PropTypes.shape({
    name: PropTypes.string.isRequired,
    onBlur: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    value: PropTypes.any,
  }),
  meta: PropTypes.shape({
    error: PropTypes.string,
    touched: PropTypes.bool,
  }),
  name: PropTypes.string.isRequired,
  widget: PropTypes.object.isRequired,
  help: PropTypes.string,
  disabled: PropTypes.bool,
};

RangeField.defaultProps = {
  disabled: false,
  help: null,
};

export default withField(RangeField);
