import React from 'react';
import PropTypes from 'prop-types';

interface IProps {
  text: string,
  element: string,
  alignment: string
}

const TextContainer: React.FC<IProps> = ({ text, element, alignment, ...rest }) => {
  return React.createElement(element, { style: { textAlign: alignment }, ...rest }, text);
};

TextContainer.propTypes = {
  text: PropTypes.string.isRequired,
  element: PropTypes.string.isRequired,
  alignment: PropTypes.string.isRequired 
};

export default TextContainer;
