import React from 'react';

// Regions
import OffCanvas from 'components/flow/regions/OffCanvas';
import Header from 'components/flow/regions/Header';
import Main from 'components/flow/regions/Main';
import Footer from 'components/flow/regions/Footer';

const Layout2: React.FC = () => {
  return (
    <React.Fragment>
      <OffCanvas />
      <div className="layout layout--2" id="flow">
        <div className="d-flex flex-fill">
          <div className="grid--vertical">
            <Header />
            <div className="row no-gutters align-items-center scrollable-wrapper">
              <Main className="col-md-10 col-lg-8 col-xl-6 scrollable-block" />
            </div>
            <Footer />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Layout2;
