import React from 'react';
import { useSelector, shallowEqual } from 'react-redux';

// Store
import { rootStore } from 'store/store';

// Componentes
import StepsBack from './StepsBack';
import StepStatus from './StepStatus';

const StepsHeader: React.FC = () => {
  const { flowSectionsById } = useSelector(
    ({ sections: { flowSectionsById }}: rootStore) => ({ flowSectionsById }),
    shallowEqual
  );
  const { currentStepId } = useSelector(
    ({ stepper: { currentStepId } }: rootStore) => ({ currentStepId }),
    shallowEqual
  );
  const { flowSteps } = useSelector(
    ({ stepper: { flowSteps } }: rootStore) => ({ flowSteps }),
    shallowEqual
  );

  const step = flowSteps.steps.find(element => element.id === currentStepId);

  return (
    <div className="d-flex align-items-center mb-3">
      <div className="d-flex align-items-center mr-auto">
        <StepsBack />
        {step?.header && (
          <div>
            <h2 className="mb-0">
              {flowSectionsById?.label}
            </h2>
            <StepStatus />
          </div>
        )}
      </div>
    </div>
  )
}

export default StepsHeader;
