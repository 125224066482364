/** @jsxRuntime classic */
/** @jsx jsx */
import React, { useState }  from 'react';
import { useSelector } from 'react-redux';
import { jsx, css } from '@emotion/react';
import {
  ModalHeader,
  ModalBody,
  ModalFooter,
} from 'reactstrap';

// Componentes
import Modal from 'components/common/Modal';
import DOMPurify from 'dompurify';

const OrgAbout: React.FC = () => {
  const [modal, setModal] = useState(false);
  const { init: { org: { 
    name, 
    about : { 
      powered, 
      header, 
      description, 
      video
    } } } } = useSelector(({ initial }: rootStore) => initial);
  const safeHtml = DOMPurify.sanitize(description);
  return (
    <div className="org-about">
      <p>
        <small>
          {`${powered} ${name} `}
          <button
            className="btn btn-link btn-sm"
            css={css`padding: 0;`}
            onClick={() => setModal(true)}
          >
            { header }
          </button>
        </small>
      </p>
      <Modal
        modal={modal}
        setModal={setModal}
      >
        {
          ({toggle}) => (
            <React.Fragment>
              <ModalHeader toggle={toggle} tag="h2">
                {header}
              </ModalHeader>
              <ModalBody>
                <div dangerouslySetInnerHTML={{ __html: safeHtml }} />
                <div className="embed-responsive embed-responsive-16by9">
                  <video controls={false} autoPlay>
                    <source src={`${video}`} type="video/mp4" />
                  </video>
                </div>
              </ModalBody>
              <ModalFooter>
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={toggle}
                >
                  Cerrar
                </button>
              </ModalFooter>
            </React.Fragment>
          )
        }
      </Modal>
    </div>
  )
};

export default OrgAbout;
