import { useState, useEffect, useRef } from 'react';

const useDocumentTitle = title => {
  const [document_title, setDoucmentTitle] = useState(title);
  const defaultTitle = useRef(document.title);

  useEffect(() => {
    document.title = document_title;

    return () => {
      document.title = defaultTitle.current;
    };
  },[document_title]);

  return [document_title, setDoucmentTitle];
};

export default useDocumentTitle;