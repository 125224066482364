import { combineReducers } from 'redux';

// Reducers
import initReducer from 'store/reducers/initReducer';
import sectionsReducer from 'store/reducers/sectionsReducer';
import steppersReducer from 'store/reducers/steppersReducer';
import offCanvasReducer from 'store/reducers/offCanvasReducer';

const rootReducer = combineReducers({
  sections: sectionsReducer,
  stepper: steppersReducer,
  initial: initReducer,
  offCanvas: offCanvasReducer
});

export default rootReducer;
