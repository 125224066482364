import React from 'react';
import PropTypes from 'prop-types';
import { isNil } from 'ramda';

// Components
import Select from 'react-select';

// HoC
import withField from 'hoc/withField';

interface FieldProps {
  field: any;
  meta: any;
  helpers: any;
  values: any;
}

interface WidgetProps extends FieldProps {
  name: string;
  multiple: [] | any;
  widget: any;
  placeholder: string | any;
  disabled: boolean | any;
  help: string | any;
  options: [] | any;
}

const AdvancedSelectField: React.FC<WidgetProps> = ({
  disabled,
  field,
  help,
  helpers,
  meta,
  multiple,
  name,
  options,
  placeholder,
  widget,
}) => {
  const {
    settings: { searchable },
  } = widget;
 
  const handleChange = valueSet => {
    if (!isNil(multiple)) {
      const stringValues = valueSet.map(({ value }) => value);
      helpers.setValue(stringValues, true);
    } else {
      helpers.setValue(valueSet.value, true);
    }
  };

  return (
    <React.Fragment>
      {options && (
        <Select
          options={options}
          name={name}
          placeholder={placeholder ? placeholder : '- Selecciona -'}
          isSearchable={searchable || false}
          isMulti={!isNil(multiple) ? true : false}
          isDisabled={disabled}
          menuPlacement='auto'
          isOptionDisabled={optn =>
            !isNil(optn.disabled) ? optn.disabled : false
          }
          value={
            field.value.length
              ? options.filter(optn => field.value.includes(optn.value))
              : null
          }
          onChange={option => handleChange(option)}
          className={`advanced-select ${
            meta.touched && meta.error && 'is-invalid'
          }`}
        />
      )}
      {meta.touched && meta.error && (
        <div className='invalid-feedback'>{meta.error}</div>
      )}
      {help && <small className='form-text text-muted'>{help}</small>}
    </React.Fragment>
  );
};

AdvancedSelectField.propTypes = {
  field: PropTypes.shape({
    name: PropTypes.string.isRequired,
    onBlur: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    value: PropTypes.any,
  }),
  meta: PropTypes.shape({
    error: PropTypes.string,
    touched: PropTypes.bool,
  }),
  helpers: PropTypes.shape({
    setValue: PropTypes.func.isRequired,
  }),
  values: PropTypes.object,
  name: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
  widget: PropTypes.object.isRequired,
  multiple: PropTypes.array,
  placeholder: PropTypes.string,
  help: PropTypes.string,
  disabled: PropTypes.bool,
};

AdvancedSelectField.defaultProps = {
  multiple: null,
  placeholder: null,
  help: null,
  disabled: false,
};

export default withField(AdvancedSelectField);
