import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import ReactHtmlParser from 'react-html-parser';
import { isNil } from 'ramda';

// HOC
import withField from 'hoc/withField';

// Components
import Modal from 'components/common/Modal';

interface FieldProps {
  field: any;
  meta: any;
}

interface WidgetProps extends FieldProps {
  disabled: boolean | any;
  help: string | any;
  label: string;
  multiple: [] | any;
  name: string;
  options: [] | any;
  placeholder: string | any;
  popover: string | any;
  widget: any;
}

const BuroCreditoField: React.FC<WidgetProps> = ({
  field,
  meta,
  name,
  placeholder,
  help,
  disabled,
  widget,
}) => {
  const [modal, setModal] = useState(false);
  const {
    settings: { nip, title, details },
  } = widget;

  return (
    <React.Fragment>
      <p>
        {!isNil(title) && `${title} `}
        <button
          type='button'
          className='button button--reset button--primary'
          onClick={() => setModal(true)}
        >
          Consulta de tu Historial Crediticio
        </button>
      </p>

      <Modal modal={modal} setModal={setModal}>
        {({ toggle }) => (
          <React.Fragment>
            <ModalHeader toggle={toggle} tag='h2'>
              Autorización de Consulta de Historial Crediticio
            </ModalHeader>
            <ModalBody>{ReactHtmlParser(details)}</ModalBody>
            <ModalFooter>
              <button
                type='button'
                className='btn btn-primary'
                onClick={toggle}
              >
                Cerrar
              </button>
            </ModalFooter>
          </React.Fragment>
        )}
      </Modal>
      <p className='h4 text-secondary'>NIP: {nip}</p>
      <input
        {...field}
        type='text'
        id={name}
        placeholder={placeholder}
        disabled={disabled}
        autoComplete='off'
        pattern='[0-9]*'
        inputMode='numeric'
        className={`form-control ${meta.touched && meta.error && 'is-invalid'}`}
      />
      {meta.touched && meta.error && (
        <div className='invalid-feedback'>{meta.error}</div>
      )}
      {help && <small className='form-text text-muted'>{help}</small>}
    </React.Fragment>
  );
};

BuroCreditoField.propTypes = {
  field: PropTypes.shape({
    name: PropTypes.string.isRequired,
    onBlur: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    value: PropTypes.any,
  }),
  meta: PropTypes.shape({
    error: PropTypes.string,
    touched: PropTypes.bool,
  }),
  name: PropTypes.string.isRequired,
  widget: PropTypes.object.isRequired,
  placeholder: PropTypes.string,
  help: PropTypes.string,
  disabled: PropTypes.bool,
};

BuroCreditoField.defaultProps = {
  placeholder: null,
  help: null,
  disabled: false,
};

export default withField(BuroCreditoField);
