import React from 'react';
import PropTypes from 'prop-types';

// HOC
import withRegion from 'hoc/withRegion';

// Components
import RenderComponent from 'components/flow/RenderComponent';

interface IProps {
  classes: string | any,
  components: [] | any
}

const Main: React.FC<IProps> = ({ classes, components }) => {
  return (
    <main className={classes} id="main">
      <div className="region__info">
        <div className="region-name">Region: Main</div>
      </div>
      <div className="region__content">
        {
          components?.map((component, index) => (
            <RenderComponent
              key={`${component.type}-${index}`}
              elementSchema={component}
            />
          ))
        }
      </div>
    </main>
  );
};

Main.propTypes = {
  classes: PropTypes.string,
  components: PropTypes.array,
};

Main.defaultProps = {
  classes: '',
  components: []
}

export default withRegion(Main, 'main');