import {
  INIT_LOADING,
  INIT_SUCCESS,
  INIT_FAIL, 
  INIT_ERROR,
  INIT_STATUS,
  SUCCESS_STATUS,
  INIT_COMPLETED
} from 'store/actionTypes/ActionsTypes';

import {
  InitialI,
  InitDispatchTypes
} from '../../models/index';

export interface InitialStateI {
  loading: boolean | any,
  completed: boolean | any,
  init: InitialI | any,
  error: number | null,
  status: number | null,
  successStatus: boolean 
}

const initialState: InitialStateI = {
  loading: false,
  completed: false,
  init: {},
  error: null,
  status: null,
  successStatus: false
}

const initReducer = (state: InitialStateI = initialState, action:InitDispatchTypes) : InitialStateI => {
  switch(action.type){
    case INIT_LOADING: {
      return {
        ...state,
        loading: true,
      }
    }
    case INIT_SUCCESS: {
      return {
        ...state,
        loading: false,
        init: action.payload
      }
    }
    case INIT_STATUS: {
      return {
        ...state,
        status: action.payload
      }
    }
    case INIT_FAIL: {
      return {
        ...state,
        loading: false
      }
    }
    case INIT_ERROR: {
      return {
        ...state,
        loading: false,
        error: action.payload
      }
    }
    case SUCCESS_STATUS: {
      return {
        ...state,
        successStatus: !state.successStatus
      }
    }
    case INIT_COMPLETED: {
      return {
        ...state,
        completed: true
      }
    }
    default: return state
  }
}

export default initReducer;