import * as qs from 'query-string';
import * as ENDPOINTS from './constants';

import {
  enterAPIManagerInstance
} from './index';
import { credentialsStore } from './localStorage';

export const getCredentials = (sessionId: string, uniqueId: string): Promise<any> => {
  return new Promise((resolve, reject) => {
    try {
      enterAPIManagerInstance({
        method: 'GET',
        url: ENDPOINTS.API_CREDENTIALS,
        headers: {
          "x-session-id": sessionId
        },
      }).then(credentials => {
        const responseJWT = credentials.data["x-user-token"] || "";
        const sessionObject = `{"xsid":"${sessionId}", "xuid":"${responseJWT}"}`;
        credentialsStore.set(`${uniqueId}`, sessionObject);
        resolve(credentials);
      }).catch(e => {
        console.log('getCredentials - Error:', e.response);
        reject(e.response);
      });
    } catch (e) {
      console.log(e.response);
      reject(e);
    }
  });
};

const fetchInit = (flowName: string, orgName: string, queryParams: string): Promise<any> => {
  const queryInit = qs.stringifyUrl({
    url: queryParams,
    query: {
      flowName: flowName,
      orgName: orgName,
    }
  });

  const uniqueId = `_xs:${orgName}-${flowName}`;
  credentialsStore.attemptSessionRestore(`${uniqueId}`);

  return new Promise((resolve, reject) => {
    try {
      enterAPIManagerInstance({
        method: 'GET',
        url: ENDPOINTS.API_INIT,
        params: new URLSearchParams(queryInit),
      }).then(init => {
        resolve(init);
      }).catch(e => {
        console.log('fetchInit - Error:', e);
        reject(e.response);
      });
    } catch (e) {
      console.log(e);
      reject(e);
    }
  });
};

const postTerms = (): Promise<any> => {
  return new Promise((resolve, reject) => {
    try {
      enterAPIManagerInstance({
        method: 'POST',
        url: ENDPOINTS.API_TERMS,
      }).then(terms => {
        resolve(terms);
      }).catch(e => {
        console.log('Error:', e.response);
        reject(e.response);
      });
    } catch (e) {
      console.log(e.response);
      reject(e);
    }
  });
};

const fetchSections = (): Promise<any> => {
  return new Promise((resolve, reject) => {
    try {
      enterAPIManagerInstance({
        method: 'GET',
        url: ENDPOINTS.API_SECTIONS,
      }).then(sections => {
        resolve(sections);
      }).catch(e => {
        console.log('Error:', e.response);
        reject(e.response);
      });
    } catch (e) {
      console.log(e);
      reject(e);
    }
  });
};

const fetchSection = (sectionId: string): Promise<any> => {
  return new Promise((resolve, reject) => {
    try {
      enterAPIManagerInstance({
        method: 'GET',
        url: `${ENDPOINTS.API_SECTIONS}/${sectionId}`
      }).then(section => {
        resolve(section);
      }).catch(e => {
        console.log('Error:', e.response);
        reject(e.response);
      });
    } catch (e) {
      console.log(e);
      reject(e);
    }
  });
};

const fetchSteps = (sectionId: string): Promise<any> => {
  return new Promise((resolve, reject) => {
    try {
      enterAPIManagerInstance({
        method: 'GET',
        url: ENDPOINTS.API_STEPS,
        params: new URLSearchParams(`?sectionId=${sectionId}`),
      }).then(steps => {
        resolve(steps);
      }).catch(e => {
        console.log('Error:', e.response);
        reject(e.response);
      });
    } catch (e) {
      console.log(e);
      reject(e);
    }
  });
};

const fetchStep = (stepId: string): Promise<any> => {
  return new Promise((resolve, reject) => {
    try {
      enterAPIManagerInstance({
        url: `${ENDPOINTS.API_STEPS}/${stepId}`,
        method: 'GET',
        headers: {
          'Content-Type': 'application/json'
        }
      }).then(step => {
        resolve(step);
      }).catch(e => {
        console.log('Error:', e.response);
        reject(e.response);
      });
    } catch (e) {
      console.log(e);
      reject(e);
    }
  });
};

const postStep = (stepsData, recordId: string): Promise<any> => {
  return new Promise((resolve, reject) => {
    try {
      enterAPIManagerInstance({
        method: 'POST',
        data: stepsData,
        url: ENDPOINTS.API_STEPS_SAVE,
        params: new URLSearchParams(`?recordId=${recordId}`)
      }).then(step => {
        resolve(step);
      }).catch(e => {
        console.log('Error:', e.response);
        reject(e.response);
      });
    } catch (e) {
      console.log(e.response);
      reject(e);
    }
  });
};

const postSuccess = (): Promise<any> => {
  return new Promise((resolve, reject) => {
    try {
      enterAPIManagerInstance({
        method: 'POST',
        url: ENDPOINTS.API_FLOW_FINALIZE
      }).then(success => {
        resolve(success);
      }).catch(e => {
        console.log('Error:', e.response);
        reject(e.response);
      });
    } catch (e) {
      console.log(e.response);
      reject(e);
    }
  });
};

const postFile = (file): Promise<any> => {
  return new Promise((resolve, reject) => {
    try {
      enterAPIManagerInstance({
        data: file,
        method: 'POST',
        url: ENDPOINTS.API_FILES,
        headers: {
          'Content-Type': 'multipart/form-data',
        }
      })
        .then(success => {
          resolve(success);
        }).catch(e => {
          console.log('error', e.response)
          reject(e.response)
        })
    } catch (e) {
      console.log(e.response);
      reject(e);
    }
  });
};

const fetchPayments = (orderNumber: string): Promise<any> => {
  return new Promise((resolve, reject) => {
    try {
      enterAPIManagerInstance({
        url: `${ENDPOINTS.API_PAYMENTS}order/${orderNumber}`,
        method: 'GET',
        headers: {
          'Content-Type': 'application/json'
        }
      }).then(payment => {
        resolve(payment);
      }).catch(e => {
        console.log('Error:', e.response);
        reject(e.response);
      });
    } catch (e) {
      console.log(e);
      reject(e);
    }
  });
};

const postSAT = (formData: any): Promise<any> => {
  return new Promise((res, reject) => {
    try {
      enterAPIManagerInstance({
        url: `${ENDPOINTS.API_SAT}`,
        method: 'POST',
        data: formData,
      }).then(data => {
        res(data);
      }).catch(e => {
        reject(e.response);
      });
    } catch (e) {
      reject(e);
    }
  });
}

const postSlots = (slot: any): Promise<any> => {
  return new Promise((resolve, reject) => {
    try {
      enterAPIManagerInstance({
        url: ENDPOINTS.API_AVAILABLESLOTS,
        method: 'POST',
        data: slot
      }).then(appointment => {
        resolve(appointment);
      }).catch(e => {
        console.log('Error:', e.response);
        reject(e.response);
      });
    } catch (e) {
      console.log(e);
      reject(e);
    }
  });
};

const postAppointment = (appointment: any): Promise<any> => {
  return new Promise((resolve, reject) => {
    try {
      enterAPIManagerInstance({
        url: ENDPOINTS.API_SCHEDULE,
        method: 'POST',
        data: appointment
      }).then(appointment => {
        resolve(appointment);
      }).catch(e => {
        console.log('Error:', e.response);
        reject(e.response);
      });
    } catch (e) {
      console.log(e);
      reject(e);
    }
  });
};

const releaseSlots = (recordId: string): Promise<any> => {
  return new Promise((resolve, reject) => {
    try {
      enterAPIManagerInstance({
        url: `${ENDPOINTS.API_RELEASE_SLOT}?recordId=${recordId}`,
        method: 'DELETE',
      }).then(response => {
        resolve(response);
      }).catch(e => {
        console.log('Error:', e.response);
        reject(e.response);
      });
    } catch (e) {
      console.log(e);
      reject(e);
    }
  });
};

const postGoTo = (stepId: string): Promise<any> => {
  return new Promise((resolve, reject) => {
    try {
      enterAPIManagerInstance({
        method: 'POST',
        url: ENDPOINTS.API_GOTO,
        data: {
          "stepId": stepId
        }
      }).then(response => {
        resolve(response)
      }).catch(e => {
        reject(e.response);
      })
    } catch (e) {
      reject(e)
    }
  })
}

const postGoBack = (): Promise<any> => {
  return new Promise((resolve, reject) => {
    try {
      enterAPIManagerInstance({
        method: 'PUT',
        url: ENDPOINTS.API_GOBACK
      }).then(response => {
        resolve(response)
      }).catch(e => {
        reject(e.response);
      })
    } catch (e) {
      reject(e)
    }
  })
}

const fetchAuth = (): Promise<any> => {
  return new Promise((resolve, reject) => {
    try {
      enterAPIManagerInstance({
        method: 'GET',
        url: ENDPOINTS.API_AUTH
      }).then(response => {
        resolve(response)
      }).catch(e => {
        reject(e.response);
      })
    } catch (e) {
      reject(e)
    }
  })
}

const postLogin = (url: string, params: { mode: string, formData: any }): Promise<any> => {
  return new Promise((resolve, reject) => {
    try {
      enterAPIManagerInstance({
        method: 'POST',
        url: url,
        data: {
          mode: params.mode,
          formData: params.formData,
        }
      }).then(response => {
        resolve(response)
      }).catch(e => {
        reject(e.response);
      })
    } catch (e) {
      reject(e)
    }
  })
}

const fetchFinalizeContent = (recordId: string): Promise<any> => {
  return new Promise((resolve, reject) => {
    try {
      enterAPIManagerInstance({
        method: 'GET',
        url: `${ENDPOINTS.API_FINALIZE_CONTENT}?recordId=${recordId}`
      }).then(response => {
        resolve(response)
      }).catch(e => {
        reject(e.response);
      })
    } catch (e) {
      reject(e)
    }
  })
}

const postOTP = (data: { countryCode: string, phoneNumber: any }): Promise<any> => {
  return new Promise((resolve, reject) => {
    try {
      enterAPIManagerInstance({
        method: 'POST',
        url: ENDPOINTS.API_OTP,
        data
      }).then(response => {
        resolve(response)
      }).catch(e => {
        reject(e.response);
      })
    } catch (e) {
      reject(e)
    }
  })
}

const postOTPValidation = (data: { transactionId: string, otpCode: any }): Promise<any> => {
  return new Promise((resolve, reject) => {
    try {
      enterAPIManagerInstance({
        method: 'POST',
        url: ENDPOINTS.API_OTP_VALIDATION,
        data
      }).then(response => {
        resolve(response)
      }).catch(e => {
        reject(e.response);
      })
    } catch (e) {
      reject(e)
    }
  })
}

const getPDFBook = (fileName:string): Promise<any> => {
  return new Promise((resolve, reject) => {
    try {
      enterAPIManagerInstance({
        method: 'GET',
        url: fileName,
        headers: {
          'Content-type': 'application/pdf',
        }
      }).then(response => {
        resolve(response)
      }).catch(e => {
        reject(e.response);
      })
    } catch (e) {
      reject(e)
    }
  })
}

export {
  fetchInit,
  postTerms,
  fetchSections,
  fetchSection,
  fetchSteps,
  fetchStep,
  postStep,
  postSuccess,
  postFile,
  fetchPayments,
  postSAT,
  postSlots,
  postAppointment,
  releaseSlots,
  postGoTo,
  postGoBack,
  fetchAuth,
  postLogin,
  fetchFinalizeContent,
  postOTP,
  postOTPValidation,
  getPDFBook
}