import React, { useEffect } from 'react'
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { isEmpty } from 'ramda';

// Store
import { rootStore } from 'store/store';

// Actions
import { getSteps} from 'store/actions/stepperActions';

// Components
import StepsHeader from './StepsHeader';
import Step from './Step';

const Steps: React.FC = () => {
  const dispatch = useDispatch();
  const { flowSteps } = useSelector(({ stepper: { flowSteps } }: rootStore) => ({ flowSteps }), shallowEqual);

  useEffect(() => {
    dispatch(getSteps());
  }, [dispatch]);
  
  return (
    <div id="steps">
      { 
        !isEmpty(flowSteps) ? (
          <React.Fragment>
            <StepsHeader />
            <Step />
          </React.Fragment>
        ) : null
      }
    </div>
  )
}

export default Steps;
