const API_BASE_URL = process.env.REACT_APP_BASE_URL;

const API_INIT = `/flows/v2/init/`;
const API_CREDENTIALS = `/flows/v2/auth/credentials`;
const API_AUTH = `/flows/v2/auth`;
const API_TERMS =  `flows/v2/termsConditions/`;
const API_SECTIONS = `flows/v2/sections`;
const API_STEPS = `flows/v2/steps`;
const API_STEPS_SAVE = `/flows/v2/save`;
const API_FLOW_FINALIZE = `/flows/v2/finalize`;
const API_FILES = `/flows/v2/assets/`;
const API_PAYMENTS = `/flows/v2/payments/`;
const API_GOTO = `/flows/v2/steps/goto`;
const API_GOBACK = `/flows/v2/steps/back`;
const API_FINALIZE_CONTENT = `/flows/v2/finalizeContent`;

// ToDo: Update urls after managment approach implemented
const API_OTP = `/flows/v2/otp/send`;
const API_OTP_VALIDATION = `/flows/v2/otp/validate`;
const API_SAT = `/flows/v2/taxes/credentials/`;
const API_AVAILABLESLOTS = `/appointments/v0/appointment/availableslots`;
const API_SCHEDULE = `/appointments/v0/appointment/schedule/`;
const API_RELEASE_SLOT = `/flows/v2/appointments/release/`;
const API_CATALOGS = `/builder/v1/catalogs/`;

// ToDo: When cleaning up instances remove these 2 enpoints
const API_APPOINTMENTS = `/appointments/v0/appointment/`;
const API_APPOINTMENTS_FLOWS =  `/flows/v2/appointments/`;

// const API_INIT = `/init`;
// const API_CREDENTIALS = `/auth/credentials`;
// const API_AUTH = `/auth`;
// const API_TERMS =  `/termsConditions/`;
// const API_SECTIONS = `/sections`;
// const API_STEPS = `/steps`;
// const API_STEPS_SAVE = `/save`;
// const API_FLOW_FINALIZE = `/finalize`;
// const API_FILES = `/assets/`;
// const API_PAYMENTS = `/payments/`;
// const API_GOTO = `/goto`;
// const API_GOBACK = `/back`;
// const API_FINALIZE_CONTENT = `/finalizeContent`;


export {
  API_BASE_URL,
  API_INIT,
  API_TERMS,
  API_SECTIONS,
  API_STEPS,
  API_STEPS_SAVE,
  API_FLOW_FINALIZE,
  API_FILES,
  API_PAYMENTS,
  API_SAT,
  API_APPOINTMENTS,
  API_CATALOGS,
  API_APPOINTMENTS_FLOWS,
  API_GOTO,
  API_GOBACK,
  API_AUTH,
  API_FINALIZE_CONTENT,
  API_SCHEDULE,
  API_AVAILABLESLOTS,
  API_RELEASE_SLOT,
  API_OTP,
  API_OTP_VALIDATION,
  API_CREDENTIALS
};