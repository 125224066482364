import React from 'react'
import PropTypes from 'prop-types';
import uuid from 'react-uuid';

// HOC
import withRegion from 'hoc/withRegion';

// Components
import RenderComponent from 'components/flow/RenderComponent';

const SidebarPrimary = ({ classes, components }) => {
  return (
    <aside className={classes} id="sidebar-primary">
      <div className="region__info">
        <div className="region-name">Region: Sidebar primary</div>
      </div>
      <div className="region__content">
        {
          components?.map(component => (
            <RenderComponent
              key={uuid()}
              elementSchema={component}
            />
          ))
        }
      </div>
    </aside>
  );
};

SidebarPrimary.propTypes = {
  classes: PropTypes.string,
  components: PropTypes.array,
};

SidebarPrimary.defaultProps = {
  classes: '',
  components: []
}

export default withRegion(SidebarPrimary, 'sidebar');
