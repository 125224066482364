import React, { useEffect, useState } from 'react';
import { Redirect, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

// Store
import { rootStore } from 'store/store';

// Actions
import { postSuccess, fetchFinalizeContent } from 'api/dataFetching';

// Hooks
import useDocumentTitle from "hook/useDocumentTitle";

// Utils
import themeBuilder, { styleElement } from 'utils/themeBuilder';
import { redirect } from 'utils/sharedFuncs';

// Components
import Spinner from 'components/common/Spinner';

// Application Insight
import { useAppInsightsContext } from "@microsoft/applicationinsights-react-js";
import { credentialsStore } from 'api/localStorage';

interface IRouteInfo {
  orgId: string;
  flowId: string;
}

interface ISuccessInfo {
  title: string,
  description: string,
  button: string,
  redirect: string,
  page: boolean
}

const Success: React.FC = () => {
  const { orgId, flowId } = useParams<IRouteInfo>();
  const appInsights = useAppInsightsContext();
  appInsights.trackMetric("Component 'Success' is in use");
  const [success, setSuccess] = useState<ISuccessInfo | null>(null)
  const { completed } = useSelector(({ initial: { completed } }: rootStore) => ({ completed }));
  const { init: {
    recordId,
    theme,
    org: {
      name: orgName
    },
    flow: {
      name: flowName,
      image,
    }
  } } = useSelector(({ initial: { init } }: rootStore) => ({ init }));
  useDocumentTitle(`Terminamos: ${flowName} - ${orgName}`);

  useEffect(() => {
    if (completed) {
      (async () => {
        await postSuccess().then().catch(err => {
          console.log(err.response)
        });
        await fetchFinalizeContent(recordId).then(res => {
          const localCredentials = credentialsStore.getKey();
          credentialsStore.clear(localCredentials);
          const { data } = res;
          setSuccess(data);
        }).catch(err => {
          console.log(err.response)
        })
      })();
    }
  }, []);

  useEffect(() => {
    const themeStyle = themeBuilder(theme);
    const style = styleElement();
    style.sheet.insertRule(themeStyle);
    document.body.classList.add('custom-theme');

    return () => { style.remove(); document.body.classList.remove('custom-theme') }
  });

  return (
    <React.Fragment>
      {
        !completed ? <Redirect to={`/${orgId}/${flowId}`} /> : !success ? (
          <div className='absolute-center'>
            <div className='d-flex flex-column align-items-center'>
              <img
                src={image}
                alt={`${flowName} - logo`}
                style={{ width: 140, marginBottom: 5 }}
              />
              <Spinner
                loader='ThreeDots'
                position='inline'
                width='40'
                height='40'
              />
            </div>
          </div>
        ) : !success.page ? redirect(success.redirect) : (
          <div className="view view--full-height">
            <div className="grid--vertical">
              <div className="d-flex flex-grow-1 flex-column overflow-auto justify-content-sm-center">
                <div className="container-fluid">
                  <div className="row justify-content-center">
                    <div className="col-md-10 col-lg-8 col-xl-5">
                      <div className="box box--shadow">
                        <div className="row no-gutters">
                          <div className="box__left box__left--sm bg bg--primary col-md-6 p-0">
                            <img src={image} alt={flowName} className="img-fluid mt-5 mr-3 mb-5 ml-3" />
                          </div>
                          <div className="col-md-6">
                            <div className="p-4">
                              <h3>
                                {success.title}
                              </h3>
                              <p>
                                {success.description}
                              </p>
                              <button
                                type="button"
                                className="btn btn-primary btn-block mt-auto"
                                onClick={() => redirect(success.redirect)}
                              >
                                {success.button}
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )
      }
    </React.Fragment>

  );
};

export default Success;
