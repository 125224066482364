import React from 'react';
import PropTypes from 'prop-types';
import { trackPromise } from 'react-promise-tracker';
import { isEmpty } from 'ramda';

// API
import { API_FILES } from 'api/constants';

// Fetching
import { postFile } from 'api/dataFetching';

// HOC
import withField from 'hoc/withField';

// Components
import Loading from 'components/common/Loading';
import Spinner from 'components/common/Spinner';

interface FieldProps {
  field: any,
  meta: any,
  helpers: any,
  clearValue:() => void
}

interface WidgetProps extends FieldProps {
  name: string,
  multiple: [] | any,
  widget: any,
  placeholder: string | any,
  help: string | any,
  disabled: boolean | any,
}

const FileInput:React.FC<WidgetProps> = ({
  field,
  meta,
  helpers,
  clearValue,
  name,
  multiple,
  widget,
  placeholder,
  help,
  disabled,
}) => {
  const {settings: { accept, capture }} = widget;

  const fileUpload = async input => {
    const formData = new FormData();
    formData.append('file', input.files[0]);
    try {
      const res = await postFile(formData)
      const { data } = res;
      const file = {
        assetId: data,
        filename: input.files[0].name
      }
      helpers.setValue(file, true);
    } catch (error) {
      console.log(error);
    }

    // const fileList = field.multiple ? [] : {};
    // if (Array.isArray(fileList)) {
    //   map(file => {
    //     fileList.push({
    //       filename: file.name,
    //       assetId: '123123-123123-ID'
    //     }); 
    //   }, field.files);
    //   return fileList;
    // } else{
    //   return {...fileList, ...{
    //     filename: field.files[0].name,
    //     assetId: '123123-123123-ID'
    //   }}
    // }
  }
  
  return (
    <React.Fragment>
      <div className="custom-file">
        <input
          type="file"
          name={name}
          id={name}
          accept={accept}
          multiple={multiple ? true : false}
          capture={capture}
          onChange={e => {
            if (e.currentTarget.files.length) {
              trackPromise(fileUpload(e.currentTarget), name);
            } else {
              clearValue();
            }
          }}
          disabled={disabled}
          className={`custom-file-input ${meta.touched && meta.error && 'is-invalid'}`}
        />
        <label
          htmlFor={name}
          className="custom-file-label"
        >
          { 
            !isEmpty(field.value) ? Array.isArray(field.value) ? field.value.length < 2 ? 
              field.value[0].filename : `${field.value.length} Archivos` : field.value.filename : 
            placeholder ? placeholder : 'Seleccionar archivo...'
          }
        </label>
      </div>
      {
        meta.touched && meta.error && (
          <div className="invalid-feedback d-block">
            { 'Archivo requerido' }
          </div>
        )
      }
      { 
        help && (
          <small className="form-text text-muted">
            { help }
          </small>
        )
      }
      {
        !isEmpty(field.value) && !isEmpty(field.value['assetId']) && (
          <div className="form-asset">
            <button
              type="button"
              className="button button--reset d-flex"
              onClick={() => window.open(`${API_FILES}${field.value['assetId']}`)}
            >
              <div className="box box--asset">
                <div className="box__top bg--primary">
                  <i className="material-icons-outlined">
                    insert_drive_file
                  </i>
                </div>
                <div className="box__body">
                  {field.value['filename']}
                </div>
              </div>
            </button>
          </div>
        )
      }
      <Loading
        area={name}
        delay={0}
      >
        <div className="mt-2">
          <Spinner
            loader="Oval"
            position="center"
            width="40"
            height="40"
          />
        </div>
      </Loading>
    </React.Fragment>
  );
};

FileInput.propTypes = {
  field: PropTypes.shape({
    name: PropTypes.string.isRequired,
    onBlur: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    value: PropTypes.any 
  }),
  meta: PropTypes.shape({
    error: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.object
    ]),
    touched: PropTypes.bool,
  }),
  helpers: PropTypes.shape({
    setValue: PropTypes.func.isRequired
  }),
  clearValue: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  multiple: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.oneOf([null])
  ]),
  widget: PropTypes.object.isRequired,
  placeholder: PropTypes.string,
  help: PropTypes.string,
  disabled: PropTypes.bool,
};

FileInput.defaultProps = {
  placeholder: null,
  help: null,
  disabled: false,
};

export default withField(FileInput);
