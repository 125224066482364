import React from 'react';
import PropTypes from 'prop-types';

// Components
import TextField from './widgets/TextField';
import TextareaField from './widgets/TextareaField';
import TelField from './widgets/TelField';
import DateCalendar from './widgets/DateCalendar';
import NumberField from './widgets/NumberField';
import EmailInput from './widgets/EmailInput';
import UrlField from './widgets/UrlField';
import SelectField from './widgets/SelectField';
import AdvancedSelectField from './widgets/AdvancedSelectField';
import RadioField from './widgets/RadioField';
import PasswordField from './widgets/PasswordField';
import Checkbox from './widgets/Checkbox';
import FileInput from './widgets/FileInput';
import RangeField from './widgets/RangeField';
import StepperField from './widgets/StepperField';
import ListField from './widgets/ListField';
import Object from './widgets/Object';
// import DrilldownField from './widgets/DrilldownField';
import BuroCreditoField from './widgets/BuroCreditoField';
// import SlotPickerField from './widgets/SlotPickerField';
import GmapsAutocompleteField from './widgets/GmapsAutocompleteField';
import GmapsPinpointField from './widgets/GmapsPinpointField';
import OtpField from './widgets/OtpField';
import Onfido from './widgets/Onfido';
import ListCardMultiselect from './widgets/ListCardMultiselect';
import ToggleField from './widgets/ToggleField';
import ObjectCatalog from './widgets/ObjectCatalog';
import SignatureArea from './widgets/SignatureArea';
import Stripe from './widgets/Stripe';
import Sat from './widgets/Sat';
import SlotSelector from './widgets/SlotSelector';
import BookNow from './widgets/BookNow';
import CatalogGroup from './group/CatalogGroup';

type IProps = {
  name: string;
  type: string;
  label: string;
  widget: {
    type: string | undefined;
    values?: any | null | undefined;
  };
  placeholder: string;
  options: any;
  help: string;
  popover: string;
  disabled: boolean;
  multiple: boolean;
  references: any;
  validations: any;
  className: string;
  elements: any;
  value: any;
};

const FormField: React.FC<IProps> = elementSchema => {
  switch (elementSchema.widget.type) {
    case 'list':
      return <ListField {...elementSchema} />;
    case 'stepper':
      return <StepperField {...elementSchema} />;
    // case 'drilldown':
    //   return <DrilldownField {...elementSchema} />;
    case 'text':
      return <TextField {...elementSchema} />;
    case 'textarea':
      return <TextareaField {...elementSchema} />;
    case 'tel':
      return <TelField {...elementSchema} />;
    case 'number':
      return <NumberField {...elementSchema} />;
    case 'email':
      return <EmailInput {...elementSchema} />;
    case 'url':
      return <UrlField {...elementSchema} />;
    case 'advancedSelect':
      return <AdvancedSelectField {...elementSchema} />;
    case 'select':
      return <SelectField {...elementSchema} />;
    case 'radio':
      return <RadioField {...elementSchema} />;
    case 'password':
      return <PasswordField {...elementSchema} />;
    case 'date':
      return <DateCalendar {...elementSchema} />;
    case 'checkbox':
      return <Checkbox {...elementSchema} />;
    case 'range':
      return <RangeField {...elementSchema} />;
    case 'object':
      return <Object {...elementSchema} />;
    case 'fileUploader':
      return <FileInput {...elementSchema} />;
    case 'buroCredito':
      return <BuroCreditoField {...elementSchema} />;
    // case 'slotPicker':
    //   return <SlotPickerField {...elementSchema} />;
    case 'gmapsAutocomplete':
      return <GmapsAutocompleteField {...elementSchema} />;
    case 'gmapsPinpoint':
      return <GmapsPinpointField {...elementSchema} />;
    case 'otp':
      return <OtpField {...elementSchema} />;
    case 'onfido':
      return <Onfido {...elementSchema} className='onfido-field' />;
    case 'listCard':
      return <ListCardMultiselect {...elementSchema} />;
    case 'toggle':
      return <ToggleField {...elementSchema} />;
    case 'objectCatalog':
      return <ObjectCatalog {...elementSchema} />;
    case 'signatureArea':
      return <SignatureArea {...elementSchema} />;
    case 'stripe':
      return <Stripe {...elementSchema} />;
    case 'sat':
      return <Sat {...elementSchema} />;
    case 'slotSelector':
      return <SlotSelector {...elementSchema} />;
    case 'bookNow':
      return <BookNow {...elementSchema} />;
    case 'CatalogGroup':
      return <FormGroup {...elementSchema} />;
    default:
      return null;
  }
};

const FormGroup: React.FC<IProps> = elementSchema => {
  const {name, widget } = elementSchema;
  switch (widget.type) {
    case 'CatalogGroup':
      return <CatalogGroup key={name} {...elementSchema} />;
    default:
      return null;
  }
};

FormGroup.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  widget: PropTypes.any,
  elements: PropTypes.array.isRequired,
  type: PropTypes.string.isRequired,
  value: PropTypes.any
};

FormGroup.defaultProps = { label: '', elements: [], widget: { type: '' } };

export default FormField;
