import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

// Actions
import { toggleMenu } from 'store/actions/offCanvasActions';

// Store
import { rootStore } from 'store/store';

const NavToogle: React.FC = () => {
  const { open } = useSelector(({ offCanvas }: rootStore) => offCanvas);
  const dispatch = useDispatch();

  return (
    <button
      type="button"
      className="button button--reset button--primary"
      id="nav-toggle"
      onClick={() => dispatch(toggleMenu())}
    >
      <i className="material-icons">
        { open ? 'menu_open' : 'menu' }
      </i>
    </button>
  )
};

export default NavToogle;
