import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector, shallowEqual } from 'react-redux';
import { format } from 'date-fns';
import { es } from 'date-fns/locale';
import { isNil } from 'ramda';

// Store
import { rootStore } from 'store/store';

// Fetching
import { postAppointment } from 'api/dataFetching';

// Components
import Spinner from 'components/common/Spinner';

// HoC
import withField from 'hoc/withField';

interface FieldProps {
  field: any;
  meta: any;
  helpers: any;
  values: any;
}

interface WidgetProps extends FieldProps {
  name: string;
  label: string;
  widget: any;
  popover: string | any;
  help: string | any;
  references: [] | any;
}

const BookNow: React.FC<WidgetProps> = ({
  widget,
  meta,
  field,
  helpers,
  help,
}) => {
  const { recordId } = useSelector(
    ({
      initial: {
        init: { recordId },
      },
    }: rootStore) => ({ recordId }),
    shallowEqual
  );
  const {
    settings: {
      organizationExternalId,
      vendorExternalId,
      vendorName,
      prodNumber,
      skuNumber,
      skuName,
      skuDescription,
      startDate,
      endDate,
    },
  } = widget;

  const [loading, setLoading] = useState(false);

  const bookAppointment = async () => {
    setLoading(true);
    const payload = {
      organizationExternalId: organizationExternalId,
      vendorExternalId: vendorExternalId,
      prodNumber: prodNumber,
      skuNumber: skuNumber,
      skuName: skuName,
      skuDescription: skuDescription,
      startDate: startDate,
      endDate: endDate,
      recordId: recordId,
    };

    try {
      const res = await postAppointment(payload);
      const { data } = res;
      helpers.setValue(data.appointmentExternalId, true);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  return (
    <React.Fragment>
      <div className='row no-gutters'>
        <div
          className={`col-lg-9 col-xl-6 box mb-2 ${
            meta.touched && meta.error && 'box--error'
          }`}
        >
          <div className='box__body'>
            <div className='text-muted mb-3'>
              <span>El horario de tu cita</span>
            </div>
            {!isNil(vendorName) && <div className='text-muted mb-1'><strong>Sucursal:</strong>{` ${vendorName}`}</div>}
            {!isNil(skuName) && <div className='text-muted mb-1'><strong>Producto:</strong>{` ${skuName}`}</div>}
            <div className='h4 mb-1'>
              <span className='text-capitalize'>
                {`${format(new Date(startDate), 'PPPP hh:mm aa', {
                  locale: es,
                })}`}
              </span>
            </div>
            <div className='box__footer mt-3 pt-3'>
              {!field.value ? (
                !loading ? (
                  <button
                    type='button'
                    className='btn btn-primary btn-block'
                    onClick={() => bookAppointment()}
                  >
                    Confirmar
                  </button>
                ) : (
                  <Spinner
                    loader='Oval'
                    position='center'
                    width='40'
                    height='40'
                  />
                )
              ) : (
                <p className='text-success text-center'>Cita Confirmada!</p>
              )}
            </div>
          </div>
        </div>
      </div>
      {meta.touched && meta.error && (
        <div className='invalid-feedback d-block'>{meta.error}</div>
      )}
      {help && <small className='form-text text-muted'>{help}</small>}
    </React.Fragment>
  );
};

BookNow.propTypes = {
  field: PropTypes.shape({
    name: PropTypes.string.isRequired,
    onBlur: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    value: PropTypes.any,
  }),
  meta: PropTypes.shape({
    error: PropTypes.string,
    touched: PropTypes.bool,
  }),
  helpers: PropTypes.shape({
    setValue: PropTypes.func.isRequired,
  }),
  widget: PropTypes.object.isRequired,
  help: PropTypes.string,
};

BookNow.defaultProps = {
  help: null,
};

export default withField(BookNow);
