import React from 'react'

// Regions
import OffCanvas from 'components/flow/regions/OffCanvas';
import SidebarPrimary from 'components/flow/regions/SidebarPrimary';
import Header from 'components/flow/regions/Header';
import Main from 'components/flow/regions/Main';
import Footer from 'components/flow/regions/Footer';

const Layout1: React.FC = () => {
  return (
    <React.Fragment>
      <OffCanvas />
      <div className="layout layout--1" id="flow">
        <div className="row flex-fill">
          <div className="d-none d-md-flex col-md-5 col-lg-4 col-xl-3">
            <SidebarPrimary className="flex-grow-1" />
          </div>
          <div className="col-md-7 col-lg-8 col-xl-9">
            <div className="grid--vertical">
              <Header />
              <Main className="scrollable-block" />
              <Footer />
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Layout1;
