import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { trackPromise } from 'react-promise-tracker';
import classNames from 'classnames/bind';
import { find, clone, propEq, isEmpty, set, lensProp } from 'ramda';

// Components
import FormField from 'components/form/FormField';
import Loading from 'components/common/Loading';
import Spinner from 'components/common/Spinner';

// HoC
import withField from 'hoc/withField';
import { postOTP, postOTPValidation } from 'api/dataFetching';

interface FieldProps {
  field: any;
  meta: any;
  helpers: any;
  values: any;
}

interface WidgetProps extends FieldProps {
  name: string;
  widget: any;
  help: string | any;
  references: any;
}

const OtpField: React.FC<WidgetProps> = ({
  name,
  widget,
  field,
  meta,
  helpers,
}) => {
  const classes = classNames('form-group', 'otp-field');
  const {
    settings: { countryCode },
  } = widget;

  const [otp, setOtp] = useState({
    phone: false,
    code: '',
    valid: false,
    transactionId: '',
    verified: false,
  });
  const [message, setMessage] = useState({
    status: 'success',
    content: '',
  });

  const phoneField = set(
    lensProp('name'),
    `${name}.phone`,
    clone(find(propEq('name', 'phone'), widget.settings.objectElements))
  );

  const otpFormatValidation = (value) => {
    const regex = /^[0-9\b]{6}$/;
    if (regex.test(value)) {
      setOtp({ ...otp, code: value });
    } else {
      setOtp({ ...otp, code: '' });
    }
  };

  const postOtp = async () => {
    const payload = {
      countryCode: countryCode,
      phoneNumber: field.value['phone'],
    };

    try {
      console.log("trying postOTP")
      const res = await postOTP(payload)
      if (res.status === 200) {
        const transactionId = res.data.transactionId;
        setOtp({
          ...otp,
          phone: true,
          valid: true,
          transactionId: transactionId,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const postValidateOtp = async () => {
    const payload = {
      transactionId: otp.transactionId,
      otpCode: otp.code,
    };

    try {
      const res = await postOTPValidation(payload)
      if (res.status === 200) {
        helpers.setValue(
          { ...field.value, transactionId: otp.transactionId },
          true
        );
        setOtp({ ...otp, valid: true, verified: true });
        setMessage({
          status: 'success',
          content: 'Tu celular se certificó correctamente.',
        });
      }
    } catch (error) {
      setOtp({ ...otp, valid: false, verified: false });
      setMessage({ status: 'danger', content: 'Código incorrecto.' });
      console.log(error);
    }
  };

  return (
    <React.Fragment>
      {
        <div className={classes}>
          <div
            className={`${
              !isEmpty(meta.touched) && !isEmpty(meta.error) && 'is-invalid'
            }`}
          >
            <FormField {...{ ...phoneField, disabled: otp.phone }} />
            {!isEmpty(field.value['phone']) &&
              !(meta.error && meta.error['phone']) &&
              !otp.phone && (
                <button
                  type='button'
                  className='btn btn-outline-primary btn-sm-block'
                  onClick={() => trackPromise(postOtp(), name)}
                >
                  Enviar código
                </button>
              )}
            {otp.phone && (
              <div className='form-group mt-4'>
                <label htmlFor='otp'>Ingresa tu código:</label>
                <input
                  type='text'
                  name='otp'
                  id='otp'
                  className={`form-control
                        ${
                          !isEmpty(otp.transactionId) &&
                          !otp.valid &&
                          'is-invalid'
                        }`}
                  onChange={(e) => otpFormatValidation(e.target.value)}
                  disabled={otp.verified}
                />
                {!isEmpty(otp.transactionId) && !otp.valid && (
                  <div className='invalid-feedback'>
                    Formato de código incorrecto
                  </div>
                )}
                <small className='form-text text-muted'>
                  Código de 6 dígitos
                </small>
              </div>
            )}
            {!isEmpty(otp.code) && !otp.verified && (
              <button
                type='button'
                className='btn btn-outline-primary btn-sm-block'
                onClick={() => trackPromise(postValidateOtp(), name)}
              >
                Validar código
              </button>
            )}
            {otp.verified && (
              <p className={`text-${message.status}`}>{message.content}</p>
            )}
          </div>
          <Loading area={name} delay={0}>
            <div className='mt-2'>
              <Spinner loader='Oval' position='center' width='40' height='40' />
            </div>
          </Loading>
        </div>
      }
    </React.Fragment>
  );
};

OtpField.propTypes = {
  field: PropTypes.shape({
    name: PropTypes.string.isRequired,
    onBlur: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    value: PropTypes.any,
  }),
  meta: PropTypes.shape({
    error: PropTypes.string,
    touched: PropTypes.bool,
  }),
  helpers: PropTypes.shape({
    setValue: PropTypes.func.isRequired,
  }),
  help: PropTypes.string,
  name: PropTypes.string.isRequired,
  widget: PropTypes.object.isRequired,
  references: PropTypes.array,
};

OtpField.defaultProps = {
  references: null,
};

export default withField(OtpField);
