import {
  TOGGLE_MENU,
  CLOSE_MENU
} from 'store/actionTypes/ActionsTypes';

import {
  MenuDispatchTypes
} from '../../models/index';

export interface InitialStateI {
  open: boolean
}

const initialState: InitialStateI = {
  open: false
}

const offCanvasReducer = (state: InitialStateI = initialState, action:MenuDispatchTypes) : InitialStateI => {
  switch(action.type){
    case TOGGLE_MENU: {
      return {
        ...state,
        open: !state.open
      }
    }
    case CLOSE_MENU: {
      return {
        ...state,
        open: action.payload
      }
    }
    default: return state
  }
}

export default offCanvasReducer;
