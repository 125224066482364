import React, { FC, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { pick, isNil } from 'ramda';

import ListCard from '../../common/ListCard';

// HOC
import withField from 'hoc/withField';

interface FieldProps {
  field: any;
  meta: any;
  helpers: any;
  values: any;
}

interface WidgetProps extends FieldProps {
  name: string;
  multiple: [] | any;
  widget: any;
  label: string;
  placeholder: string | any;
  disabled: boolean | any;
  help: string | any;
  popover: string | any;
  options: [] | any;
  references: [] | any;
}

const ListCardMultiselect: FC<WidgetProps> = (props) => {
  const { name, multiple, widget, help, field, meta, helpers, values } = props;
  const {
    settings: { entityOptions },
  } = widget;

  // Todo: Strip other object properties that aren't 'field_' pattern
  function stripFieldsOnly(options) {
    const fieldPattern = /^field_/i;
    return Object.fromEntries(
      Object.entries(options).filter(([key]) => {
        return fieldPattern.test(key);
      })
    );
  }

  function onClickHandler(id) {
    if (!isNil(multiple)) {
      const previousValues = field.value;
      const nextValues = previousValues.includes(id)
        ? previousValues.filter((idValue) => idValue !== id)
        : previousValues.concat(id);
      helpers.setValue(nextValues, true);
    } else {
      helpers.setValue(id, true);
    }
  }

  return (
    <React.Fragment>
      <div
        className={`row row-cols-1 row-cols-sm-2 row-cols-md-1 row-cols-lg-3 row-cols-xl-3
                 ${meta.touched && meta.error && 'is-invalid'}`}
      >
        {entityOptions.map((options, index) => (
          <div key={index} className='col mb-3'>
            <ListCard
              classNames={field.value.includes(options.id) ? 'box--active' : ''}
              id={options.id}
              labels={stripFieldsOnly(options)}
              imgUrl={options.image}
              onClick={() => onClickHandler(options.id)}
            />
          </div>
        ))}
      </div>
      {meta.touched && meta.error && (
        <div className='invalid-feedback'>{meta.error}</div>
      )}
      {help && <small className='form-text text-muted'>{help}</small>}
    </React.Fragment>
  );
};

ListCardMultiselect.propTypes = {
  field: PropTypes.shape({
    name: PropTypes.string.isRequired,
    onBlur: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    value: PropTypes.any,
  }),
  meta: PropTypes.shape({
    error: PropTypes.string,
    touched: PropTypes.bool,
  }),
  helpers: PropTypes.shape({
    setValue: PropTypes.func.isRequired,
  }),
  values: PropTypes.object,
  name: PropTypes.string.isRequired,
  widget: PropTypes.object.isRequired,
  multiple: PropTypes.array,
  help: PropTypes.string,
};

export default withField(ListCardMultiselect);
