import React, { useEffect, useState } from 'react';
import { Redirect, useParams } from 'react-router-dom';
import { shallowEqual, useSelector } from 'react-redux';
import { Formik, Form as FormikForm } from 'formik';
import * as Yup from 'yup';

// Store
import { rootStore } from 'store/store';

// Fetching
import { fetchAuth, postLogin } from 'api/dataFetching';

// Utils
import themeBuilder, { styleElement } from 'utils/themeBuilder';

// Components
import OrgLogo from 'components/OrgLogo';
import OrgAbout from 'components/OrgAbout';
import FormField from 'components/form/FormField';
import SubmitButton from 'components/form/elements/SubmitButton';

const fieldSchema = {
  name: "email",
  type: "string",
  multiple: null,
  label: "Email",
  value: null,
  format: null,
  placeholder: "Escribe tu correo",
  help: null,
  tooltip: null,
  popover: null,
  states: {
    visible: true
  },
  widget: {
    type: "email",
    settings: null,
    params: null
  },
  required: false,
  error: null,
  disabled: false,
  references: null,
  validations: null
}

interface IRouteInfo {
  orgId: string;
  flowId: string;
}

interface IAuthInfo {
  title: string;
  description: string;
  button: string;
  redirect: string;
  page: boolean;
  loginUrl: string;
  logoutUrl: string;
  mode: string;
  status: string;
  type: string;
}

const Auth: React.FC = () => {
  const { orgId, flowId } = useParams<IRouteInfo>();
  const [auth, setAuth] = useState<IAuthInfo | null>(null)
  const { init: { isAuthorized, theme } } = useSelector(
    ({ initial: { init } }: rootStore) => ({ init }),
    shallowEqual
  );

  useEffect(() => {
    if (!isAuthorized) {
      (async () => {
        try {
          const res = await fetchAuth();
          const { data } = res;
          setAuth({ ...auth, ...data });
        } catch (error) {
          console.log(error);
        }
      })();
    }
  }, []);

  useEffect(() => {
    const themeStyle = themeBuilder(theme);
    const style = styleElement();
    style.sheet.insertRule(themeStyle);
    document.body.classList.add('custom-theme');
    return () => { style.remove(); document.body.classList.remove('custom-theme') }
  }, []);

  const onSubmit = async (values, { setSubmitting }) => {
    try {
      const res = await postLogin(auth.loginUrl, {
        mode: auth.mode,
        formData: values,
      });
      const { data } = res;
      setAuth({ ...auth, ...data });
    } catch (error) {
      console.log(error);
    }
    setSubmitting(false);
  };

  const retry = () => {
    if (auth) {
      setAuth({ ...auth, status: "not-started" });
    }
  }

  return (
    <>
      {
        isAuthorized ? <Redirect to={`/${orgId}/${flowId}`} /> : auth && (
          <div className="layout layout--2">
            <div className="d-flex flex-fill">
              <div className="grid--vertical">
                <header id="header">
                  <div className="region__info">
                    <div className="region-name">Region: Header</div>
                  </div>
                  <div className="region__content">
                    <OrgLogo />
                  </div>
                </header>
                <div className="row no-gutters align-items-center scrollable-wrapper">
                  <main className="col-md-10 col-lg-8 col-xl-5 scrollable-block" id="main">
                    <div className="region__info">
                      <div className="region-name">Region: Main</div>
                    </div>
                    <div className="region__content">
                      <div className="box">
                        <div className='box__header justify-content-center'>
                          <div className='box__title'>
                            <h1 className='mb-0'>
                              {auth.title}
                            </h1>
                          </div>
                        </div>
                        <div className="box__body">
                          <div className='box__content'>
                            <p>
                              {auth.description}
                            </p>
                            {auth.status === 'not-started' ? (
                              <Formik
                                initialValues={{
                                  email: ''
                                }}
                                validationSchema={
                                  Yup.object({
                                    email: Yup.string().email('Ingresa un email válido').required('Requerido')
                                  })
                                }
                                onSubmit={onSubmit}
                              >
                                {({ isSubmitting }) => (
                                  <FormikForm
                                    className='needs-validation'
                                    onKeyDown={e => {
                                      if (e.key === 'Enter' && isSubmitting) {
                                        e.preventDefault();
                                      }
                                    }}
                                    noValidate
                                  >
                                    <FormField {...fieldSchema} />
                                    <div className='box__footer mt-3 pt-3 text-center'>
                                      <SubmitButton
                                        settings={{
                                          onValid: false,
                                          show: true,
                                          disabled: false
                                        }}
                                        className='btn btn-primary btn-block'
                                      >
                                        Enviar
                                      </SubmitButton>
                                    </div>
                                  </FormikForm>
                                )}
                              </Formik>
                            ) : (
                              <div className="box__footer">
                                <div className='mt-3 pt-3 text-center'>
                                  <button className="btn btn-primary btn-block" onClick={()=>retry()}>
                                    Reenviar Correo
                                  </button>
                                </div>
                                <div className='mt-3 pt-3 text-center'>
                                  <a href='https://pefai.com' target='blank'>
                                    ¿Necesitas ayuda?
                                  </a>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </main>
                </div>
                <footer id="footer">
                  <div className="region__info">
                    <div className="region-name">Region: Footer</div>
                  </div>
                  <div className="region__content">
                    <OrgAbout />
                  </div>
                </footer>
              </div>
            </div>
          </div>
        )
      }
    </>
  );
};

export default Auth;
