import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { rootStore } from 'store/store';

interface IProps {
  element: string,
  alignment: string | any
}

const FlowTitle: React.FC<IProps> = ({ element, alignment, ...rest }) => {
  
  const { init: { flow: { 
    image, 
    name 
  } } } = useSelector(({ initial }: rootStore) => initial);
  const title = (
    <>
      { 
        // image && (
        //  <img src={image} alt={`Icono de flujo - ${name}`} className="img-fluid" /> 
        //)
      }
      {name}
    </>
  ) ;

  return (
    <div className="flow-title">
      {React.createElement(element, { style: { textAlign: alignment }, ...rest }, title)}
    </div>
  );
};

FlowTitle.propTypes = {
  element: PropTypes.string.isRequired,
  alignment: PropTypes.string 
};

FlowTitle.defaultProps = {
  alignment: 'left'
};

export default FlowTitle;