import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { pick, isEmpty, where, is, clone } from 'ramda';
import {
  useLoadScript,
  Autocomplete,
  GoogleMap,
  Marker,
} from '@react-google-maps/api';

// Utils
import { chkEmpty } from 'utils/object'

// HOC
import withField from 'hoc/withField';

// Components
import FormField from '../FormField';

interface FieldProps {
  field: any,
  meta: any,
  helpers: any,
  clearValue:() => void
}

interface WidgetProps extends FieldProps {
  name: string,
  widget: any,
  placeholder: string | any,
  help: string | any,
}

const libraries = ['places'];

const GmapsAutocompleteField: React.FC<WidgetProps> = ({
  field,
  meta,
  helpers,
  clearValue,
  name,
  widget,
  help
}) => {
  const [map, setMap] = React.useState(null);
  const [pos, setPos] = React.useState({ lat: 0, lng: 0 });
  const [autocomplete, setAutocomplete] = useState(null);

  const {
    settings: {
      objectElements,
      editableAddress,
      componentRestrictions,
      draggableMap,
      scrollwheel,
      zoom,
      draggableMarker,
    },
  } = widget;

  const isLocation = where({
    lat: is(Number),
    lng: is(Number),
  });

  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GM,
    libraries: libraries,
  });

  const mapStyles = {
    width: '100%',
    height: '300px',
  };

  function onLoad(autocomplete) {
    setAutocomplete(autocomplete);
  }

  const onMapLoad = useCallback(function callback(map) {
    setMap(map);
  }, []);

  const updateMapState = () => {
    if (map) {
      setPos({
        lng: map.center.lng(),
        lat: map.center.lat(),
      });
      helpers.setValue({
        ...field.value,
        location: {
          lat: map.center.lat(),
          lng: map.center.lng(),
        },
      }, true);
    }
  };

  function onPlaceChanged() {
    const keys = objectElements.map((o) => o.name !== 'location' && o.name);
    if (autocomplete !== null) {
      const place = autocomplete.getPlace();
      if (place['address_components']) {
        const addressObject = place.address_components.reduce(
          (components, { long_name, types }) => {
            components[types[0]] = long_name;
            return components;
          },
          {}
        );

        const addressValue = pick(keys, addressObject);
        const location = {
          location: {
            lat: place.geometry.location.lat(),
            lng: place.geometry.location.lng(),
          },
        };
        helpers.setValue({ ...addressValue, ...location }, true);
      } else {
        clearValue();
      }
    } else {
      console.log('Autocomplete is not loaded yet!');
    }
  }

  return (
    <React.Fragment>
      {isLoaded ? (
        <React.Fragment>
          <Autocomplete
            onLoad={onLoad}
            onPlaceChanged={onPlaceChanged}
            options={{
              componentRestrictions: {
                country: [componentRestrictions],
              },
            }}
          >
            <div className='form-group mb-2'>
              <input
                type='text'
                name={name}
                id={name}
                autoComplete='nope'
                className={`form-control ${
                  meta.touched && meta.error && 'is-invalid'
                }`}
              />
            </div>
          </Autocomplete>
          {!isEmpty(field.value) && isLocation(field.value.location) && (
            <GoogleMap
              onLoad={onMapLoad}
              mapContainerStyle={mapStyles}
              zoom={zoom}
              center={field.value['location']}
              options={{
                draggable: draggableMap,
                scrollwheel: scrollwheel,
              }}
              onBoundsChanged={() => {
                setPos({
                  lng: map.center.lng(),
                  lat: map.center.lat(),
                });
              }}
              onZoomChanged={() => updateMapState()}
              onDragEnd={() => updateMapState()}
            >
              <Marker draggable={draggableMarker} position={pos} />
            </GoogleMap>
          )}
        </React.Fragment>
      ) : (
        loadError && <div>El servicio no pudo cargarse.</div>
      )}
      {meta.touched && meta.error && (
        <div className='invalid-feedback d-block'>
          Por favor seleciona una locación.
        </div>
      )}
      {help && <small className='form-text text-muted'>{help}</small>}
      {editableAddress && !chkEmpty(field.value) && (
        clone(objectElements).map(element => {
          element.name = `${name}.${element.name}`;
          return <FormField {...element} key={element.name} />;
        })
      )}
    </React.Fragment>
  );
};

GmapsAutocompleteField.propTypes = {
  field: PropTypes.shape({
    name: PropTypes.string.isRequired,
    onBlur: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    value: PropTypes.any 
  }),
  meta: PropTypes.shape({
    error: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.object,
      PropTypes.array
    ]),
    touched: PropTypes.bool,
  }),
  helpers: PropTypes.shape({
    setValue: PropTypes.func.isRequired
  }),
  clearValue: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  widget: PropTypes.object.isRequired,
  help: PropTypes.string,
};

GmapsAutocompleteField.defaultProps = {
  help: null,
};

export default withField(GmapsAutocompleteField);
