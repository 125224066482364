/** @jsxRuntime classic */
/** @jsx jsx */
import React from 'react';
import PropTypes from 'prop-types';
import { jsx, css } from '@emotion/react';
import DatePicker from 'react-datepicker';
import NumberFormat from 'react-number-format';
import 'react-datepicker/dist/react-datepicker.css';
import es from 'date-fns/locale/es';

// HOC
import withField from 'hoc/withField';

interface FieldProps {
  field: any,
  meta: any,
  helpers: any
}

interface WidgetProps extends FieldProps {
  name: string,
  widget: any,
  placeholder: string | any,
  help: string | any,
  disabled: boolean | any,
}

const DateCalendar: React.FC<WidgetProps> = ({
  field,
  meta,
  helpers,
  name,
  widget,
  placeholder,
  help,
  disabled,
}) => {

  const style = css`
    .react-datepicker-wrapper {
      display: block;
    }
  `;

  const {settings: {
    min,
    max,
    format,
    monthDropdown,
    yearDropdown,
    inline
  }} = widget;

  // Configs : Datepicker + FormatDate
  const maskPattern = [...format].filter(item => item !== '/');
  const formatPattern = format.replace(/[a-zA-Z0-9]/gu, '#');
 
  function handleDateChange(date, format) {
    const formattedDate: any = date ? new Date(date) : null;
    if (format === 'MM/yyyy' && formattedDate !== null) {
      formattedDate.setDate(1);
    }
    helpers.setValue(
      formattedDate !== null ? formattedDate.toISOString() : '',
      true
    );
  }

  return (
    <React.Fragment>
      <div css={style}>
        <DatePicker
          name={name}
          id={name}
          {...(min && { minDate: new Date(min) })}
          {...(max && { maxDate: new Date(max) })}
          selected={field.value ? new Date(field.value) : ''}
          dateFormat={format || 'dd/MM/yyyy'}
          showPopperArrow={false}
          inline={inline}
          showMonthDropdown={monthDropdown}
          showYearDropdown={yearDropdown}
          showMonthYearPicker={format === 'MM/yyyy'}
          dropdownMode='select'
          locale={es}
          onChange={(date) => {
            handleDateChange(date, format);
          }}
          disabled={disabled}
          placeholderText={placeholder}
          autoComplete='off'
          className={`form-control ${
            meta.touched && meta.error && 'is-invalid'
          }`}
          customInput={
            <NumberFormat
              format={formatPattern}
              placeholder={format}
              mask={maskPattern}
            />
          }
        />
      </div>
      {meta.touched && meta.error && (
        <div className='invalid-feedback d-block'>{meta.error}</div>
      )}
      {help && <small className='form-text text-muted'>{help}</small>}
    </React.Fragment>
  );
};

DateCalendar.propTypes = {
  field: PropTypes.shape({
    name: PropTypes.string.isRequired,
    onBlur: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    value: PropTypes.any 
  }),
  meta: PropTypes.shape({
    error: PropTypes.string,
    touched: PropTypes.bool,
  }),
  helpers: PropTypes.shape({
    setValue: PropTypes.func.isRequired
  }),
  name: PropTypes.string.isRequired,
  widget: PropTypes.object.isRequired,
  placeholder: PropTypes.string,
  help: PropTypes.string,
  disabled: PropTypes.bool
};

DateCalendar.defaultProps = {
  placeholder: null,
  help: null,
  disabled: false
};

export default withField(DateCalendar);
