import React from 'react';
import { useSelector, shallowEqual } from 'react-redux';

// Store
import { rootStore } from 'store/store';

// Components
import Layout1 from './Layout1';
import Layout2 from './Layout2';
import Layout3 from './Layout3';
import Layout4 from './Layout4';

const Layout: React.FC = () => { 
  const { id } = useSelector(({
    sections: { flowSection: { layout: { id } } }
  }: rootStore) => ({ id }), shallowEqual);
  
  const layout = () =>{
    switch (id) {
      case "lid_1":
        return <Layout1 />;
      case "lid_2":
        return <Layout2 />;
      case "lid_3":
        return <Layout3 />;
      case "lid_4":
        return <Layout4 />;
      default:
        return null;
    }
  }
  
  return layout();
};

export default Layout;
