import axios from 'axios';
import * as ENDPOINTS from './constants';
import { getCredentials } from './dataFetching';
import { credentialsStore } from './localStorage';

const credentials = process.env.NODE_ENV === "development" ? true : true;

const COMMON_HEADERS = {
  'Cache-Control': 'no-cache',
  'Content-type': 'application/json',
  'Access-Control-Allow-Origin': '*'
};
interface IExperienceSessionData {
  xsid: string,
  xuid: string
}

/**
 * enterAPIManagerInstance - this Axios instance has the sole purpose of handling
 * "enter" (flows, experiences) API calls. Motivation for this has been the
 * Auth/Autorization design where through the headers session and jwt are handled
 */
export const enterAPIManagerInstance = axios.create({
  baseURL: ENDPOINTS.API_BASE_URL,
  headers: COMMON_HEADERS,
  withCredentials: credentials,
});

/**
 * Axios Interceptors - Request
 * With this interceptor we pick up whatever has been put in local storage and apply it 
 * to each call's headers. The logic here is that "there will always be something in our 
 * local storage"
 */
enterAPIManagerInstance.interceptors.request.use(config => {
  const localSessionData = credentialsStore.getValue();
  const { xsid, xuid }: IExperienceSessionData = localSessionData !== ""
    ? JSON.parse(localSessionData)
    : { xsid: "", xuid: "" };

  config.headers = {
    'x-session-id': xsid,
    'x-user-token': xuid,
    ...config.headers
  }
  return config;
});

/**
 * Axios Interceptors - Response
 * Initial responses (/init) will always contain either a Session ID and a JWT, or just a Session ID 
 * to later process this and create a JWT. The logic for this interceptor is to extract, match, and 
 * store locally these values (x-session-id, x-user-token)
 */
enterAPIManagerInstance.interceptors.response.use(async config => {
  const { status, headers } = config;
  /**
 * HTTP Response Status Code : 201 Created
 * 
 * This status code is used to "renew" Session Id when a session configuration changes,
 * this can happen when "prefills" or "edited" experiences are configured and a new Session Id
 * is created
 */
  if (status === 201 && credentialsStore.getKey() !== "") {
    const sessionId = headers['x-session-id']; 
    await getCredentials(sessionId, credentialsStore.getKey());
  }
  return config;
}, async error => {

  /**
   * HTTP Response Status Code : 403 - Forbidden
   * 
   * Upon having a valid session but an expired token, request new credentials using 
   * previous session Id, then update localstorage and application instance object,
   * then retry failed call with new credentials
   */

  if (error.response.status === 403) {
    const failedRequest = error.config;
    const sessionId = failedRequest.headers['x-session-id'];
    await getCredentials(sessionId, credentialsStore.getKey());
    failedRequest.headers["x-user-token"] = JSON.parse(credentialsStore.getValue())['xuid'];
    return enterAPIManagerInstance(failedRequest);
  }

  /**
   * HTTP Response Status Code : 412 - Precondition Failed
   * 
   * This error handles the logic for whenever a session has expired or a 
   * suspected Session ID and/or JWT token forgery
   */
  if (error.response.status === 412) {
    const localCredentials = credentialsStore.getKey();
    credentialsStore.clear(localCredentials);
    window.location.replace(error.response.data.redirectUrl);
  }
  return Promise.reject(error);
});
