import { connect } from 'formik';
import flatten from 'flat';
import { useEffect } from 'react';

const ScrollToError = (props) => {
  const { isSubmitting, isValidating, errors, status } = props.formik;
  const fieldsWithErrors = Object.keys(flatten(errors));

  useEffect(() => {
    if (
      status !== 'error' &&
      fieldsWithErrors.length &&
      isSubmitting &&
      !isValidating
    ) {
      const windowObject: Window = window;
      const componentWithError = 
        document.getElementsByClassName('invalid-feedback');

      if (componentWithError.length) {
        const region = componentWithError[0].parentElement;
        const regionOffset = region?.offsetTop || 0;
        if (windowObject.innerWidth < 768) {
          window.scrollTo(0, regionOffset);
        } else {
          const main = document
            .getElementById('main')
            ?.getElementsByClassName('region__content')[0];
          main?.scrollTo(0, regionOffset);
        }
      }
    }
  });

  return null;
};

export default connect(ScrollToError);
