import React from 'react';
import PropTypes from 'prop-types';
import { usePromiseTracker } from 'react-promise-tracker';

interface IProps {
  area: string,
  delay?: number,
  children: React.ReactNode
}

const Loading:React.FC<IProps> = ({
  area,
  delay,
  children
}) => {
  const { promiseInProgress } = usePromiseTracker({
    area: area,
    delay: delay,
  });
  return (
    promiseInProgress && (
      <div className="loading">
        {children}
      </div>
    )
  );
};

Loading.propTypes = {
  area: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  delay: PropTypes.number,
};

Loading.defaultProps = {
  delay: 0,
};

export default Loading;
