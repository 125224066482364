import { Dispatch } from 'redux';
import { RootState } from '../store';
import {
  SECTIONS_LOADING,
  SECTIONS_SUCCESS,
  SECTIONS_FAIL,
  SECTIONS_SUCCESS_BY_ID,
  SECTION_LOADING,
  SECTION_FAIL,
  SECTION_SUCCESS,
  SECTION_NUMBER,
  PREVIOUS_SECTION_ID,
  PREVIOUS_SECTION_NUMBER,
  CURRENT_SECTION_ID,
  CURRENT_SECTION_NUMBER,
  SECTION_RESET,
  RESET_CURRENT_STEP_ID,
  INIT_COMPLETED,
  NAV_DIRECTION
} from '../actionTypes/ActionsTypes';
import { SectionDispatchTypes } from '../../models/index';
import { fetchSections, fetchSection } from '../../api/dataFetching';
import {
  compose,
  length,
  propOr,
  find,
  propEq,
  useWith,
  identity,
  pathOr,
  equals
} from 'ramda';

import { getVal } from 'utils/sharedFuncs';

export const getSections = () => async (dispatch: Dispatch<SectionDispatchTypes>): Promise<any> => {
  try {
    dispatch({
      type: SECTIONS_LOADING,
    });
    const res = await fetchSections();

    dispatch({
      type: SECTIONS_SUCCESS,
      payload: res.data
    })

    dispatch(getCurrentSectionsId());
    dispatch(getSectionNumber());

  } catch (error) {
    dispatch({
      type: SECTIONS_FAIL,
      payload: error,
    });
  }
}

export const getSection = (id: string) =>
  async (dispatch: Dispatch<SectionDispatchTypes>): Promise<any> => {
    try {
      dispatch({
        type: SECTION_LOADING,
      });
      const res = await fetchSection(id);

      dispatch({
        type: SECTION_SUCCESS,
        payload: res.data
      })
    } catch (error) {
      dispatch({
        type: SECTION_FAIL,
        payload: error,
      });
    }
  }

export const getSectionsById = (sectionId: string) => (dispatch: Dispatch<SectionDispatchTypes>, getState: () =>
  RootState): any => {
  const {
    sections: { flowSections }
  } = getState();
  const sections = pathOr({}, ['sections'], flowSections);
  const findById = useWith(find, [propEq('id'), identity]);
  const result = findById(sectionId, sections);
  dispatch({
    type: SECTIONS_SUCCESS_BY_ID,
    payload: result
  });
};

export const getCurrentSectionsId = () => (dispatch: Dispatch<SectionDispatchTypes>, getState: () =>
  RootState): any => {
  const {
    sections: { currentSectionId, currentSectionNumber, previousSectionId, flowSections }
  } = getState();

  const sections = pathOr({}, ['sections'], flowSections);
  const allSectionsNumbers = sections.map(ele => ele.sectionNum)
  const result = Math.min(...allSectionsNumbers);

  // Lookup into Sections (sections) for "COMPLETED" sections
  const predicateCompleteSections = ele => ele.status === 'COMPLETED' && ele.valid === true;
  const completeSections = sections.every(predicateCompleteSections);

  // Lookup into Sections (sections) for "ACTIVE" sections
  const predicateActiveSection = ele => equals(ele.status, 'ACTIVE');
  const idToSendActive = find(predicateActiveSection, sections);

  // Lookup into Sections (sections) for "INCOMPLETE" sections
  const predicateIncomplete = ele => equals(ele.status, 'INCOMPLETED');
  const idToSendIncomplete = find(predicateIncomplete, sections);

  // Lookup into Sections (sections) for "COMPLETED" and valid sections
  const predicateCompleteAndValidTrue = ele => ele.status === 'COMPLETED' && ele.valid === true;
  const idToSendCompleteAndValidTrue = find(predicateCompleteAndValidTrue, sections);

  // Lookup into Sections (sections) for ---next section--- from current
  const predicateNextSection = ele => ele.sectionNum === currentSectionNumber + 1;
  const idToNextSection = find(predicateNextSection, sections);

  // Lookup into Sections (sections) for ---first--- in the array
  const predicateByMinNumber = ele => equals(ele.sectionNum, result);
  const idToSendMinNumber = find(predicateByMinNumber, sections);

  // Previous serves as an anchor to hold previous section values
  let previousTargetSection;
  // Holds section id and section number values
  let nextTargetSection;

  // Given any flow that "starts" or "resumes", 
  // Their Active section can be either the first section or X section of the flow
  // If no previousSection is stored then our next target section will be active
  if (idToSendActive && previousSectionId === "") {
    nextTargetSection = idToSendActive;
    // Navigate to next section
  } else if (idToNextSection) {
    nextTargetSection = idToNextSection
    // Navigate to incomplete section
  } else if (idToSendIncomplete) {
    nextTargetSection = idToSendIncomplete;
    // Navigate to complete and valid section 
    // (Not implemented yet. This will be directed to completed but invalid)
  } else if (idToSendCompleteAndValidTrue) {
    nextTargetSection = idToSendCompleteAndValidTrue;
    // Navigate to the --first-- section
  } else if (idToSendMinNumber) {
    nextTargetSection = idToSendMinNumber;
  }

  // Before dispatching to update CURRENT_SECTION_*, store PREVIOUS_SECTION_* values
  if (previousSectionId === "" || previousSectionId !== currentSectionId) {
    previousTargetSection = {
      id: currentSectionId,
      sectionNum: currentSectionNumber
    }
  }

  if (completeSections) {
    dispatch({
      type: INIT_COMPLETED,
    });
  }

  // Store current section data as "previous" data (a moment before update)
  dispatch({
    type: PREVIOUS_SECTION_ID,
    payload: previousTargetSection.id
  });

  dispatch({
    type: PREVIOUS_SECTION_NUMBER,
    payload: previousTargetSection.sectionNum
  });

  // General SECTION Reset
  dispatch({
    type: SECTION_RESET
  });

  dispatch({
    type: RESET_CURRENT_STEP_ID
  });

  // Update and store current section data as "next" data
  dispatch({
    type: CURRENT_SECTION_ID,
    payload: nextTargetSection.id
  })

  dispatch({
    type: CURRENT_SECTION_NUMBER,
    payload: nextTargetSection.sectionNum
  })
}

export const goToPrevSection = () => (dispatch: Dispatch<SectionDispatchTypes>, getState: () =>
  RootState): any => {
  const {
    sections: { flowSections, currentSectionId, currentSectionNumber, previousSectionId }
  } = getState();

  const sections = pathOr({}, ['sections'], flowSections);
  const allSectionNumbers = sections.map(ele => ele.sectionNum)

  const minSections = Math.min(...allSectionNumbers);

  const predicateFirstSection = equals(currentSectionNumber, minSections);

  const result = getVal(allSectionNumbers, currentSectionNumber, true);
  const predicateBackSection = ele => equals(ele.sectionNum, result);
  const idToSendBackSection = find(predicateBackSection, sections);

  // Before dispatching to update CURRENT_SECTION_*, store PREVIOUS_SECTION_* values
  if (previousSectionId === "" || previousSectionId !== currentSectionId) {
    dispatch({
      type: PREVIOUS_SECTION_ID,
      payload: currentSectionId
    });

    dispatch({
      type: PREVIOUS_SECTION_NUMBER,
      payload: currentSectionNumber
    });
  }

  if (predicateFirstSection) {
    return false
  } else if (idToSendBackSection.enabled === true) {
    const { id, sectionNum } = idToSendBackSection || {};

    dispatch({
      type: SECTION_RESET
    });

    dispatch({
      type: RESET_CURRENT_STEP_ID
    });

    dispatch({
      type: CURRENT_SECTION_ID,
      payload: id
    })

    dispatch({
      type: CURRENT_SECTION_NUMBER,
      payload: sectionNum
    })
  } else {
    return false
  }

}

export const setCurrentSectionItem = (sectionNumber: number) => (dispatch: Dispatch<any>,
  getState: () => RootState): any => {
  const {
    sections: { currentSectionId, currentSectionNumber, previousSectionId, flowSections }
  } = getState();

  const sections = pathOr({}, ['sections'], flowSections);
  const predicateClickedSection = ele => ele.sectionNum === sectionNumber;
  const clickedSection = find(predicateClickedSection, sections);
  const { id, sectionNum } = clickedSection || {};

  // Before dispatching to update CURRENT_SECTION_*, store PREVIOUS_SECTION_* values
  if (previousSectionId === "" || previousSectionId !== currentSectionId) {
    dispatch({
      type: PREVIOUS_SECTION_ID,
      payload: currentSectionId
    });

    dispatch({
      type: PREVIOUS_SECTION_NUMBER,
      payload: currentSectionNumber
    });
  }

  // Dispatch: this action will set navigation direction forward, as a result when current step id is set
  // it will direct to the first step on the section rather than active.
  dispatch({
    type: NAV_DIRECTION,
    payload: "forward"
  });

  dispatch({
    type: SECTION_RESET
  });

  dispatch({
    type: RESET_CURRENT_STEP_ID
  });

  dispatch({
    type: CURRENT_SECTION_ID,
    payload: id
  });

  dispatch({
    type: CURRENT_SECTION_NUMBER,
    payload: sectionNum
  });
}

export const getSectionNumber = () => (dispatch: Dispatch<SectionDispatchTypes>, getState: () =>
  RootState): any => {
  const {
    sections: { flowSections }
  } = getState();

  const getSectionsLength = compose(
    length,
    propOr('', 'sections'),
  )(flowSections);

  dispatch({
    type: SECTION_NUMBER,
    payload: getSectionsLength
  })
}
