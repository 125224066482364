export default Object.freeze([
  {
    id: 'lid_1',
    regions: [
      {
        id: 'offCanvas',
        components: [
          {
            type: 'flowTitle',
            weight: -2,
            settings: [
              {
                type: 'element',
                param: 'h1'
              },
              {
                type: 'alignment',
                param: 'center'
              }
            ]
          },
          {
            type: 'sectionsNav',
            weight: -1,
            settings: null
          },
          {
            type: 'orgAbout',
            weight: 9999,
            settings: null
          }
        ] 
      },
      {
        id: 'sidebar',
        components: [
          {
            type: 'orgLogo',
            weight: -2,
            settings: [
              {
                type: "alignment",
                param: "center"
              }
            ]
          },
          {
            type: 'flowTitle',
            weight: -1,
            settings: [
              {
                type: 'element',
                param: 'h1'
              },
              {
                type: 'alignment',
                param: 'center'
              }
            ]
          },
          {
            type: 'orgAbout',
            weight: 9999,
            settings: null
          }
        ] 
      },
      {
        id: 'header',
        components: [
          {
            type: 'navToggle',
            weight: -2,
            settings: null
          },
          {
            type: 'orgLogo',
            weight: -1,
            settings: null
          }
        ] 
      },
      {
        id: 'footer',
        components: [
          {
            type: 'orgAbout',
            weight: 9999,
            settings: null
          },
          {
            type: 'chat',
            weight: 9999,
            settings: null
          }
        ] 
      }
    ]
  },
  {
    id: 'lid_2',
    regions: [
      {
        id: 'offCanvas',
        components: [
          {
            type: 'flowTitle',
            weight: -2,
            settings: [
              {
                type: 'element',
                param: 'h1'
              },
              {
                type: 'alignment',
                param: 'center'
              }
            ]
          },
          {
            type: 'sectionsNav',
            weight: -1,
            settings: null
          }
        ] 
      },
      {
        id: 'header',
        components: [
          {
            type: 'orgLogo',
            weight: -1,
            settings: null
          }
        ] 
      },
      {
        id: 'main',
        components: [
          {
            type: 'flowTitle',
            weight: 0,
            settings: [
              {
                type: 'element',
                param: 'h1'
              }
            ]
          }
        ]
      },
      {
        id: 'footer',
        components: [
          {
            type: 'orgAbout',
            weight: 9999,
            settings: null
          }
        ] 
      }
    ]
  },
  {
    id: 'lid_3',
    regions: [
      {
        id: 'offCanvas',
        components: [
          {
            type: 'flowTitle',
            weight: -2,
            settings: [
              {
                type: 'element',
                param: 'h1'
              },
              {
                type: 'alignment',
                param: 'center'
              }
            ]
          },
          {
            type: 'sectionsNav',
            weight: -1,
            settings: null
          },
          {
            type: 'orgAbout',
            weight: 9999,
            settings: null
          }
        ] 
      },
      {
        id: 'sidebar',
        components: [
          {
            type: 'orgLogo',
            weight: -2,
            settings: [
              {
                type: "alignment",
                param: "center"
              }
            ]
          },
          {
            type: 'flowTitle',
            weight: -1,
            settings: [
              {
                type: 'element',
                param: 'h1'
              },
              {
                type: 'alignment',
                param: 'center'
              }
            ]
          },
          {
            type: 'orgAbout',
            weight: 9999,
            settings: null
          }
        ] 
      },
      {
        id: 'header',
        components: [
          {
            type: 'navToggle',
            weight: -2,
            settings: null
          },
          {
            type: 'orgLogo',
            weight: -1,
            settings: null
          },
          {
            type: 'languageSwitcher',
            weight: 1,
            settings: null
          }
        ] 
      },
      {
        id: 'main',
        components: [
          {
            type: 'flowTitle',
            weight: 0,
            settings: [
              {
                type: 'element',
                param: 'h1'
              }
            ]
          }
        ]
      },
      {
        id: 'footer',
        components: [
          {
            type: 'orgAbout',
            weight: 9999,
            settings: null
          },
          {
            type: 'chat',
            weight: 9999,
            settings: null
          }
        ] 
      }
    ]
  },
  {
    id: 'lid_4',
    regions: [
      {
        id: 'offCanvas',
        components: [
          {
            type: 'flowTitle',
            weight: -2,
            settings: [
              {
                type: 'element',
                param: 'h1'
              },
              {
                type: 'alignment',
                param: 'center'
              }
            ]
          },
          {
            type: 'sectionsNav',
            weight: -1,
            settings: null
          }
        ] 
      },
      {
        id: 'header',
        components: [
          {
            type: 'navToggle',
            weight: -2,
            settings: null
          },
          {
            type: 'orgLogo',
            weight: -1,
            settings: null
          },
          {
            type: 'languageSwitcher',
            weight: 1,
            settings: null
          }
        ] 
      },
      {
        id: 'main',
        components: [
          {
            type: 'flowTitle',
            weight: 0,
            settings: [
              {
                type: 'element',
                param: 'h1'
              }
            ]
          }
        ]
      },
      {
        id: 'footer',
        components: [
          {
            type: 'orgAbout',
            weight: 9999,
            settings: null
          }
        ] 
      }
    ]
  }
]);
