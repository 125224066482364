/*
*
* @adapters
* This functions serve as backend - frontend adapters/adaptees to normalize
* specific backend non-transformable payload structures that need to be adapted
* to frontend field mappings.
*
*/

/*
*
* @CatalogAdapter
* Catalog payloads have specific attributes that need to be mapped to specific
* element attributes, as a result changing the strucuture
* 
* params:
* payload - an array of objects with a unique fields [id]
*
* output:
* a transformed array of object mapped with id as value, label to label, and the rest
* of the fields as specific refrences
* 
*/
const catalogAdapter:any = (payload:any, schema:any) => {
    const catalogAdaptee = payload.map((object) => {
        // Mapping payload specific fields [id]
        const idKeyReference = schema['id'];
        const iterableSchema = Object.keys(schema);
        // * For certain HTML elements "id" needs to get mapped from id to 
        // * the "value" attribute
        const uniqueAttrs = { value: object[idKeyReference] }
        // The rest of the mapped attributes 
        const otherAttrs = iterableSchema.map(function(key) {
            const value = object[schema[key]];
            return { [key]: value };
        });
        // Shallow object migration for these 2 objects.
        // ? Deeper merges can use lodash "merge" function 
        const mappedResults = Object.assign({}, uniqueAttrs, ...otherAttrs);
        return mappedResults;
    });
    return catalogAdaptee;
}

export { catalogAdapter };