import React from 'react';
import PropTypes from 'prop-types';

// Variants
import Pill from './Pill';
import Checkbox from './Checkbox';
import Radio from './Radio';

interface IProps {
  type: string
}

const Variant: React.FC<IProps> = props => {
  const {type} = props;
  switch (type) {
    case 'pill':
      return <Pill {...props} />;
    case 'checkbox':
      return <Checkbox {...props} />;
    case 'radio':
      return <Radio {...props} />;  
    default:
      return null;
  }
};

Variant.propTypes = {
  type: PropTypes.string.isRequired
};

export default Variant;