import React, { useState, useEffect } from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import { isEmpty } from 'ramda';

// Store
import { rootStore } from 'store/store';

const StepsStatus: React.FC = () => {
  const { flowSteps } = useSelector(({ stepper: { flowSteps } }: rootStore) => ({ flowSteps }), shallowEqual);
  const { stepLength } = useSelector(({ stepper: { stepLength } }: rootStore) => ({ stepLength }), shallowEqual);
  const { currentStepNumber } = useSelector(({
    stepper: { currentStepNumber }
  }: rootStore) => ({ currentStepNumber }), shallowEqual);
  const [activeStep, setActiveStep] = useState(0);
  
  useEffect(() => {
    if (!isEmpty(flowSteps)) {
      const indexOfActive = flowSteps.steps.findIndex(ele => ele.stepNumber === currentStepNumber);
      setActiveStep(indexOfActive);
    }
  }, [flowSteps, currentStepNumber]);

  return (
    <div> 
      Paso <span>{ activeStep + 1 } de { stepLength }</span>
    </div>
  )
}

export default StepsStatus;
