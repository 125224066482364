import {  
  SECTIONS_FAIL, 
  SECTIONS_LOADING, 
  SECTIONS_SUCCESS,
  SECTIONS_SUCCESS_BY_ID,
  SECTION_LOADING,
  SECTION_FAIL,
  SECTION_SUCCESS,
  SECTION_NUMBER,
  PREVIOUS_SECTION_ID,
  PREVIOUS_SECTION_NUMBER,
  CURRENT_SECTION_ID,
  CURRENT_SECTION_NUMBER,
  SECTION_RESET,
} from "store/actionTypes/ActionsTypes";

import {
  SectionDispatchTypes, 
  SectionsInfo,
  SectionInfo
} from '../../models/index';

export interface InitialStateI {
  loadingSections: boolean,
  loadingSection: boolean,
  flowSection: SectionInfo | any,
  flowSections: SectionsInfo | any,
  flowSectionsById: SectionsInfo | any,
  sectionLength: SectionsInfo | any,
  currentSectionId: string,
  currentSectionNumber: number,
  previousSectionId: string,
  previousSectionNumber: number
}

const initialState:InitialStateI = {
  loadingSections: false,
  loadingSection: false,
  flowSection: {},
  flowSections: {},
  flowSectionsById: {},
  sectionLength: 0,
  currentSectionId: '',
  currentSectionNumber: 0,
  previousSectionId: '',
  previousSectionNumber: 0
};

const sectionsReducer = (state: InitialStateI = initialState, action: SectionDispatchTypes ) : InitialStateI => {
  switch (action.type) {
    case SECTIONS_FAIL: {
      return {
        ...state,
        loadingSections: false,
      }
    }
    case SECTIONS_LOADING: {
      return {
        ...state,
        loadingSections: true,
      }
    }
    case SECTIONS_SUCCESS: {
      return {
        ...state,
        loadingSections: false,
        flowSections: action.payload,
      }
    }
    case SECTIONS_SUCCESS_BY_ID: {
      return {
        ...state,
        flowSectionsById: action.payload
      }
    }
    case SECTION_FAIL: {
      return {
        ...state,
        loadingSection: false,
      }
    }
    case SECTION_LOADING: {
      return {
        ...state,
        loadingSection: true,
      }
    }
    case SECTION_SUCCESS: {
      return {
        ...state,
        loadingSection: false,
        flowSection: action.payload
      }
    }
    case SECTION_NUMBER: {
      return {
        ...state,
        sectionLength: action.payload
      }
    }
    case PREVIOUS_SECTION_ID: {
      return {
        ...state,
        previousSectionId: action.payload
      }
    }
    case PREVIOUS_SECTION_NUMBER: {
      return {
        ...state,
        previousSectionNumber: action.payload
      }
    }
    case CURRENT_SECTION_ID: {
      return {
        ...state,
        currentSectionId: action.payload
      }
    }
    case CURRENT_SECTION_NUMBER: {
      return {
        ...state,
        currentSectionNumber: action.payload
      }
    }
    case SECTION_RESET: {
      return {
        ...state,
        flowSection: {},
        currentSectionId: ''
      }
    }
    default: return state
  }
};

export default sectionsReducer;