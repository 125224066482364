import React, { FC, useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { pick, isNil, includes, is } from 'ramda';

import useCloseOnForeginEvent from 'hook/useCloseOnForeginEvent';

// HOC
import withField from 'hoc/withField';

interface FieldProps {
  field: any;
  meta: any;
  helpers: any;
  values: any;
}

interface WidgetProps extends FieldProps {
  name: string;
  multiple: [] | any;
  widget: any;
  placeholder: string | any;
  disabled: boolean | any;
  help: string | any;
  options: [] | any;
}

const ObjectCatalog: FC<WidgetProps> = (props) => {
  const { name, multiple, widget, help, field, meta, helpers, values } = props;
  const [searchText, setSearchText] = useState<string>('');
  const objectCatalogContainer = useRef();
  useCloseOnForeginEvent(objectCatalogContainer, () => {
    setSearchText('');
  });

  const {
    settings: { entityOptions },
  } = widget;

  function onClickFilterHandler(fieldId) {
    const value = !isNil(multiple)
      ? field.value.filter((field) => {
          return field !== fieldId;
        })
      : '';
    helpers.setValue(value, true);
  }

  function onChangeInputHandler(event) {
    setSearchText(event.target.value);
  }

  function onClickListHandler(id) {
    if (!isNil(multiple)) {
      const previousValues = field.value;
      const nextValues = previousValues.includes(id)
        ? previousValues.filter((idValue) => idValue !== id)
        : previousValues.concat(id);
      helpers.setValue(nextValues, true);
    } else {
      helpers.setValue(id, true);
    }
    setSearchText('');
  }

  const filterCatalogOptions = (text) => {
    const regex = new RegExp(`^${text}`, 'i');
    const filteredCatalog = entityOptions
      .filter((entityOptionsField) => {


        return regex.test(entityOptionsField.field_1) && !includes(entityOptionsField.id, field.value);
      })
      .sort()
      .map((entityOptionsField, index) => {
        return (
          <li
            key={index}
            onClick={() => {
              onClickListHandler(entityOptionsField.id);
            }}
          >
            <div className='media'>
              {!isNil(entityOptionsField.image) && (
                <img
                  src={entityOptionsField.image}
                  className='align-self-center mr-3'
                  alt={`${name}-${entityOptionsField.field_1}`}
                />
              )}
              <div className='media-body'>
                <p className='mt-0'>{entityOptionsField.field_1}</p>
                <ul className='object-catalog_selection__details'>
                  <li>{entityOptionsField.field_2}</li>
                  <li>{entityOptionsField.field_3}</li>
                  <li>{entityOptionsField.field_4}</li>
                  <li>{entityOptionsField.field_5}</li>
                </ul>
              </div>
            </div>
          </li>
        );
      })
      .slice(0, 5);

    return text.length ? filteredCatalog : [];
  };

  function updateFilters(filterIds) {
    if (!filterIds) {
      return false;
    }

    const filters = is(String, filterIds) ? [filterIds] : filterIds;
    return filters.map((id) => {
      const filter = entityOptions.filter((entityOptionsField) => {
        return id === entityOptionsField.id;
      })[0];
      return (
        <li
          key={`filter-${id}`}
          onClick={() => {
            onClickFilterHandler(id);
          }}
        >
          {filter.field_1}
        </li>
      );
    });
  }

  return (
    <div ref={objectCatalogContainer} className='object-catalog'>
      <div className={`${meta.touched && meta.error && 'is-invalid'}`}>
        <input
          type='text'
          placeholder='Catalogo con búsqueda'
          name={name}
          onChange={(event) => {
            onChangeInputHandler(event);
          }}
          value={searchText}
          className={`form-control form-control-underline`}
        />
        <ul className='object-catalog__selection'>
          {filterCatalogOptions(searchText)}
        </ul>
        <ul className='object-catalog__filters'>
          {updateFilters(field.value)}
        </ul>
      </div>
      {meta.touched && meta.error && (
        <div className='invalid-feedback'>{meta.error}</div>
      )}
      {help && <small className='form-text text-muted'>{help}</small>}
    </div>
  );
};

ObjectCatalog.propTypes = {
  field: PropTypes.shape({
    name: PropTypes.string.isRequired,
    onBlur: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    value: PropTypes.any,
  }),
  meta: PropTypes.shape({
    error: PropTypes.string,
    touched: PropTypes.bool,
  }),
  helpers: PropTypes.shape({
    setValue: PropTypes.func.isRequired,
  }),
  values: PropTypes.object,
  name: PropTypes.string.isRequired,
  widget: PropTypes.object.isRequired,
  multiple: PropTypes.array,
  help: PropTypes.string,
};

export default withField(ObjectCatalog);
