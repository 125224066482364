import React, { useEffect, useState, useCallback, memo } from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import { sortBy, prop, pathOr } from 'ramda';

// Store
import { rootStore } from 'store/store';

// API
import { fetchStep } from 'api/dataFetching';

// Components
import RenderComponent from 'components/flow/RenderComponent';
import Spinner from 'components/common/Spinner';

// Utils
import { scrollToTop } from 'utils/sharedFuncs';

const Step: React.FC = () => {
  const { currentStepId } = useSelector(
    ({ stepper: { currentStepId } }: rootStore) => ({ currentStepId }),
    shallowEqual
  );
  const [components, setComponents] = useState([]);
  const [loading, setLoading] = useState(false);

  const getStep = useCallback(async () => {
    try {
      setLoading(true);
      const res = await fetchStep(currentStepId);
      const sortedComponents = sortBy(
        prop('weight'),
        pathOr([], ['components'], res.data)
      );
      setComponents(sortedComponents);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
      scrollToTop();
    }
  }, [currentStepId]);

  useEffect(() => {
    if (currentStepId) {
      getStep();
    }
  }, [currentStepId, getStep]);

  return (
    <React.Fragment>
      {loading ? (
        <Spinner loader='Grid' position='middle' width='40' height='40' />
      ) : (
        components.map((component, index) => (
          <RenderComponent
            key={`step-render-component-${index}`}
            elementSchema={component}
          />
        ))
      )}
    </React.Fragment>
  );
};

export default memo(Step);
