import React from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { equals, pathOr } from 'ramda';

// Store
import { rootStore } from 'store/store';

// Actions
import { backStep } from 'store/actions/stepperActions';

// Components
import BtnIcon from 'components/common/BtnIcon';

const StepsBack: React.FC = () => {
  const dispatch = useDispatch();
  const { flowSections } = useSelector(
    ({ sections: { flowSections } }: rootStore) => ({ flowSections }),
    shallowEqual
  );
  const { flowSteps } = useSelector(
    ({ stepper: { flowSteps } }: rootStore) => ({ flowSteps }),
    shallowEqual
  );
  const { currentStepNumber } = useSelector(
    ({ stepper: { currentStepNumber } }: rootStore) => ({ currentStepNumber }),
    shallowEqual
  );
  const { currentSectionNumber } = useSelector(
    ({ sections: { currentSectionNumber } }: rootStore) => ({
      currentSectionNumber,
    }),
    shallowEqual
  );

  const sections = pathOr([], ['sections'], flowSections);
  const allSectionNumbers = sections.map((ele) => ele.sectionNum);
  const resultSections = Math.min(...allSectionNumbers);

  const steps = pathOr([], ['steps'], flowSteps);
  const allStepNumbers = steps.map((ele) => ele.stepNumber);
  const resultSteps = Math.min(...allStepNumbers);

  const predicateToStepBack =
    equals(currentStepNumber, resultSteps) &&
    equals(currentSectionNumber, resultSections);

  const flowStep =
    currentStepNumber > 0 &&
    flowSteps.steps.filter((step) => {
      return currentStepNumber === step.stepNumber;
    })[0];

  return (
    <>
      {!predicateToStepBack && currentStepNumber !== 0 && flowStep.navBack ? (
        <BtnIcon
          size={50}
          onClick={() => dispatch(backStep())}
          className='mr-2'
          aria-label='Paso anterior'
        >
          <i className='material-icons'>arrow_back</i>
        </BtnIcon>
      ) : null}
    </>
  );
};

export default StepsBack;
