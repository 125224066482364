import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { isNil } from 'ramda';

// Widget
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { format } from 'date-fns';
import { es } from 'date-fns/locale';
import { isEmpty } from 'ramda';

// Fetching
import { releaseSlots, postSlots } from 'api/dataFetching';

// Values
import initialValues from 'components/form/values/initialValues';

// Components
import Pill from 'components/form/variants/Pill';

// HoC
import withField from 'hoc/withField';
import { useSelector, shallowEqual } from 'react-redux';
import { rootStore } from 'store/store';

interface FieldProps {
  field: any;
  meta: any;
  helpers: any;
}

interface WidgetProps extends FieldProps {
  name: string;
  widget: any;
  label: string;
  popover: string | any;
  help: string | any;
  references: [] | any;
}

const SlotSelector: React.FC<WidgetProps> = ({
  name,
  widget,
  help,
  meta,
  helpers,
  field,
}) => {
  const {
    settings: {
      vendorExternalId,
      skuNumber,
      appointmentSpan,
      employeeExternalId,
      orgExternalId,
      objectElements,
      vendorName,
      skuName
    },
  } = widget;

  const [days, setDays] = useState([]);
  const [hours, setHours] = useState(null);
  const [slots, setSlots] = useState(null);
  const [selectedDay, setSelectedDay] = useState(null);
  const [selectedHour, setSelectedHour] = useState(null);
  const [selectedSlot, setSelectedSlot] = useState(null);
  const { recordId } = useSelector(
    ({
      initial: {
        init: { recordId },
      },
    }: rootStore) => ({ recordId }),
    shallowEqual
  );
  useEffect(() => {
    getAvailability();
  }, []);

  const getAvailability = async () => {
    const payload = {
      skuNumber: skuNumber,
      appointmentSpan: appointmentSpan,
      orgExternalId: orgExternalId,
      vendorExternalId: vendorExternalId,
      employeeExternalId: employeeExternalId,
    };

    try {
      const res = await postSlots(payload);
      const { data } = res;
      const days = data.contents.days;
      setDays(days);
    } catch (error) {
      console.log(error);
    }
  };

  const avalibleHours = day => {
    helpers.setValue(objectElements.reduce(initialValues, {}), true);
    setSelectedDay(day);
    setSelectedSlot(null);
    setSelectedHour(null);
    setSlots(null);
    const d = days.find(
      ({ date, hours }) =>
        date.split('T')[0] === day.split('T')[0] && !isEmpty(hours)
    );
    if (!isNil(d) && !isEmpty(d)) {
      setHours(d.hours);
    } else {
      setHours(null);
    }
  };

  const avalibleSlots = hour => {
    helpers.setValue(objectElements.reduce(initialValues, {}), true);
    setSelectedSlot(null);
    setSelectedHour(hour.time);
    const slots = hour.slots;
    if (!isEmpty(slots)) {
      setSlots(slots);
    } else {
      setSlots(null);
    }
  };

  const setAppointment = slot => {
    setSelectedSlot(slot.startTime);
    const slotTimeframe = {
      startTime: slot.startDatetime,
      endTime: slot.endDatetime,
    };
    helpers.setValue(slotTimeframe, true);
  };

  const reschedule = async () => {
    await releaseSlots(recordId);
    helpers.setValue(objectElements.reduce(initialValues, {}), true);
    setSelectedSlot(null);
  };

  const formatHour = time => {
    const day = new Date(selectedDay);
    const hourParams = time.split(':');
    day.setHours(hourParams[0], hourParams[1], hourParams[2]);
    return format(day, 'hh:mm aa');
  };

  return (
    <React.Fragment>
      {!isEmpty(field.value.startTime) ? (
        <div className='row no-gutters'>
          <div className='col-lg-9 col-xl-6 box mb-2'>
            <div className='box__body'>
              <div className='text-muted mb-3'>
                <span>El horario de tu cita</span>
              </div>
              {!isNil(vendorName) &&
                <div className='text-muted mb-1'><strong>Sucursal:</strong>{` ${vendorName}`}</div>}
              {!isNil(skuName) &&
                <div className='text-muted mb-1'><strong>Producto:</strong>{` ${skuName}`}</div>}
              <div className='h4 mb-1'>
                <span className='text-capitalize'>
                  {`${format(new Date(field.value.startTime), 'PPPP', {
                    locale: es,
                  })}           
                ${format(new Date(field.value.startTime), 'hh:mm aa', {
                    locale: es,
                  })}`}
                </span>
              </div>
              <div className='pt-2'>
                <button
                  type='button'
                  className='button button--reset button--primary'
                  onClick={() => reschedule()}
                >
                  Cambiar horario
                </button>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <React.Fragment>
          <div className='row justify-content-start'>
            <div className='col-auto'>
              <DatePicker
                inline
                id={name}
                name={name}
                dateFormat={'dd/MM/yyyy'}
                locale={es}
                filterDate={day =>
                  days.some(
                    d => d.date.split('T')[0] === format(day, 'yyyy-MM-dd')
                  )
                }
                onChange={date => avalibleHours(date.toISOString())}
              />
            </div>
            {hours ? (
              <div className='col'>
                <div className='hours'>
                  <div className='h4'>Selecciona una hora</div>
                  {hours.map((hour, i) => (
                    <Pill
                      key={`hour-${i}`}
                      name={`hour-${i}`}
                      id={`hour-${i}`}
                      label={formatHour(hour.time)}
                      checked={hour.time === selectedHour}
                      disabled={false}
                      onChange={() => avalibleSlots(hour)}
                    />
                  ))}
                </div>
                {slots ? (
                  <div className='slots mt-3'>
                    <div className='h4'>Selecciona tu cita</div>
                    {slots.map((slot, i) => (
                      <Pill
                        key={`slot-${i}`}
                        name={`slot-${i}`}
                        id={`slot-${i}`}
                        label={format(new Date(slot.startDatetime), 'hh:mm aa')}
                        checked={slot.startTime === selectedSlot}
                        disabled={false}
                        onChange={() => setAppointment(slot)}
                      />
                    ))}
                  </div>
                ) : null}
              </div>
            ) : null}
          </div>
        </React.Fragment>
      )}
      {meta.touched && meta.error && (
        <div className='invalid-feedback d-block'>Debes elegir un horario</div>
      )}
      {help && <small className='form-text text-muted'>{help}</small>}
    </React.Fragment>
  );
};

SlotSelector.propTypes = {
  field: PropTypes.shape({
    name: PropTypes.string.isRequired,
    onBlur: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    value: PropTypes.any,
  }),
  meta: PropTypes.shape({
    error: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    touched: PropTypes.bool,
  }),
  helpers: PropTypes.shape({
    setValue: PropTypes.func.isRequired,
  }),
  name: PropTypes.string.isRequired,
  widget: PropTypes.object.isRequired,
  help: PropTypes.string,
};

SlotSelector.defaultProps = {
  popover: null,
  help: null,
  references: null,
};

export default withField(SlotSelector);
