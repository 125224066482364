import React, { useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import PropTypes from 'prop-types';
import uuid from 'react-uuid';

// Store
import { rootStore } from 'store/store';

// Actions
import { closeMenu } from 'store/actions/offCanvasActions';

// Components
import RenderComponent from 'components/flow/RenderComponent';

// HOC
import withRegion from 'hoc/withRegion';

const OffCanvas = ({ classes, components }) => {
  const dispatch = useDispatch();
  const { open } = useSelector(({ offCanvas }: rootStore) => offCanvas);
  const initialRender = useRef(true);
  const isMobile = useMediaQuery({maxWidth: 768})

  useEffect(() => {
    if (initialRender.current) {
      initialRender.current = false;
    } else {
      !isMobile && dispatch(closeMenu());
    }
  }, [dispatch, isMobile]);

  if (open) {
    document.body.classList.add('off-canvas--open');
  } else {
    document.body.classList.remove('off-canvas--open');
  }

  return (
    <div className={`${classes} ${open ? 'open' : 'hidden'}`} id="off-canvas">
      <div className="region__info">
        <div className="region-name">Region: Off Canvas</div>
      </div>
      <div className="region__content">
        {
          components.map(component => (
            <RenderComponent
              key={uuid()}
              elementSchema={component}
            />
          ))
        }
      </div>
    </div>
  );
};

OffCanvas.propTypes = {
  classes: PropTypes.string,
  components: PropTypes.array,
};

OffCanvas.defaultProps = {
  classes: '',
  components: [],
};

export default withRegion(OffCanvas, 'offCanvas');
