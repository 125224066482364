import {  
  STEPS_FAIL, 
  STEPS_LOADING, 
  STEPS_SUCCESS,
  STEPS_SUCCESS_BY_ID,
  STEP_FAIL,
  STEP_LOADING,
  STEP_SUCCESS,
  STEP_NUMBER,
  CURRENT_STEP_ID,
  CURRENT_STEP_NUMBER,
  REDIRECT,
  RESET_CURRENT_STEP_ID,
  NAV_DIRECTION
} from "store/actionTypes/ActionsTypes";

import {
  StepperDispatchTypes, 
  StepsInfoI,
  StepInfoI,
} from '../../models/index';

export interface InitialStateI {
  loadingStep?: boolean,
  flowSteps?: StepsInfoI | any,
  flowStepsById?: StepsInfoI | any,
  flowStep?: StepInfoI | any,
  stepLength: number,
  stepModal: boolean,
  loadingSteps: boolean,
  redirectTo: string,
  currentStepId: string,
  currentStepNumber: number,
  stepStatus: null,
  isClicked: boolean
  navDirection: string,
}

const initialState: InitialStateI = {
  loadingSteps: false,
  loadingStep: false,
  flowSteps: [],
  flowStep: [],
  flowStepsById: {},
  stepLength: 0,
  stepModal: false,
  redirectTo: '',
  currentStepNumber: 0,
  currentStepId: '',
  stepStatus: null,
  isClicked: false,
  navDirection: ''
};

const steppersReducer = (state: InitialStateI = initialState, action: StepperDispatchTypes ) : InitialStateI => {
  switch (action.type) {
    case STEPS_FAIL: {
      return {
        ...state,
        loadingSteps: false,
      }
    }
    case STEPS_LOADING: {
      return {
        ...state,
        loadingSteps: true
      }
    }
    case STEPS_SUCCESS_BY_ID: {
      return {
        ...state,
        flowStepsById: action.payload
      }
    }
    case STEPS_SUCCESS: {
      return {
        ...state,
        loadingSteps: false,
        flowSteps: action.payload
      }
    }
    case STEP_FAIL: {
      return {
        ...state,
        loadingStep: false,
      }
    }
    case STEP_LOADING: {
      return {
        ...state,
        loadingStep: true,
      }
    }
    case STEP_SUCCESS: {
      return {
        ...state,
        loadingStep: false,
        flowStep: action.payload
      }
    }
    case STEP_NUMBER: {
      return {
        ...state,
        stepLength: action.payload
      }
    }
    case RESET_CURRENT_STEP_ID: {
      return {
        ...state,
        currentStepId: '',
        currentStepNumber: 0,
        stepLength: 0,
        flowSteps: []
      }
    }
    case CURRENT_STEP_ID: {
      return {
        ...state,
        currentStepId: action.payload
      }
    }
    case CURRENT_STEP_NUMBER: {
      return {
        ...state,
        currentStepNumber: action.payload
      }
    }
    case REDIRECT: {
      return {
        ...state,
        redirectTo: action.payload
      }
    }
    case NAV_DIRECTION:{
      return {
        ...state,
        navDirection: action.payload
      }
    }

    default: return state
  }
};

export default steppersReducer;