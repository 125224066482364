export const getVal = (array: [], val: number, dir?: boolean) => {
  for (let i = 0; i < array.length; i++) {
    if (dir === true) {
      if (array[i] >= val) {
        return array[i - 1] || 0;
      }
    } else {
      if (array[i] > val) {
        return array[i];
      }
    }
  }
}

export const findId = (id, arr) => {
  return arr.reduce((a, item) => {
    if (a) return a;
    if (item.categoryId === id) return item;
    if (item.childs) return findId(id, item.childs);
  }, null);
}

export const scrollToTop = () => {
  const windowObject: Window = window;
  if (windowObject.innerWidth < 768) {
    const bodyElement: Element | null =
      document.getElementsByTagName('body')[0];
    bodyElement.scrollIntoView();
  } else {
    const DOMelementWithIdSteps: Element | null =
      document.getElementById('steps');
    if (DOMelementWithIdSteps) {
      const DOMelementParentOfSteps: Element | null =
        DOMelementWithIdSteps.parentElement;
      DOMelementParentOfSteps?.scrollTo(0, 0);
    }
  }
}

export const formatValue = (format: string, value: string): string => {
  let val: string;

  switch (format) {
    case 'uppercase':
      val = value.toUpperCase();
      break;
    case 'lowercase':
      val = value.toLowerCase()
      break;
    default:
      val = value;
      break;
  }
  return val;
}

export const redirect = (url: string): any =>{
  window.location.href = url;
}