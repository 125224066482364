class CredentialsStore {
    private static _instance: CredentialsStore;
    private _instanceKey = "";
    private _instanceValue = "";

    private constructor() {
        if (CredentialsStore._instance) {
            throw new Error("Usage: CredentialsStore._instance");
        }
        CredentialsStore._instance = this;
    }

    public static get instance() {
        return CredentialsStore._instance ?? (CredentialsStore._instance = new CredentialsStore());
    }

    public attemptSessionRestore(key: string): boolean {
        const valuesInLS = window.localStorage.getItem(key);
        const dataInStorage = valuesInLS === null ? false : true;

        if (dataInStorage) {
            this._instanceKey = key;
            this._instanceValue = valuesInLS;
            return true;
        }
        return false;
    }

    public set(key: string, value: string): void {
        if (key === "") {
            throw new Error("LocalStorage: Key cannot be empty");
        }
        this._instanceKey = key;
        this._instanceValue = value;
        window.localStorage.setItem(this._instanceKey, this._instanceValue);
    }

    public hasProperty(propertyName: string): boolean {
        const value = JSON.parse(this._instanceValue);
        return value[propertyName] ? true : false;
    }

    public getKey(): string {
        return this._instanceKey;
    }

    public getValue(): string {
        return this._instanceValue;
    }

    public clear(key: string): void {
        if (key === "") {
            throw new Error("LocalStorage: No key provided");
        }
        this._instanceKey = "";
        this._instanceValue = "";
        window.localStorage.removeItem(key);
    }
}

export const credentialsStore = CredentialsStore.instance;
