import React from 'react';
import PropTypes from 'prop-types';

// HOC
import withField from 'hoc/withField';

interface FieldProps {
  field: any;
  meta: any;
  helpers: any;
}

interface WidgetProps extends FieldProps {
  name: string;
  label: string;
  placeholder: string | any;
  popover: string | any;
  help: string | any;
  disabled: boolean | any;
  references: [] | any;
}

const UrlField: React.FC<WidgetProps> = ({
  disabled,
  field,
  help,
  meta,
  name,
  placeholder
}) => { 
  return (
    <React.Fragment>
      <input
        {...field}
        type='url'
        id={name}
        placeholder={placeholder}
        disabled={disabled}
        autoComplete='off'
        className={`form-control ${meta.touched && meta.error && 'is-invalid'}`}
      />
      {meta.touched && meta.error && (
        <div className='invalid-feedback'>{meta.error}</div>
      )}
      {help && <small className='form-text text-muted'>{help}</small>}
    </React.Fragment>
  );
};

UrlField.propTypes = {
  field: PropTypes.shape({
    name: PropTypes.string.isRequired,
    onBlur: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    value: PropTypes.any,
  }),
  meta: PropTypes.shape({
    error: PropTypes.string,
    touched: PropTypes.bool,
  }),
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  help: PropTypes.string,
  disabled: PropTypes.bool,
};

UrlField.defaultProps = {
  placeholder: null,
  help: null,
  disabled: false,
};

export default withField(UrlField);


// Todo: url no worky.. :..(