import React, { useState, useEffect, useRef } from 'react';
import { Redirect, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useAppInsightsContext } from "@microsoft/applicationinsights-react-js";
import {
  ModalHeader,
  ModalBody,
  ModalFooter,
} from 'reactstrap';

// Fetching
import { postTerms } from 'api/dataFetching';

// Store
import { rootStore } from 'store/store';

// Hooks
import useDocumentTitle from "hook/useDocumentTitle";

// Utils
import themeBuilder, { styleElement } from 'utils/themeBuilder';

// Componentes
import Modal from 'components/common/Modal';
import DOMPurify from 'dompurify';

interface ParamTypes {
  orgId: string;
  flowId: string;
}

const Welcome: React.FC = () => {
  const { orgId, flowId } = useParams<ParamTypes>();
  const appInsights = useAppInsightsContext();
  appInsights.trackMetric("Component 'Welcome' is in use");

  const [modalTAndC, setModalTAndC] = useState(false);
  const [modalPP, setModalPP] = useState(false);
  const buttonRef = useRef();


  const { init: {
    isAuthorized,
    termsConditions,
    theme,
    org: {
      name: org
    },
    flow: {
      name: flow,
      termsAndConditions,
      privacyPolicy,
      image
    },  
    welcome : { 
      title, 
      description, 
      button,
    } }, 
  } = useSelector(({ initial }: rootStore) => initial);
  useDocumentTitle(`Bienvenido: ${flow} - ${org}`);
  const safeHtmlTAndC = DOMPurify.sanitize(termsAndConditions);
  const safeHtmlPP = DOMPurify.sanitize(privacyPolicy);

  
  const routeChange = async () => {
    buttonRef.current.disabled = true;
    try {
      const res = await postTerms();
      if (res.status === 200) {
        window.location = `/${orgId}/${flowId}`;
      }
      /* const { data } = res;
      if (data === 'accepted') {
        window.location = `/${orgName}/${flowName}`;
      } */
    } catch (error) {
      console.log(error);
      buttonRef.current.disabled = false;
    }
  }

  useEffect(() => {
    const themeStyle = themeBuilder(theme);
    const style = styleElement();
    style.sheet.insertRule(themeStyle);
    document.body.classList.add('custom-theme');

    return () => { style.remove(); document.body.classList.remove('custom-theme') }
  });

  return (
    <React.Fragment>
      {
        !isAuthorized ? <Redirect to={`/${orgId}/${flowId}/auth`} /> : 
        termsConditions ? <Redirect to={`/${orgId}/${flowId}`} /> : (
          <div className="view view--full-height">
            <div className="grid--vertical">
              <div className="d-flex flex-grow-1 flex-column overflow-auto justify-content-sm-center">
                <div className="container-fluid">
                  <div className="row justify-content-center">
                    <div className="col-md-10 col-lg-8 col-xl-5"> 
                      <div className="box mt-3">
                        <div className="row no-gutters">
                          <div className="box__left box__left--sm bg bg--primary col-md-6 p-0"> 
                            <img src={image} alt={flow} className="img-fluid mt-5 mr-3 mb-5 ml-3" />
                          </div>
                          <div className="col-md-6">
                            <div className="p-4">
                              <h3>
                                {title}
                              </h3>
                              <p>
                                {description}
                              </p>
                              <p>
                                <button
                                  type="button"
                                  className="button button--reset button--primary"
                                  onClick={() => setModalTAndC(true)}
                                >
                                  Términos y condiciones
                                </button>
                                {' y '}
                                <button
                                  type="button"
                                  className="button button--reset button--primary" 
                                  onClick={() => setModalPP(true)}
                                >
                                  aviso de privacidad
                                </button>
                              </p>
                              <button
                                ref={buttonRef}
                                type="button"
                                className="btn btn-primary btn-block"
                                onClick={() => routeChange()}
                              >
                                {button}
                              </button>
                            </div>
                          </div>
                        </div>  
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <Modal
              modal={modalTAndC}
              setModal={setModalTAndC}
            >
              {
                ({toggle}) => (
                  <React.Fragment>
                    <ModalHeader toggle={toggle} tag="h2">
                      Términos y Condiciones
                    </ModalHeader>
                    <ModalBody>
                      <div dangerouslySetInnerHTML={{ __html: safeHtmlTAndC }} />
                    </ModalBody>
                    <ModalFooter>
                      <button
                        type="button"
                        className="btn btn-primary"
                        onClick={toggle}
                      >
                        Aceptar
                      </button>
                    </ModalFooter>
                  </React.Fragment>
                )
              }
            </Modal>
            <Modal
              modal={modalPP}
              setModal={setModalPP}
            >
              {
                ({toggle}) => (
                  <React.Fragment>
                    <ModalHeader toggle={toggle} tag="h2">
                      Aviso de privacidad
                    </ModalHeader>
                    <ModalBody>
                      <div dangerouslySetInnerHTML={{ __html: safeHtmlPP }} />
                    </ModalBody>
                    <ModalFooter>
                      <button
                        type="button"
                        className="btn btn-primary"
                        onClick={toggle}
                      >
                        Aceptar
                      </button>
                    </ModalFooter>
                  </React.Fragment>
                )
              }
            </Modal>
          </div>
        )
      }
    </React.Fragment>
  );
};

export default Welcome;
