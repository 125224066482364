import React from 'react';
import PropTypes from 'prop-types';
import { isNil, isEmpty } from 'ramda';

// HOC
import withField from 'hoc/withField';
import NumberFormat from 'react-number-format';

interface FieldProps {
  field: any;
  helpers: any;
  meta: any;
}

interface WidgetProps extends FieldProps {
  name: string;
  widget: any;
  placeholder: string | any;
  help: string | any;
  disabled: boolean | any;
}

const NumberField: React.FC<WidgetProps> = ({
  field,
  meta,
  widget,
  name,
  placeholder,
  help,
  disabled,
  helpers,
}) => {
  const { settings } = widget;
  const { min, max, step, fieldFormatting, prefix } = settings || {};
  return (
    <React.Fragment>
      {!isNil(fieldFormatting) &&
      (fieldFormatting === 'number' || fieldFormatting === 'currency') ? (
        <NumberFormat
          className={`form-control ${
            meta.touched && meta.error && 'is-invalid'
          }`}
          displayType={'input'}
          thousandSeparator={true}
          isNumericString={true}
          prefix={prefix}
          value={!isEmpty(field.value) ? field.value : null}
          name={name}
          onValueChange={val => helpers.setValue(val.floatValue, true)}
        />
      ) : (
        <input
          {...field}
          type='number'
          min={min}
          max={max}
          step={step}
          placeholder={placeholder}
          disabled={disabled}
          autoComplete='off'
          id={name}
          className={`form-control ${
            meta.touched && meta.error && 'is-invalid'
          }`}
        />
      )}
      {meta.touched && meta.error && (
        <div className='invalid-feedback'>{meta.error}</div>
      )}
      {help && <small className='form-text text-muted'>{help}</small>}
    </React.Fragment>
  );
};

NumberField.propTypes = {
  field: PropTypes.shape({
    name: PropTypes.string.isRequired,
    onBlur: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    value: PropTypes.any,
  }),
  meta: PropTypes.shape({
    error: PropTypes.string,
    touched: PropTypes.bool,
  }),
  helpers: PropTypes.shape({
    setValue: PropTypes.func.isRequired,
    setTouched: PropTypes.func.isRequired,
  }),
  name: PropTypes.string.isRequired,
  widget: PropTypes.object.isRequired,
  placeholder: PropTypes.string,
  help: PropTypes.string,
  disabled: PropTypes.bool,
};

NumberField.defaultProps = {
  placeholder: null,
  help: null,
  disabled: false,
};

export default withField(NumberField);
