import React from 'react';
import PropTypes from 'prop-types';

interface IProps {
    value: string;
    onClick?: React.MouseEventHandler<HTMLButtonElement>;
    type?: string;
}

const Button: React.FC<IProps> = ({ value, onClick, type }) => {
  return (
    <button
      type='button'
      className={`btn btn-sm-block btn-lg ${type}`}
      onClick={onClick}
    >
      {value}
    </button>
  );
};

Button.propTypes = {
  value: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  type: PropTypes.string,
};

export default Button;