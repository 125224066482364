import { equals, isNil, isEmpty, gt, lt} from 'ramda';

export const equal = (val1, val2) => equals(val1, val2);

export const notEqual = (val1, val2) => !equals(val1, val2);

export const notEmpty = val => !isEmpty(val);

export const notNil = val => !isNil(val);

export const greaterThan = (val1, val2) => gt(val1, val2);

export const lowerThan = (val1, val2) => lt(val1, val2);

export const test = (testType, val1, val2) => {
  switch(testType){
    case 'equals':
      if (!isEmpty(val1) && equal(val1, val2)) {
        return true;
      } else {
        return false;
      }
    case 'notEquals': 
      if (!isEmpty(val1) && notEqual(val1, val2)) {
        return true;
      } else {
        return false;
      }
    case 'notEmpty': 
      if (notEmpty(val1)) {
        return true;
      } else {
        return false;
      }
    case 'greaterThan':
      if (!isEmpty(val1)) {
        if (Array.isArray(val1) && greaterThan(val1.length, val2)) {
          return true;
        } else if (greaterThan(val1, val2)) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    case 'lowerThan':
      if (!isEmpty(val1)) {
        if (Array.isArray(val1) && lowerThan(val1.length, val2)) {
          return true;
        } else if (lowerThan(val1, val2)) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    default:
      return false
  }
}