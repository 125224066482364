import { Dispatch } from 'redux';
import { TOGGLE_MENU, CLOSE_MENU } from '../actionTypes/ActionsTypes';
import { MenuDispatchTypes } from '../../models/index';

export const toggleMenu = () => (dispatch: Dispatch<MenuDispatchTypes>):any => {
  dispatch({
    type: TOGGLE_MENU
  })
}

export const closeMenu = () => (dispatch: Dispatch<MenuDispatchTypes>):any => {
  dispatch({
    type: CLOSE_MENU,
    payload: false
  })
}
