import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FieldArray } from 'formik';
import { clone, is } from 'ramda';

// HOC
import withField from 'hoc/withField';

// Values
import initialValues from 'components/form/values/initialValues';

// Components
import BtnIcon from 'components/common/BtnIcon';
import Collapse from 'components/common/Collapse'
import FormField from  'components/form/FormField';

interface FieldProps {
  field: any,
  meta: any,
  helpers: any,
  clearValue:() => void
}

interface WidgetProps extends FieldProps {
  name: string,
  widget: any,
  placeholder: string | any,
  help: string | any,
}

const ListField:React.FC<WidgetProps> = ({
  field,
  meta,
  name,
  widget,
  help,
}) => {
  const {settings: {objectElements, singular, key}} = widget;
  const [open, setOpen] = useState([true]);

  const openTab = tabIndex => {
    const accordionTabs : boolean[] = [];
    for (let i=0; i<field.value.length; i++) {
      accordionTabs.push(i === tabIndex ? true : false);
    }
    setOpen(accordionTabs);
  };

  const closeAccordion = () => {
    const accordionTabs : boolean[] = [];
    for (let i=0; i<field.value.length; i++) {
      accordionTabs.push(false);
    }
    setOpen(accordionTabs);    
  };

  const openAccordion = () => {
    const accordionTabs : boolean[] = [];
    for (let i=0; i<field.value.length; i++) {
      accordionTabs.push(true);
    }
    setOpen(accordionTabs);    
  };

  return (
    <FieldArray name={name}>
      {({push, remove}) => (
        <React.Fragment>
          <div className={`list ${meta.touched && meta.error && 'is-invalid'}`}>
            {
              field.value.map((v, index) => (
                <div className="list--item" key={`${name}.${index}`}>
                  <div
                    className={`box box--shadow ${
                      meta.touched &&
                      is(Object, meta.touched[index]) &&
                      meta.error &&
                      is(Object, meta.error[index]) &&
                      'box--error'} `}
                  >
                    <div className="box__header">
                      <div
                        className={`box__title ${
                          meta.touched &&
                          is(Object, meta.touched[index]) &&
                          meta.error &&
                          is(Object, meta.error[index]) && 'box__title--error'}`}
                      >
                        <button
                          type="button"
                          className="button button--reset"
                          onClick={() => openTab(index)}
                        >
                          {`${singular}: ${field.value[index][key]}`}
                        </button>
                      </div>
                      <div className="box__actions">
                        <button
                          type="button"
                          className="button button--reset button--primary"
                          onClick={() => openTab(index)}
                          aria-label={`Editar ${singular.toLowerCase()}`}
                        >
                          <i className="material-icons">edit</i>
                        </button>
                        <button
                          type="button"
                          className="button button--reset button--danger"
                          onClick={() => remove(index)}
                          aria-label={`Remover ${singular.toLowerCase()}`}
                        >
                          <i className="material-icons">delete</i>
                        </button>
                      </div>
                    </div>
                    <Collapse isOpen={open[index]}>
                      <div className="box__body">
                        {
                          clone(objectElements).map(element => {
                            element.name = `${name}.${index}.${element.name}`;
                            return <FormField {...element} key={element.name} />;
                          })
                        }
                      </div>
                    </Collapse>
                  </div>
                </div>
              ))
            }
          </div>
          {
            meta.touched &&
            meta.error && 
            typeof meta.error === 'string' && (
              <div className="invalid-feedback">
                {meta.error}
              </div>
            )
          }
          { 
            help && (
              <small className="form-text text-muted">
                { help }
              </small>
            )
          }
          <div className="d-flex justify-content-center mt-4">
            <BtnIcon 
              size={50} 
              onClick={() => {
                push(objectElements.reduce(initialValues, {}));
                closeAccordion();
              }}
              aria-label={`Agregar ${singular.toLowerCase()}`}
            >
              <i className="material-icons">add</i>
            </BtnIcon>
          </div>
        </React.Fragment>
      )}
    </FieldArray>
  );
};

ListField.propTypes = {
  field: PropTypes.shape({
    name: PropTypes.string.isRequired,
    onBlur: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    value: PropTypes.any 
  }),
  meta: PropTypes.shape({
    error: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.object,
      PropTypes.array
    ]),
    touched: PropTypes.bool,
  }),
  helpers: PropTypes.shape({
    setValue: PropTypes.func.isRequired
  }),
  clearValue: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  widget: PropTypes.object.isRequired,
  help: PropTypes.string,
};

ListField.defaultProps = {
  help: null,
};

export default withField(ListField);
