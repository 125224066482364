import React from 'react';
import ReactHtmlParser from 'react-html-parser';
import PropTypes from 'prop-types';

// Components 
import UncontrolledPopover from 'components/common/UncontrolledPopover';

// Utils
import { cssSelector } from 'utils/string'

interface IProps {
  label: string,
  name: string,
  popover: string | any
}

const Label:React.FC<IProps> = ({ label, name, popover }) => {
  const id = cssSelector('icon' + name);

  return (
    <React.Fragment>
      <label htmlFor={name}>
        {`${label}:`}
      </label> 
      {
        popover && (
          <React.Fragment>
            <button
              type="button"
              className="button button--reset"
              id={id}
            >
              <i className="material-icons">
                info
              </i>
            </button>
            <UncontrolledPopover
              trigger="click hover"
              target={id}
            > 
              {ReactHtmlParser(popover)}
            </UncontrolledPopover> 
          </React.Fragment>
        )
      }
    </React.Fragment>
  )
}

Label.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  popover: PropTypes.string,
};

Label.defaultProps = {
  popover: null,
}

export default Label;