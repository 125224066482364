import React from 'react'
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

interface IProps {
  name: string,
  id: string,
  value: any,
  label: string,
  checked: boolean,
  disabled: boolean,
  className: string,
  onChange: () => void;
}
const Checkbox: React.FC<IProps> = props => {
  const {name, id, value, label, checked, disabled, className, onChange} = props
  const classes = classNames(
    'form-check-input',
    {'checked': checked},
    className,
  );

  return (
    <div className="form-check">
      <input
        type="checkbox"
        value={value}
        name={name}
        id={id}
        checked={checked}
        disabled={disabled}
        className={classes}
        onChange={onChange}
      />
      <label
        htmlFor={id}
        className="form-check-label"
      >
        {label}
      </label>
    </div>
  );
};

Checkbox.propTypes = {
  name: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool,
  ]).isRequired,
  label: PropTypes.string.isRequired,
  checked: PropTypes.bool.isRequired,
  disabled: PropTypes.bool.isRequired,
  className: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired
};

export default Checkbox;
