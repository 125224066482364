import React from 'react';
import PropTypes from 'prop-types';
import { isNil } from 'ramda';

// HOC
import withField from 'hoc/withField';

// Variant 
import Variant from  '../variants/Variant';

interface FieldProps {
  field: any,
  meta: any,
  helpers: any
}

interface WidgetProps extends FieldProps {
  name: string,
  multiple: [] | any,
  widget: any,
  options: [] | any,
  help: string | any,
}

const Checkbox:React.FC<WidgetProps> = ({
  field,
  meta,
  helpers,
  name,
  multiple,
  widget,
  options,
  help,
}) => {  
  const { settings: { variant } } = widget;

  const handleChange = id => {
    if (!isNil(multiple)) {
      const previousValues = field.value;
      const nextValues = previousValues.includes(id)
        ? previousValues.filter(idValue => idValue !== id)
        : previousValues.concat(id);
      helpers.setValue(nextValues, true);
    } else {
      helpers.setValue( id, true);
    }
  };

  return (
    <React.Fragment>
      <div className="checkbox-group" role="group" aria-labelledby={name}>
        {options.map((optn, i) => (
          <Variant
            key={`${name}-${i}`}
            type={variant}
            name={name}
            id={`${name}-${i}`}
            label={optn.label}
            value={optn.value}
            checked={field.value.includes(optn.value)}
            onChange={() => handleChange(optn.value)}
            disabled={!isNil(optn.disabled) ? optn.disabled : false}
            className={meta.touched && meta.error ? 'is-invalid' : ''}
          />
        ))}
      </div>
      {meta.touched && meta.error && (
        <div className="invalid-feedback d-block">
          {meta.error}
        </div>
      )}
      {help && (
        <small className="form-text text-muted">
          {help}
        </small>
      )}
    </React.Fragment>
  )
};

Checkbox.propTypes = {
  field: PropTypes.shape({
    name: PropTypes.string.isRequired,
    onBlur: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    value: PropTypes.any 
  }),
  meta: PropTypes.shape({
    error: PropTypes.string,
    touched: PropTypes.bool,
  }),
  helpers: PropTypes.shape({
    setValue: PropTypes.func.isRequired
  }),
  name: PropTypes.string.isRequired,
  multiple: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.oneOf([null])
  ]),
  widget: PropTypes.object.isRequired,
  options: PropTypes.array.isRequired,
  help: PropTypes.string,
};

Checkbox.defaultProps = {
  help: null,
};

export default withField(Checkbox);