import React, { useEffect, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useField, useFormikContext } from 'formik';
import classNames from 'classnames/bind';
import { isNil } from 'ramda';

// Utils
import { byPath } from 'utils/object';
import { logicalArrayEvaluator } from 'utils/array';

// Validations
import { test } from 'components/form/validation/test';

// Values
import initialValues from 'components/form/values/initialValues';

// Components
import Label from 'components/form/elements/Label';
import { formatValue } from 'utils/sharedFuncs';

const withField = (Field: React.ElementType<any>): React.FC => {
  interface NewFieldProps {
    name: string;
    type: string;
    multiple: [] | any;
    states: {
      visible: boolean;
    };
    widget: {
      type: string;
      settings?: any;
    };
    label: string | any;
    popover: string | any;
    references: [] | any;
    className: string | any;
  }

  const NewField: React.FC<NewFieldProps> = ({
    name,
    type,
    multiple,
    states,
    widget,
    label,
    popover,
    references,
    className,
    ...props
  }) => {
    const { values, setFieldValue } = useFormikContext();
    const { visible } = states;
    const showField = isNil(references)
      ? true
      : logicalArrayEvaluator(references, conditionalField);
    const { settings } = widget;
    const { fieldFormatting = null } = settings || {};

    function conditionalField(reference) {
      const basePath = name.includes('.')
        ? name.split(/\.(?=[^.]+$)/)[0]
        : null; // Path to field (dot notation)
      const refPath = basePath
        ? basePath + '.' + reference.name
        : reference.name; // Path to current + reference path
      const field = byPath(values, refPath); // Find reference field by created path
      return test(reference.type, field, reference.params);
    }

    const clearValue = useCallback(() => {
      let val;
      if (type === 'object') {
        const {
          settings: { objectElements },
        } = widget;
        val = objectElements.reduce(initialValues, {});
      } else {
        val = '';
      }
      setFieldValue(name, multiple ? [val] : val);
    }, []);

    const [field, meta, helpers] = useField(name);

    const fieldProps = useMemo(
      () => ({
        field,
        meta,
        helpers,
        clearValue,
      }),
      [field, meta, helpers, clearValue]
    );

    /**
     * Value formatting effect
     * from settings: { fieldFormatting }
     */
    useEffect(() => {
      if (type === 'string' && !isNil(fieldFormatting)) {
        const formattedValue = formatValue(fieldFormatting, field.value);
        setFieldValue(name, formattedValue);
      }
    }, [field.value]);

    useEffect(() => {
      if (!showField) clearValue();
    }, [showField]);

    return (
      <React.Fragment>
        {visible && showField ? (
          <div className={classNames('form-group', className)}>
            {label ? (
              <Label name={name} label={label} popover={popover} />
            ) : null}
            <Field
              name={name}
              multiple={multiple}
              widget={widget}
              {...props}
              {...fieldProps}
            />
          </div>
        ) : null}
      </React.Fragment>
    );
  };

  NewField.propTypes = {
    name: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    multiple: PropTypes.oneOfType([PropTypes.array, PropTypes.oneOf([null])]),
    states: PropTypes.shape({
      visible: PropTypes.bool.isRequired,
    }).isRequired,
    widget: PropTypes.shape({
      type: PropTypes.string.isRequired,
      settings: PropTypes.shape({
        fieldFormatting: PropTypes.string,
        objectElements: PropTypes.array,
      }),
    }).isRequired,
    label: PropTypes.string,
    popover: PropTypes.string,
    references: PropTypes.array,
    className: PropTypes.string,
  };

  NewField.defaultProps = {
    widget: {
      type: '',
      settings: {},
    },
    label: null,
    popover: null,
    references: null,
    className: null,
  };

  return NewField;
};

withField.propTypes = {
  Field: PropTypes.elementType.isRequired,
};

export default withField;
