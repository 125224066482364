import React, { useEffect } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { isEmpty } from 'ramda';

// Store
import { rootStore } from 'store/store';

// Actions
import { getSection, getSectionsById } from 'store/actions/sectionsActions';

// Components
import Spinner from 'components/common/Spinner';
import Layout from './layouts/Layout';

const Stepper: React.FC = () => {
  const dispatch = useDispatch();
  const { logo } = useSelector(
    ({
      initial: {
        init: {
          org: { logo },
        },
      },
    }: rootStore) => ({ logo }),
    shallowEqual
  );
  const { currentSectionId } = useSelector(
    ({ sections: { currentSectionId } }: rootStore) => ({ currentSectionId }),
    shallowEqual
  );
  const { flowSection } = useSelector(
    ({ sections: { flowSection } }: rootStore) => ({ flowSection }),
    shallowEqual
  );
  const { loadingSection } = useSelector(
    ({ sections: { loadingSection } }: rootStore) => ({ loadingSection }),
    shallowEqual
  );

  useEffect(() => {
    if (!isEmpty(currentSectionId)) {
      dispatch(getSection(currentSectionId));
      dispatch(getSectionsById(currentSectionId));
    }
  }, [dispatch, currentSectionId]);

  return (
    <React.Fragment>
      {loadingSection ? (
        <div className='absolute-center'>
          <div className='d-flex flex-column align-items-center'>
            <img
              src={logo}
              alt='Pefai - logo'
              style={{ width: 140, marginBottom: 5 }}
            />
            <Spinner
              loader='ThreeDots'
              position='inline'
              width='40'
              height='40'
            />
          </div>
        </div>
      ) : (
        !isEmpty(flowSection) && <Layout />
      )}
    </React.Fragment>
  );
};

export default Stepper;
