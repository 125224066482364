import { ThunkAction } from 'redux-thunk';
import { Dispatch } from 'redux';

import { RootState, rootStore } from '../store';
import {
  STEPS_LOADING,
  STEPS_SUCCESS,
  STEPS_SUCCESS_BY_ID,
  STEPS_FAIL,
  STEP_LOADING,
  STEP_SUCCESS,
  STEP_FAIL,
  STEP_NUMBER,
  CURRENT_STEP_ID,
  CURRENT_STEP_NUMBER,
  REDIRECT,
  NAV_DIRECTION,
  SECTION_RESET,
  RESET_CURRENT_STEP_ID,
} from '../actionTypes/ActionsTypes';

import {
  find,
  propEq,
  useWith,
  identity,
  pathOr,
  equals,
  length,
  propOr,
  compose
} from 'ramda';

import { fetchSteps, fetchStep, postGoTo, postGoBack } from '../../api/dataFetching';

import { StepperDispatchTypes } from '../../models/index';

import { goToPrevSection, getSections } from './sectionsActions';

import { getVal } from 'utils/sharedFuncs';

export const getSteps = () => async (dispatch: Dispatch<StepperDispatchTypes>,
  getState: () => RootState): Promise<any> => {

  const {
    initial: { init: { flow } },
    initial: { init: { org } },
    sections: { currentSectionId }
  } = getState();

  try {
    dispatch({
      type: STEPS_LOADING,
    });

    const res = await fetchSteps(currentSectionId);

    dispatch({
      type: STEPS_SUCCESS,
      payload: res.data
    })
    dispatch(getCurrentStepsId());
    dispatch(getStepNumber());


  } catch (error) {
    dispatch({
      type: STEPS_FAIL,
      payload: error,
    });

    if (error.status === 401) {
      window.location = `${window.location.origin}/${org.name}/${flow.matching}`
    }

  }
};

export const getStepsById = (id: string) => (dispatch: Dispatch<StepperDispatchTypes>, getState: () =>
  RootState): any => {
  const {
    stepper: { flowSteps }
  } = getState();
  const steps = pathOr({}, ['steps'], flowSteps);
  const findById = useWith(find, [propEq('id'), identity]);
  const result = findById(id, steps);
  dispatch({
    type: STEPS_SUCCESS_BY_ID,
    payload: result
  });
};

export const getStep = ((stepId: string): ThunkAction<void, rootStore, null, StepperDispatchTypes> => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch({
        type: STEP_LOADING,
      });
      const res = await fetchStep(stepId);

      dispatch({
        type: STEP_SUCCESS,
        payload: res.data
      })

    } catch (error) {
      dispatch({
        type: STEP_FAIL,
        payload: error,
      });
    }
  }
});

export const getCurrentStepsId = () => (dispatch: Dispatch<StepperDispatchTypes>, getState: () =>
  RootState): any => {
  const {
    stepper: { flowSteps, currentStepId, currentStepNumber, navDirection }
  } = getState();

  const steps = pathOr([], ['steps'], flowSteps);
  const allStepNumbers = steps.map(ele => ele.stepNumber);
  const resultMin = Math.min(...allStepNumbers);
  const resultMax = Math.max(...allStepNumbers);
  const maxNumberStep = equals(currentStepNumber, resultMax);

  // Lookup into steps (flowSteps) for "INCOMPLETED" steps (not implemented yet)
  const predicateIncomplete = ele => equals(ele.status, 'INCOMPLETED');
  const idToSendIncompleteStep = find(predicateIncomplete, steps);

  // Lookup into steps (flowSteps) for "COMPLETE" and valid steps (not implemented yet)
  const predicateCompleteSteps = ele => ele.status === 'COMPLETED' && ele.valid === true;
  const completeSteps = steps.every(predicateCompleteSteps);

  // Lookup into steps (flowSteps) for ---next step--- from current
  const resultToCompleteNext = getVal(allStepNumbers, currentStepNumber);
  const predicateNextCompleteStep = ele => equals(ele.stepNumber, resultToCompleteNext);
  const idToSendNextCompleteStep = find(predicateNextCompleteStep, steps);

  // Lookup into steps (flowSteps) for "ACTIVE" steps
  const predicateActiveStep = ele => equals(ele.status, 'ACTIVE');
  const idToSendActiveStep = find(predicateActiveStep, steps);

  // Lookup into steps (flowSteps) for --first-- element in the array
  const predicateByMinNumber = ele => equals(ele.stepNumber, resultMin);
  const idToSendMinNumber = find(predicateByMinNumber, steps);

  // Lookup into steps (flowSteps) for --last-- element in the array
  const predicateByMaxNumber = ele => equals(ele.stepNumber, resultMax);
  const idToSendMaxNumber = find(predicateByMaxNumber, steps);

  // Holds value of lookup results
  let nextTargetStep;
  // Holds value of flow's steps directions  (backwards, forward)
  let updatednavDirection = "";

  // Given any flow that "starts" or "resumes", 
  // Its currentStepId will always be ""
  // And its idToSendActiveStep will always point to the latest step (if starting Section 1 : Step 1)
  // - Ex: If a flow starts then Section 1 : Step 1 is Active, 
  //       if a refresh or flow resume will focus on that Section and Step
  if (currentStepId === '' && idToSendActiveStep) {
    // Lookup for Active step
    nextTargetStep = navDirection === "forward" ? idToSendMinNumber : idToSendActiveStep;
  }
  // Given the last step in a section...
  else if (maxNumberStep) {
    // This dispatech action calls for a Section update which as part of the flow
    // will trigger this function after that, as part of this we hook into the direction of the flow
    // to determine if the flow is moving forward or backwards.
    updatednavDirection = "forward";
    dispatch(getSections());
  }
  else if (navDirection === "forward") {
    // If the flow is moving forward, then we need the --first-- step of the next section
    nextTargetStep = idToSendMinNumber;
  }
  else if (navDirection === "backwards") {
    // If the flow is moving forward, then we need the --last-- step of the next section
    nextTargetStep = idToSendMaxNumber;
  }
  else if (idToSendNextCompleteStep) {
    // Lookup for --next-- step in a set of steps
    nextTargetStep = idToSendNextCompleteStep;
  }

  // Dispatch : flow direction (backwards, forward) for min/max step focus
  // Note: if no asignation it will set the variable's default value of ""
  dispatch({
    type: NAV_DIRECTION,
    payload: updatednavDirection
  });

  // Dispatch: Update step id value (ex: "id: pefaiflowstep-#")
  dispatch({
    type: CURRENT_STEP_ID,
    payload: nextTargetStep.id
  });

  // Dispatch: Update step number value (ex: "stepNumber: #")
  dispatch({
    type: CURRENT_STEP_NUMBER,
    payload: nextTargetStep.stepNumber
  });
}

export const nextIsClicked = () => (dispatch: Dispatch<StepperDispatchTypes>): any => {
  dispatch({
    type: NEXT_CLICKED,
    payload: false
  })
}

export const nextStep = () => (dispatch: Dispatch<StepperDispatchTypes>, getState: () =>
  RootState): any => {

  const {
    stepper: { currentStepNumber, flowSteps },
    sections: { currentSectionId }
  } = getState();

  const steps = pathOr({}, ['steps'], flowSteps);

  const allStepNumbers = steps.map(ele => ele.stepNumber);

  const resultToCompleteNext = getVal(allStepNumbers, currentStepNumber);
  const predicateNextCompleteStep = ele => equals(ele.stepNumber, resultToCompleteNext);
  const idToSendNextCompleteStep = find(predicateNextCompleteStep, steps);

  dispatch(getSteps(currentSectionId));
}

export const backStep = () => (dispatch: Dispatch<any>, getState: () =>
  RootState): any => {
  const {
    stepper: { currentStepNumber, flowSteps }
  } = getState();

  const steps = pathOr({}, ['steps'], flowSteps);

  const mapedStepsNumbers = steps.map(ele => ele.stepNumber);
  const minSteps = Math.min(...mapedStepsNumbers);

  const predicateFirstStep = equals(currentStepNumber, minSteps);

  const result = getVal(mapedStepsNumbers, currentStepNumber, true);
  const predicateBackStep = ele => equals(ele.stepNumber, result);
  const idToSendBackStep = find(predicateBackStep, steps);

  if (predicateFirstStep) {
    dispatch({
      type: NAV_DIRECTION,
      payload: "backwards"
    })

    dispatch(goToPrevSection())
  } else {
    const { id, stepNumber } = idToSendBackStep || {};

    dispatch({
      type: CURRENT_STEP_ID,
      payload: id
    })

    dispatch({
      type: CURRENT_STEP_NUMBER,
      payload: stepNumber
    })
  }
}

export const redirect = (link: string): any => {
  return {
    type: REDIRECT,
    payload: link
  };
}

export const getStepNumber = () => (dispatch: Dispatch<StepperDispatchTypes>, getState: () =>
  RootState): any => {
  const {
    stepper: { flowSteps }
  } = getState();

  const stepsLength = compose(
    length,
    propOr('', 'steps'),
  )(flowSteps);

  dispatch({
    type: STEP_NUMBER,
    payload: stepsLength
  })
}

export const navigateTo = (stepId: string) => async (dispatch: Dispatch<any>): Promise<any> => {
  await postGoTo(stepId);
  dispatch({
    type: SECTION_RESET
  });
  dispatch(getSections());
}

export const navigateBack = () => async (dispatch: Dispatch<any>): Promise<any> => {
  await postGoBack();
  dispatch({
    type: SECTION_RESET
  });
  dispatch(getSections());

}
