import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { init } from 'onfido-sdk-ui';

// HOC
import withField from 'hoc/withField';

interface FieldProps {
  meta: any,
  helpers: any
}

interface WidgetProps extends FieldProps {
  widget: any,
  help: string | any,
  className: string | any
}

const Onfido:React.FC<WidgetProps> = ({
  meta,
  helpers,
  widget,
  help,
}) => {
  
  const { settings: {
    token,
    smsNumberCountryCode,
    userDetails,
    steps
  }} = widget;

  let onfido = {};

  const triggerOnfido = () => {
    onfido = init({
      token: token,
      containerId: 'onfido',
      language: 'es',
      smsNumberCountryCode: smsNumberCountryCode,
      ...(userDetails && {userDetails: userDetails}),
      useMemoryHistory: true,
      steps: steps,
      customUI: {
        "fontFamilyTitle": "Poppins, Impact, fantasy",
        "fontFamilySubtitle": "Poppins, Impact, fantasy",
        "fontFamilyBody": "Poppins, Impact, fantasy",
      },
      onComplete: function(data) {
        console.log('Everything is complete');
        helpers.setValue(data, true);
      },
      onError: function(error) {
        console.log(error);
      },
    });
  }

  useEffect(() => {
    triggerOnfido();
    return () => onfido.tearDown();
  }, []);

  return (
    <React.Fragment>
      <div id="onfido"></div>
      {meta.touched && meta.error && (
        <div className="invalid-feedback d-block">
          {'Documento requerido.'}
        </div>
      )}
      {help && (
        <small className="form-text text-muted">
          {help}
        </small> 
      )}
    </React.Fragment>        
  )
};

Onfido.propTypes = {
  meta: PropTypes.shape({
    error: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.object
    ]),
    touched: PropTypes.bool,
  }),
  helpers: PropTypes.shape({
    setValue: PropTypes.func.isRequired
  }),
  widget: PropTypes.object.isRequired,
  help: PropTypes.string,
  className: PropTypes.string,
};

Onfido.defaultProps = {
  help: null,
  className: null
};

export default withField(Onfido);