import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { AppInsightsContext } from "@microsoft/applicationinsights-react-js";
import { reactPlugin } from "./analytics/AppInsights";

// Store
import store from 'store/store';

// Components
import App from './App';

ReactDOM.render(
  <AppInsightsContext.Provider value={reactPlugin} >
    <Provider store={ store }>
      <App />
    </Provider>
  </AppInsightsContext.Provider>,
  document.getElementById('root')
);