import React, { FC, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import SignatureCanvas from 'react-signature-canvas';

// Utils
import { chkEmpty } from 'utils/object'
import { postFile } from 'api/dataFetching';
import { createBlobFromBase } from 'utils/object';

// HOC
import withField from 'hoc/withField';

interface FieldProps {
  field: any;
  meta: any;
  helpers: any;
  clearValue:() => void;
}

interface WidgetProps extends FieldProps {
  name: string;
  widget: any;
  label?: string;
  placeholder: string | any;
  disabled: boolean | any;
  help: string | any;
  popover: string | any;
  options: [] | any;
  references: [] | any;
}

const SignatureArea: FC<WidgetProps> = (props) => {
  const { widget, help, meta, field, helpers, clearValue } = props;
  const {
    settings: { personName },
  } = widget;
  const [hasSignature, setHasSignature] = useState<boolean>(false);
  const [message, setMessage] = useState({
    status: 'success',
    content: '',
  });
  const signatureArea = useRef({});

  function clearSignatureArea(e) {
    e.preventDefault();
    signatureArea.current.clear();
    setHasSignature(false); 
    clearValue();
  }

  function onEndHandler() {
    setHasSignature(true);
  }

  async function onSaveSignatureHandler() {
    const signatureData = signatureArea.current
      .getTrimmedCanvas()
      .toDataURL('image/png');
    const encodedData = createBlobFromBase(signatureData);
    const formData = new FormData();
    formData.append('file', encodedData);
    try {
      const res = await postFile(formData);
      const { data } = res;
      const file = {
        assetId: data,
      };
      helpers.setValue(file, true);
      setMessage({
        status: 'success',
        content: 'Tu firma se guardo correctamente.',
      });
    } catch (error) {
      setMessage({ status: 'danger', content: 'Error en firma.' });
    }
  }

  return (
    <React.Fragment>
      <div className='row no-gutters'>
        <div className='col-lg-9 col-xl-6 signature-area'>
          <div
            className={`signature-area__box ${
              meta.touched && meta.error && 'is-invalid'
            }`}
          >
            <div className='signature-area__pad'>
              <SignatureCanvas
                ref={signatureArea}
                clearOnResize={true}
                onEnd={onEndHandler}
              />
            </div>
            <div className='signature-area__body'>{personName}</div>
          </div>
          {meta.touched && meta.error && (
            <div className='invalid-feedback'>Confirmar firma.</div>
          )}
          {help && <small className='form-text text-muted'>{help}</small>}
          <div className='text-center mt-2'>
            {hasSignature && (
              <button
                type='button'
                className='btn btn-link'
                onClick={clearSignatureArea}
              >
                Borrar
              </button>
            )}

            {hasSignature && chkEmpty(field.value) && (
              <button
                type='button'
                className='btn btn-outline-primary btn-sm-block'
                onClick={() => onSaveSignatureHandler()}
              >
                Guardar firma
              </button>
            )}
          </div>
          {!chkEmpty(field.value) && (
            <p className={`text-${message.status}`}>{message.content}</p>
          )}
        </div>
      </div>
    </React.Fragment>
  );
};

SignatureArea.propTypes = {
  field: PropTypes.shape({
    name: PropTypes.string.isRequired,
    onBlur: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    value: PropTypes.any,
  }),
  meta: PropTypes.shape({
    error: PropTypes.string,
    touched: PropTypes.bool,
  }),
  helpers: PropTypes.shape({
    setValue: PropTypes.func.isRequired,
  }),
  clearValue: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  widget: PropTypes.object.isRequired,
  help: PropTypes.string,
};

export default withField(SignatureArea);

// Todo: url no worky.. :..(
// ? Did I miss 'name'??
