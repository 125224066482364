import React from 'react';
import PropTypes from 'prop-types';

// HoC
import withField from 'hoc/withField';

interface FieldProps {
  field: any;
  meta: any; 
}

interface WidgetProps extends FieldProps {
  name: string;
  widget: any;
  help: string | any;
  popover: string | any;
  placeholder: string | any;
  disabled: boolean | any;
  references: any;
}

const TextareaField: React.FC<WidgetProps> = ({
  widget,
  name,
  meta,
  field,
  placeholder,
  disabled,
  help,
}) => {
  const {
    settings: { rows },
  } = widget;

  return (
    <React.Fragment>
      <textarea
        {...field}
        rows={rows}
        id={name}
        placeholder={placeholder}
        disabled={disabled}
        className={`form-control ${meta.touched && meta.error && 'is-invalid'}`}
      />
      {meta.touched && meta.error && (
        <div className='invalid-feedback'>{meta.error}</div>
      )}
      {help && <small className='form-text text-muted'>{help}</small>}
    </React.Fragment>
  );
};

TextareaField.propTypes = {
  field: PropTypes.shape({
    name: PropTypes.string.isRequired,
    onBlur: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    value: PropTypes.any,
  }),
  meta: PropTypes.shape({
    error: PropTypes.string,
    touched: PropTypes.bool,
  }),
  name: PropTypes.string.isRequired,
  widget: PropTypes.object.isRequired,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  help: PropTypes.string,
  popover: PropTypes.string,
  references: PropTypes.array,
};

TextareaField.defaultProps = {
  placeholder: null,
  disabled: null,
  help: null, 
};

export default withField(TextareaField);
