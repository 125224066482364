import { Dispatch } from 'redux';
import {
  INIT_FAIL,
  INIT_SUCCESS,
  INIT_LOADING,
  INIT_ERROR,
  INIT_STATUS,
  SUCCESS_STATUS
} from '../actionTypes/ActionsTypes';
import { InitDispatchTypes } from '../../models/index';
import { fetchInit, getCredentials } from '../../api/dataFetching';
import { credentialsStore } from 'api/localStorage';

export const getInit = (flowName: string, orgName: string, queryParams: string) => async (
  dispatch: Dispatch<InitDispatchTypes>): Promise<any> => {
  try {
    dispatch({
      type: INIT_LOADING
    });

    const res = await fetchInit(flowName, orgName, queryParams);

    // Get session id from headers
    const initSessionId = res.headers["x-session-id"];
    const uniqueId = `_xs:${orgName}-${flowName}`;

    // If no credentials are stored in local storage call /credentials for JWT and store
    if (!credentialsStore.attemptSessionRestore(`${uniqueId}`)) {
      await getCredentials(initSessionId, uniqueId);
    } else {
      // ToDo: Review is this still a valid case
      // If partial credentials (session id) are stored but no JWT, call /credentials to update with JWT
      if (!credentialsStore.hasProperty('xuid')) {
        await getCredentials(initSessionId, uniqueId);
      }
    }

    dispatch({
      type: INIT_SUCCESS,
      payload: res.data
    });

    dispatch({
      type: INIT_STATUS,
      payload: res.status
    });
  } catch (error) {
    dispatch({
      type: INIT_STATUS,
      payload: error.status
    });

    dispatch({
      type: INIT_FAIL,
      payload: error
    });
  }
}

export const getError = (err: string) => (dispatch: Dispatch<InitDispatchTypes>): any => {
  dispatch({
    type: INIT_ERROR,
    payload: err
  })
}

export const setSuccessStatus = () => (dispatch: Dispatch<InitDispatchTypes>): any => {
  dispatch({
    type: SUCCESS_STATUS,
    payload: false
  })
}
