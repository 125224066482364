import React from 'react';
import AppRouter from 'routers/AppRouter';
import TagManager from 'react-gtm-module';

// Styles
import 'styles/App.scss';

const tagManagerArgs = {
  gtmId: process.env.REACT_APP_GTM,
};

TagManager.initialize(tagManagerArgs);

const App: React.FC = () => {
  return <AppRouter />;
};

export default App;
