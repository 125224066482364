import React from 'react';
import PropTypes from 'prop-types';
import NumberFormat from 'react-number-format';

// HoC
import withField from 'hoc/withField';

interface FieldProps {
  field: any;
  meta: any;
  helpers: any;
}

interface WidgetProps extends FieldProps {
  disabled: boolean | any;
  format: string | any;
  help: string | any;
  label: string;
  name: string;
  placeholder: string | any;
  popover: string | any;
  references: any;
  widget: any;
}

const TelField: React.FC<WidgetProps> = ({
  disabled,
  field,
  help,
  helpers,
  meta,
  name,
  placeholder,
  widget,
}) => {
  const {
    settings: { format },
  } = widget;

  return (
    <React.Fragment>
      <NumberFormat
        type='tel'
        name={name}
        id={name}
        value={field.value}
        format={format}
        isNumericString={false}
        placeholder={placeholder}
        autoComplete='off'
        disabled={disabled}
        onBlur={field.onBlur}
        onValueChange={(val) => helpers.setValue(val.value, true)}
        className={`form-control ${meta.touched && meta.error && 'is-invalid'}`}
      />
      {meta.touched && meta.error && (
        <div className='invalid-feedback'>{meta.error}</div>
      )}
      {help && <small className='form-text text-muted'>{help}</small>}
    </React.Fragment>
  );
};

TelField.propTypes = {
  field: PropTypes.shape({
    name: PropTypes.string.isRequired,
    onBlur: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    value: PropTypes.any,
  }),
  meta: PropTypes.shape({
    error: PropTypes.string,
    touched: PropTypes.bool,
  }),
  helpers: PropTypes.shape({
    setValue: PropTypes.func.isRequired,
  }),
  name: PropTypes.string.isRequired,
  widget: PropTypes.object.isRequired,
  placeholder: PropTypes.string,
  format: PropTypes.string,
  help: PropTypes.string,
  disabled: PropTypes.bool,
};

TelField.defaultProps = {
  placeholder: null,
  format: null,
  help: null,
  disabled: false,
};

export default withField(TelField);