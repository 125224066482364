import React from 'react';
import PropTypes from 'prop-types';

const Video: React.FC = ({ src, loop, controls, autoplay, ...rest }) => {
  return (
    <div 
      className="embed-responsive embed-responsive-16by9 video video--wrapper"
    >
      <video controls={controls} loop={loop} autoPlay={autoplay} {...rest}> 
        <source src={src} type="video/mp4" />
      </video>
    </div>
    
  )
};

Video.propTypes = {
  src: PropTypes.string.isRequired,
  loop: PropTypes.bool,
  controls: PropTypes.bool,
  autoplay: PropTypes.bool
}

Video.defaultProps = {
  loop: false,
  controls: false,
  autoplay: false
};

export default Video;
