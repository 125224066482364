/** @jsxRuntime classic */
/** @jsx jsx */
import React, { useRef } from 'react';
import PropTypes from 'prop-types'; 
import { jsx, css } from '@emotion/react';

// HoC
import withField from 'hoc/withField';

interface FieldProps {
  field: any;
  meta: any;
  helpers: any;
}

interface WidgetProps extends FieldProps {
  name: string;
  type: string;
  widget: any;
  label: string;
  placeholder: string | any;
  popover: string | any;
  help: string | any;
  disabled: boolean | any;
  references: [] | any;
}

const StepperField: React.FC<WidgetProps> = ({
  name,
  widget,
  placeholder,
  help,
  disabled,
  helpers,
  field,
  meta,
}) => {
  const {settings: {
    min,
    max,
    step,
    header,
    headerIcon,
    preffix,
    suffix
  }} = widget;
  const inputNumber = useRef(null);

  const operation = operator => {
    try {
      switch (operator) {
        case 'up':
          inputNumber.current.stepUp();
          break;        
        case 'down':
          inputNumber.current.stepDown();
          break;
      }
      helpers.setValue(inputNumber.current.value, true)
    } catch (e) {
      stepFallback(operator);
    }
  };

  function stepFallback(operator) {
    const directionModifier = operator === 'down' ? -1 : 1;
    const stepper = step ?? 1;
    const value = (field.value || 0) + (stepper * directionModifier);
    helpers.setValue(value, true)
  }

 

  return (
    <React.Fragment>
      <div
        css={css`
          @media (min-width: 700px) {
            width: 30%;
            margin: 15px 0;
          }
          width: 50%;
          border-radius: 10px;
          display: flex;
          flex-flow: column nowrap;
        `}
        className={` shadow ${meta.touched && meta.error && 'is-invalid'}`}
      >
        <div
          css={css`
            border-radius: 10px 10px 0 0;
            color: #fefefe;
            background-color: #0279fe;
            padding: 15px 10px;
            display: flex;
            justify-content: center;
          `}
        >
          {headerIcon && (
            <span
              className='material-icons'
              css={css`
                margin-right: 5%;
              `}
            >
              {headerIcon}
            </span>
          )}
          <span>{header}</span>
        </div>
        <div
          css={css`
            width: 100%;
            padding: 15px 10px;
            display: flex;
            justify-content: space-evenly;
            align-items: center;
          `}
        >
          <button
            css={css`
              background-color: #f6f9fe;
              border-color: transparent;
              :disabled {
                span {
                  opacity: 0.5;
                }
              }
            `}
            onClick={() => operation('down')}
            type='button'
            disabled={(min && !(field.value > min)) || disabled}
          >
            <span
              css={css`
                color: #0279fe;
              `}
              className='material-icons'
            >
              remove_circle
            </span>
          </button>
          {preffix && (
            <span
              css={css`
                font-weight: bold;
                color: #042c5c;
              `}
            >
              {preffix}
            </span>
          )}
          <input
            css={css`
              width: 80%;
              text-align: center;
              border-color: transparent;
              font-weight: bold;
              color: #042c5c;
              background-color: #f6f9fe;
              -moz-appearance: textfield;
              ::-webkit-outer-spin-button,
              ::-webkit-inner-spin-button {
                -webkit-appearance: none;
                margin: 0;
              }
              :disabled {
                opacity: 0.5;
              }
            `}
            {...field}
            value={field.value !== '' ? field.value : 0}
            type='number'
            min={min}
            max={max}
            step={step}
            placeholder={placeholder}
            disabled={disabled}
            autoComplete='off'
            id={name}
            ref={inputNumber}
          />
          {suffix && (
            <span
              css={css`
                font-weight: bold;
                color: #042c5c;
              `}
            >
              {suffix}
            </span>
          )}
          <button
            css={css`
              background-color: #f6f9fe;
              border-color: transparent;
              :disabled {
                span {
                  opacity: 0.5;
                }
              }
            `}
            onClick={() => operation('up')}
            type='button'
            disabled={(max && !(field.value < max)) || disabled}
          >
            <span
              css={css`
                color: #0279fe;
              `}
              className='material-icons'
            >
              add_circle
            </span>
          </button>
        </div>
      </div>
      {meta.touched && meta.error && (
        <div className='invalid-feedback'>{meta.error}</div>
      )}
      {help && <small className='form-text text-muted'>{help}</small>}
    </React.Fragment>
  );
};

StepperField.propTypes = {
  field: PropTypes.shape({
    name: PropTypes.string.isRequired,
    onBlur: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    value: PropTypes.any,
  }),
  helpers: PropTypes.shape({
    setValue: PropTypes.func.isRequired,
  }),
  meta: PropTypes.shape({
    error: PropTypes.string,
    touched: PropTypes.bool,
  }),
  name: PropTypes.string.isRequired,
  widget: PropTypes.object.isRequired,
  placeholder: PropTypes.string,
  help: PropTypes.string,
  disabled: PropTypes.bool
};

StepperField.defaultProps = {
  placeholder: null,
  help: null,
  disabled: false
};

export default withField(StepperField);
