import { enterAPIManagerInstance } from 'api';
import { stringifyUrl } from 'query-string';
import { isNil, isEmpty } from 'ramda';

// API
import { API_CATALOGS } from 'api/constants';

export const cleanObject = (object: any): any => {
  Object
    .entries(object)
    .forEach(([k, v]) => {
      if (v && typeof v === 'object')
        cleanObject(v);
      if (v &&
        typeof v === 'object' &&
        !Object.keys(v).length ||
        v.length === 0
      ) {
        if (Array.isArray(object))
          object.splice(k, 1);
        else if (!(v instanceof Date))
          delete object[k];
      }
    });
  return object;
}

/* 
  Function name: replaceNull
  Description: Replaces all the null values (nested) inside an object
  by converting object into string (JSON.stringify) and passing a replacer
  function.
  Params: (object, replaceValue)
  - object: Object that we want iterate
  - replaceValue: String that will replace null values
*/
export const replaceNull = (object: any, replaceValue = ''): any => {
  const replacer = (key, value) =>
    String(value) === "null" || String(value) === "undefined" ? replaceValue : value;
  return JSON.parse(JSON.stringify(object, replacer));
}

/* 
  Function name: chkNil
  Description: Iterates all keys of the object and returns true
  if the all the keys are null, or undefined.
  Params: (object: any)
  - object: Object that we want to iterate
*/
export function chkNil(object: any): boolean {
  return Object.values(object).every(v => v && typeof v === 'object'
    ? chkNil(v)
    : isNil(v)
  );
}

/* 
  Function name: chkEmpty
  Description: Iterates all keys of the object and returns true
  if the all the keys are empty
  Params: (object: any)
  - object: Object that we want to iterate
*/
export function chkEmpty(object: any): boolean {
  return Object.values(object).every(v => v && typeof v === 'object'
    ? chkEmpty(v)
    : isEmpty(v)
  );
}

/* 
  Function name: byPath
  Description: Access nested keys by a dot notation string path
  and retrieves value.
  - 'reference[0].contact_info.phone'
  - 'person.name'
  - 'person.skills[1].type
  Params: (object: any, path: string)
  - object: Object that we want to iterate
  - path: Dot notation string path
  Alternatives: Node package object-resolve-path
*/
export function byPath(object: any, path: string) {
  path = path.replace(/\[(\w+)\]/g, '.$1'); // Convert indexes to properties
  path = path.replace(/^\./, ''); // Strip a leading dot
  const a = path.split('.');
  for (let i = 0, n = a.length; i < n; ++i) {
    const k = a[i];
    if (typeof object === 'object' && k in object) {
      object = object[k];
    } else {
      return;
    }
  }
  return object;
}

export function find(obj, item) {
  for (const key in obj) {
    if (obj[key] && typeof obj[key] === "object") {
      const result = find(obj[key], item);
      if (result) {
        result.unshift(key);
        return result;
      }
    } else if (obj[key] === item) {
      return [key];
    }
  }
}

export function getParentKeys(obj, key) {
  if (!obj || typeof obj !== 'object') return;
  for (const [k, v] of Object.entries(obj)) {
    if (k === key) return [k];
    const path = getParentKeys(v, key);
    if (path) return [k, ...path];
  }
}

export const createBlobFromBase = (baseData: string) => {
  const data = baseData.split(',')
  const encodignConfig = data[0].split(':')[1].split(';')[0]
  const byteData = data[0].indexOf('base64') >= 0 ? atob(data[1]) : decodeURI(data[1])
  const byteDataLength = byteData.length;

  const byteArray = new Uint8Array(byteDataLength)
  for (let i = 0; i < byteDataLength; i++)
    byteArray[i] = byteData.charCodeAt(i);

  return new Blob([byteArray], { type: encodignConfig });
}

export const fetchCatalog = (filter: any, values: any): any => {
  const { catalog, query } = filter;
  const queryDefaultParams =
    query.default === null ? [] : Object.assign({}, ...query.default);
  const queryReferenceParams = query.references === null ? [] : query.references.reduce((result, param) => {
    const refKey = Object.keys(param)[0];
    result[refKey] = values[param[refKey]];
    return result;
  }, {});

  const queryUrl = stringifyUrl({
    url: `${API_CATALOGS}${catalog}?`,
    query: { ...queryDefaultParams, ...queryReferenceParams },
  }); 

  return new Promise((resolve, reject) => {
    try {
      enterAPIManagerInstance({
        method: 'GET',
        url: `${queryUrl}`,
      })
        .then((response) => {
          resolve(response.data);
        })
        .catch((e) => {
          console.log(e.response);
          reject(e.response);
        });
    } catch (e) {
      console.log(e.response);
      reject(e.response);
    }
  });
}
