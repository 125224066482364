export default Object.freeze([
  {
    basicHtml: {
      ALLOWED_TAGS: [
        "a",
        "em",
        "strong",
        "cite",
        "blockquote",
        "code",
        "ul",
        "ol",
        "li",
        "dl",
        "dt",
        "dd",
        "h2",
        "h3",
        "h4",
        "h5",
        "h6",
        "p",
        "br",
        "span",
        "img"
      ],
      ALLOWED_ATTR: [
        "href",
        "hreflang",
        "cite",
        "type",
        "start",
        "id",
        "src",
        "alt",
        "height",
        "width",
        "data-entity-type",
        "data-entity-uuid",
        "data-align",
        "data-caption"
      ]
    
    }
  },
  {
    restrictedHtml: {
      ALLOWED_TAGS: [
        "a",
        "em",
        "strong",
        "cite",
        "blockquote",
        "code",
        "ul",
        "ol",
        "li",
        "dl",
        "dt",
        "dd",
        "h2",
        "h3",
        "h4",
        "h5",
        "h6",
      ],
      ALLOWED_ATTR: [
        "href",
        "hreflang",
        "cite",
        "type",
        "start",
        "id"
      ]
    }
  }
    
]);