import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { isNil } from 'ramda';

// HoC
import withField from 'hoc/withField';

// Variant
import Variant from '../variants/Variant';

interface FieldProps {
  field: any;
  helpers: any;
  meta: any;
}
interface WidgetProps extends FieldProps {
  help: string | any;
  multiple: [] | any;
  name: string;
  options: [] | any;
  widget: any;
}

const RadioField: React.FC<WidgetProps> = ({
  field,
  help,
  helpers,
  meta,
  name,
  options,
  widget,
}) => {
  const {
    settings: { variant },
  } = widget;

  const handleChange = (id) => {
    helpers.setValue(id, true);
  };

  return (
    <div className='checkbox-group'>
      {options.map((optn, i) => (
        <Variant
          key={`${name}-${i}`}
          {...field}
          type={variant}
          value={optn.value}
          label={optn.label}
          checked={field.value === optn.value}
          onChange={() => handleChange(optn.value)}
          disabled={!isNil(optn.disabled) ? optn.disabled : false}
          className={meta.touched && meta.error ? 'is-invalid' : ''}
          id={`${name}-${i}`}
        />
      ))}
      {meta.touched && meta.error && (
        <div className='invalid-feedback d-block'>{meta.error}</div>
      )}
      {help && <small className='form-text text-muted'>{help}</small>}
    </div>
  );
};

RadioField.propTypes = {
  field: PropTypes.shape({
    name: PropTypes.string.isRequired,
    onBlur: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    value: PropTypes.any,
  }),
  meta: PropTypes.shape({
    error: PropTypes.string,
    touched: PropTypes.bool,
  }),
  helpers: PropTypes.shape({
    setValue: PropTypes.func.isRequired,
  }),
  name: PropTypes.string.isRequired,
  widget: PropTypes.object.isRequired,
  options: PropTypes.array.isRequired,
  help: PropTypes.string,
};

RadioField.defaultProps = {
  multiple: null,
  help: null,
};

export default withField(RadioField); 
