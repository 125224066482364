import React from 'react';
import PropTypes from 'prop-types';
import { UncontrolledPopover as Popoverlement, PopoverBody } from 'reactstrap';

interface IProps {
  target: string,
  trigger?: string | any,
  placement?: string | any,
  children: React.ReactNode | any
}

const UncontrolledPopover:React.FC<IProps> = ({ target, placement, trigger, children }) => {
  return (
    <Popoverlement
      target={target}
      placement={placement}
      trigger={trigger} 
    >
      <PopoverBody>
        {children}
      </PopoverBody>
    </Popoverlement>
  );
}

UncontrolledPopover.propTypes = {
  target: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  placement: PropTypes.string,
  trigger: PropTypes.string
}

UncontrolledPopover.defaultProps = {
  placement: 'right',
  trigger: 'focus'
};

export default UncontrolledPopover;