import React from 'react';
import PropTypes from 'prop-types';
import { Collapse as CollapseWrapper } from 'reactstrap';

interface IProps {
  isOpen: boolean | any,
  children?: React.ReactNode | any
}
const Collapse: React.FC<IProps> = ({
  isOpen, children, ...rest
}) => {
  return (
    <CollapseWrapper
      isOpen={ isOpen }
      { ...rest }
    >
      { children }
    </CollapseWrapper>
  );
};

Collapse.propTypes = {
  children: PropTypes.node.isRequired,
  isOpen: PropTypes.bool,
};

Collapse.defaultProps = {
  isOpen: true,
};

export default Collapse;
