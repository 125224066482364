import React from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import PropTypes from 'prop-types';
import { isEmpty, clone } from 'ramda';

// Utils
import { cleanObject } from 'utils/object';

// Store
import { rootStore } from 'store/store';

// Actions
import { nextStep } from 'store/actions/stepperActions';
import { postStep } from 'api/dataFetching';

// Component
import Form from 'components/form/elements/Form';
import SubmitButton from 'components/form/elements/SubmitButton';

interface IComponents {
  type: string;
  value: string;
  settings: {
    alingment: string;
    element: string;
  };
  weight: number;
}

interface IProps {
  components: IComponents[] | any;
}

const StepForm: React.FC<IProps> = ({ components, ...settings }) => {
  const dispatch = useDispatch();
  const { currentSectionId } = useSelector(
    ({ sections: { currentSectionId } }: rootStore) => ({ currentSectionId }),
    shallowEqual
  );
  const { currentStepId } = useSelector(
    ({ stepper: { currentStepId } }: rootStore) => ({ currentStepId }),
    shallowEqual
  );
  const { recordId } = useSelector(
    ({
      initial: {
        init: { recordId },
      },
    }: rootStore) => ({ recordId }),
    shallowEqual
  );

  const onSubmit = (values, { setSubmitting, setErrors, setStatus }) => {
    const formData = cleanObject(clone(values));
    const payload = {
      stepId: currentStepId,
      sectionId: currentSectionId,
      formData: formData,
    };
    postStep(payload, recordId)
      .then(res => {
        if (res.status === 200) {
          dispatch(nextStep());
        }
      })
      .catch(err => {
        setStatus(err.data.error);
        !isEmpty(err.data.field) && setErrors({ [err.data.field]: err.data.error });
        setSubmitting(false);

        if (err.status === 401) {
          window.location.reload();
        }
      });
  };

  // const checkForGroups = components.some(element => element.type === 'group');
  // const groupedComponents = checkForGroups
  //   ? components
  //       .filter(component => component.type === 'group')
  //       .map(component => component.value)
  //   : [];
  // const nonGroupComponents = components.filter(
  //   component => component.type !== 'group'
  // );
  // const flattenedComponents = nonGroupComponents.concat(...groupedComponents);

  // const iValues = flattenedComponents.reduce(initialValues, {});
  // const vSchema = Yup.object().shape(
  //   flattenedComponents.reduce(validationSchema, {})
  // );

  return (
    <Form
      components={components}
      onSubmit={onSubmit}
      settings={settings}
    >
      <div className="form-actions">
        {
          /*!isFirstStep && (
            <button
              type="button"
              className="btn btn-sm-block btn-outline-secondary" 
              onClick={() => dispatch(backStep())}
              tabIndex={-1}
            >
              Regresar
            </button>
          )*/}
        <SubmitButton settings={settings}>Siguiente</SubmitButton>
      </div>
    </Form>
  );
};

StepForm.propTypes = {
  components: PropTypes.array.isRequired,
};

export default StepForm;
