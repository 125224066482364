import React from 'react';
import PropTypes from 'prop-types';
import { Modal as ModalWrapper } from 'reactstrap';

interface IProps {
  size?: string,
  modal: boolean,
  setModal: (modal: boolean) => void,
  children?: React.ReactNode | any
}
const Modal: React.FC<IProps> = ({
  size, modal, setModal, children, ...rest
}) => {
  const toggle = () => setModal(!modal);
  return (
    <ModalWrapper
      size={ size }
      isOpen={ modal }
      toggle={ toggle }
      { ...rest }
    >
      { children({ toggle }) }
    </ModalWrapper>
  );
};

Modal.propTypes = {
  children: PropTypes.func.isRequired,
  modal: PropTypes.bool.isRequired,
  setModal: PropTypes.func.isRequired,
  size: PropTypes.string,
};

Modal.defaultProps = {
  size: 'md',
};

export default Modal;
