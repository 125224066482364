import React, { useState, useEffect, useCallback } from 'react'
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import { shallowEqual, useSelector } from 'react-redux';
import { find, propEq, sortBy, prop, pathOr, concat, filter, where, flip, includes, reject, 
  not, compose, keys } from 'ramda';

// Store
import { rootStore } from 'store/store';

// Constants
import layouts from 'constants/layouts';
 
const withRegion = (Region: React.ElementType<any>, regionId: string):React.FC<any> => {
  
  const NewRegion = ({ className }) => {
    const { flowSection } = useSelector(({ sections: { flowSection } }: rootStore) => ({ flowSection }), shallowEqual);
    // const { loadingSections } = useSelector(({ sections }: rootStore) => sections);
    const [ components, setComponents ] = useState([]);

    const regionDefaultComponents = (layoutId, defaultComponents) => {
      const layoutRegions = find(propEq('id', layoutId), layouts);
      const regionDefaultComponents = pathOr([], ['components'], find(propEq('id', regionId), layoutRegions.regions));
      const defaultSettings = keys(filter(compose(not, propEq('show', true)), defaultComponents));
      const components = reject(where({type: flip(includes)(defaultSettings)}), regionDefaultComponents);
      return components;
    };

    const sortComp = () => {
      const defaultComponents = regionDefaultComponents(flowSection.layout.id, flowSection.components);
      const dynamiComponents = pathOr([], ['components'], find(propEq('id', regionId), flowSection.layout.regions))
      const allComponents = concat(defaultComponents, dynamiComponents);
      const sortedComponents = sortBy(prop('weight'), allComponents);
      setComponents(sortedComponents);
    }
    
    useEffect(() => {
      sortComp();
    }, []);
    
    const env = process.env.NODE_ENV;
    const classes = classNames(
      'region',
      // env === 'development' && 'region--highlight',
      className,
    );

    const customProps = React.useMemo(() => ({
      classes,
      components
    }), [classes, components]);

    return <Region {...customProps} />;
  }

  NewRegion.propTypes = {
    className: PropTypes.string,
  };
  
  return NewRegion;
}

withRegion.propTypes = {
  Region: PropTypes.elementType.isRequired,
  regionId: PropTypes.string.isRequired,
};

export default withRegion;
