import React, { FC } from 'react';
import PropTypes from 'prop-types';

interface IProps {
  classNames?: string;
  id: string;
  labels?: any; //do not use any
  imgUrl?: string;
  onClick?: () => void;
}

const ListCard: FC<IProps> = props => {
  const { id, classNames, labels, imgUrl, onClick } = props;

  const title = labels['field_1'];
  const fieldKeys = Object.keys(labels);

  return (
    <div className={`list-card box h-100 ${classNames}`} onClick={onClick}>
      <div className='box__body'>
        {imgUrl && (
          <div className='list-card__banner'>
            <img alt={`${title} list card`} src={imgUrl} />
          </div>
        )}
        <div className='mb-2'>{title}</div>
        <ul className='list-unstyled mb-0'>
          {fieldKeys.slice(1).map((label, index, arr) => (
            <li
              key={`list-card-label-${id}-${index}`}
              className={arr.length - 1 === index ? 'mb-0' : 'mb-1'}
            >
              {index === 0 ? (
                <span className='h5'>{labels[label]}</span>
              ) : (
                <span>{labels[label]}</span>
              )}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

ListCard.propTypes = {
  classNames: PropTypes.string,
  id: PropTypes.string.isRequired,
  labels: PropTypes.object,
  imgUrl: PropTypes.string,
  onClick: PropTypes.func,
};

export default ListCard;
