import React  from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { rootStore } from 'store/store';
interface IProps {
  alignment?: string | any
}
const OrgLogo: React.FC<IProps> = ({ alignment, ...rest }) => {
  const { init: { org: { 
    logo, 
    name 
  } } } = useSelector(({ initial }: rootStore) => initial);

  return (
    <div
      id="org-logo" 
      style={{textAlign: alignment}} 
      {...rest}
    >
      <img src={logo} alt={name} className="img-fluid" />
    </div>
  )
};

OrgLogo.propTypes = {
  alignment: PropTypes.string 
};

OrgLogo.defaultProps = {
  alignment: 'center'
};

export default OrgLogo;
