import React, { useCallback } from 'react';
import { useDebounce } from 'react-use';
import PropTypes from 'prop-types';
import { Formik, validateYupSchema, yupToFormErrors, useFormikContext } from 'formik';
import * as yup from 'yup';
import { isNil } from 'ramda';

// Utils
import { byPath } from 'utils/object'
import { logicalArrayEvaluator } from 'utils/array'

// Form
import initialValues from 'components/form/values/initialValues';
import validationSchema from 'components/form/validation/validationSchema';
import { test } from 'components/form/validation/test';

// Components
import UncontrolledAlert from 'components/common/UncontrolledAlert';
import FormField from 'components/form/FormField';
import ScrollToError from 'components/common/ScrollToError';

interface IProps {
  settings: any,
  components: [] | any,
  onSubmit: (arg: any, arg2: any) => void,
  children: React.ReactNode | any
}

const Form:React.FC<IProps> = ({ settings, components, children, onSubmit }) => {
  const { onValid } = settings;
  const cmpts = processComponents(components);
  // const iv = cmpts.reduce(initialValues, {});
  // const vs = yup.object().shape(cmpts.reduce(validationSchema, {}));

  const checkForGroups = cmpts.some(element => element.type === 'group');
  const groupedComponents = checkForGroups
    ? cmpts
        .filter(component => component.type === 'group')
        .map(component => component.value)
    : [];
  const nonGroupComponents = cmpts.filter(
    component => component.type !== 'group'
  );
  const flattenedComponents = nonGroupComponents.concat(...groupedComponents);
  const iv = flattenedComponents.reduce(initialValues, {});
  const vs = yup.object().shape(flattenedComponents.reduce(validationSchema, {}));

  function processComponents(array, path = '') {
    const arr = [...array];
    const fieldsArray = [];
    let childsArray = [];
    
    for (let i = 0; i < arr.length; i++) {
      if (path.length) {
        arr[i]['path'] = path + "." + arr[i]['name'];
      } else {
        arr[i]['path'] = arr[i]['name'];
      }
      if (arr[i]['type'] === 'object') {
        childsArray = processComponents(arr[i]['widget']['settings']['objectElements'], arr[i]['path'])
        arr[i]['widget']['settings']['objectElements'] = childsArray;
      }
      fieldsArray.push(arr[i]);
    }
    
    return fieldsArray;
  }
  
  function validations(values) {
    const conditionalValidations = (
      accum,
      { path,
        type,
        widget,
        validations
      }) => {
      let childs = []
      let merge = {}

      function evalFunc(obj: any): boolean {
        const basePath = path.includes('.') ? path.split(/\.(?=[^.]+$)/)[0] : null;
        const refPath = basePath ? basePath + '.' + obj.name : obj.name;
        const field = byPath(values, refPath);
        return test(obj.type, field, obj.params);
      }
     
      if (validations?.conditionals) {
        accum[path] = validations.conditionals.map(item => {
          return logicalArrayEvaluator(item.conditions, evalFunc) && item
        })
      } 
       
      if (type === 'object') {
        childs = widget.settings.objectElements
        merge = childs.reduce(conditionalValidations, {});
      }
      return {...accum, ...merge};
    }
    
    return cmpts.reduce(conditionalValidations, {});
  }
  
  const SubmitOnValid: React.FC = () => {
    const {values, dirty, isValid, submitForm, isSubmitting} = useFormikContext();
    const debouncedSubmit = useCallback(() => {
      if (dirty && isValid && !isSubmitting) {
        submitForm();
      }
    }, [dirty, isValid, submitForm, isSubmitting]);
  
    useDebounce(
      debouncedSubmit,
      500,
      [values]
    )
  
    return null
  } 

  return (
    <Formik
      isInitialValid={false}
      initialValues={iv}
      validate={values => {
        const cv = validations(values);
        try {
          validateYupSchema(values, vs, true,
            {
              validations: cv
            }
          );
        } catch (err) {
          return yupToFormErrors(err);
        }
        return {};
      }}
      onSubmit={onSubmit}
      enableReinitialize={true}
    >
      {({
        handleReset,
        handleSubmit,
        status
      }) => {
        return (
          <React.Fragment>
            {!isNil(status) && (
              <UncontrolledAlert color='danger'>
                <p className='mb-0'>{status}</p>
              </UncontrolledAlert>
            )}
            <form
              onReset={handleReset}
              onSubmit={handleSubmit}
              className="needs-validation"
              id="step-form"
            >
              {cmpts.map(element => (
                <FormField key={element.name} {...element} />
              ))}
              {children}
            </form>
            <ScrollToError />
            {onValid && <SubmitOnValid />}
          </React.Fragment>
        );
      }}
    </Formik>
  );
};

Form.propTypes = {
  components: PropTypes.array.isRequired,
  onSubmit: PropTypes.func.isRequired,
  settings: PropTypes.object.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export default Form;

