import React from 'react';
import PropTypes from 'prop-types';
import { clone } from 'ramda';

// HOC
import withField from 'hoc/withField';

// Components
import FormField from  'components/form/FormField';

interface FieldProps {
  field: any,
  meta: any,
  helpers: any,
  clearValue:() => void
}

interface WidgetProps extends FieldProps {
  name: string,
  widget: any,
  placeholder: string | any,
  help: string | any,
}

const ObjectField:React.FC<WidgetProps> = ({
  name,
  widget,
  help,
}) => {
  const {settings: {objectElements}} = widget;

  return (
    <React.Fragment>
      {clone(objectElements).map(element => {
        element.name = `${name}.${element.name}`;
        return (
          <FormField
            key={element.name}
            {...element}
          />
        );
      })}
      {help && (
        <small className="form-text text-muted">
          {help}
        </small>
      )}
    </React.Fragment>
  );
};

ObjectField.propTypes = {
  name: PropTypes.string.isRequired,
  widget: PropTypes.object.isRequired,
  help: PropTypes.string,
};

ObjectField.defaultProps = {
  help: null
};

export default withField(ObjectField);
