import React, { useEffect } from 'react';
import { Redirect, useParams } from 'react-router-dom';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

// Store
import { rootStore } from 'store/store';

// Actions
import { getSections } from 'store/actions/sectionsActions';

// Utils
import themeBuilder, { styleElement } from 'utils/themeBuilder';

// Hooks
import useDocumentTitle from "hook/useDocumentTitle";

// Components
import Stepper from 'components/flow/Stepper';

// Application Insight
import { useAppInsightsContext } from "@microsoft/applicationinsights-react-js";

interface IRouteInfo {
  orgId: string;
  flowId: string;
}

const Flow: React.FC = () => {
  const { orgId, flowId } = useParams<IRouteInfo>();
  const appInsights = useAppInsightsContext();
  appInsights.trackPageView({ message: 'On Flow component' });

  const dispatch = useDispatch();
  const { theme } = useSelector(({ initial: { init: { theme } } }: rootStore) => ({ theme }), shallowEqual);
  const { termsConditions } = useSelector(({
    initial: { init: { termsConditions } } }: rootStore) => ({ termsConditions }), shallowEqual);
  const { isAuthorized } = useSelector(({
    initial: { init: { isAuthorized } } }: rootStore) => ({ isAuthorized }), shallowEqual);
  const { name: org } = useSelector(({
    initial: { init: { org: { name } } } }: rootStore) => ({ name }), shallowEqual);
  const { name } = useSelector(({
    initial: { init: { flow: { name } } } }: rootStore) => ({ name }), shallowEqual);
  const { completed } = useSelector(({ initial: { completed } }: rootStore) => ({ completed }), shallowEqual);
  const { page, redirect } = useSelector(({
    initial: { init: { success: { page, redirect } } } }: rootStore) => ({ page, redirect }), shallowEqual);

  useDocumentTitle(`${name} - ${org}`);

  useEffect(() => {
    if (isAuthorized && termsConditions) {
      dispatch(getSections());
    }
  }, [dispatch]);

  useEffect(() => {
    const themeStyle = themeBuilder(theme);
    const style = styleElement();
    style.sheet.insertRule(themeStyle);
    document.body.classList.add('custom-theme');

    windowHeight();

    return () => { style.remove(); document.body.classList.remove('custom-theme') }
  }, [theme]);

  const windowHeight = () => {
    const vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  }

  window.addEventListener('resize', () => {
    windowHeight();
  });

  const SuccessRedirect = () => {
    if (page) {
      return <Redirect to={`/${orgId}/${flowId}/success`} />
    } else {
      window.location = redirect;
    }
    return null;
  }

  return (
    <React.Fragment>
      {
        !isAuthorized ? <Redirect to={`/${orgId}/${flowId}/auth`} /> : !termsConditions ? (
          <Redirect to={`/${orgId}/${flowId}/welcome`} />
        ) : completed ? (
          <SuccessRedirect />
        ) : (
          <Stepper />
        )
      }
    </React.Fragment>
  )
};

export default Flow;
