import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

interface IProps {
  name: string;
  id: string;
  label?: string;
  checked?: boolean;
  disabled?: boolean;
  className?: string | null;
  onChange?: () => void;
}

const Pill: React.FC<IProps> = props => {
  const { name, id, label, checked, disabled, className, onChange } = props;

  const classes = classNames(
    'pill',
    { 'pill--checked': checked },
    { 'pill--disabled': disabled },
    className
  );

  return (
    <label htmlFor={id} className={classes} tabIndex={0} onKeyPress={onChange}>
      <input
        name={name}
        checked={checked}
        type='checkbox'
        id={id}
        disabled={disabled}
        onChange={onChange}
      />
      {label}
    </label>
  );
};

Pill.propTypes = {
  name: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  checked: PropTypes.bool.isRequired,
  disabled: PropTypes.bool.isRequired,
  className: PropTypes.string,
  onChange: PropTypes.func.isRequired,
};

Pill.defaultProps = {
  className: null,
};

export default Pill;
