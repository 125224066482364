// Utils
import { replaceNull } from 'utils/object'

const initialValues = (iv, { name, multiple, type, value, widget }) => {
  switch (type) {
    case 'date':
    case 'string':
    case 'number':
    case 'boolean':
    default:
      iv[name] = value ? value : multiple ? [] : ''; 
      break;
    case 'object':
      if (value) {
        iv[name] = replaceNull(value);
      } else if (multiple) {
        iv[name] = [widget.settings.objectElements.reduce(initialValues, {})];
      } else {
        iv[name] = widget.settings.objectElements.reduce(initialValues, {});
      }
      break;
  }
  return iv;
};

export default initialValues;