import React, { useCallback } from 'react'
import PropTypes from 'prop-types';
import { useMediaQuery } from 'react-responsive';
import classNames from 'classnames/bind';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';

// Store
import { rootStore } from 'store/store';

// Actions
import { setCurrentSectionItem } from 'store/actions/sectionsActions';
import { closeMenu } from 'store/actions/offCanvasActions';

// Models
import { SectionInfo } from 'models';

interface IProps {
  section: SectionInfo[] | any
}

const SectionItem: React.FC<IProps> = ({ section }) => {
  const dispatch = useDispatch();
  const { currentSectionNumber } = useSelector(({
    sections: { currentSectionNumber }
  }: rootStore ) => ({ currentSectionNumber }), shallowEqual);
  const isMobile = useMediaQuery({maxWidth: 768});

  const dispatchCurrentSection = useCallback(sectionNumber => {
    isMobile && dispatch(closeMenu());
    currentSectionNumber !== sectionNumber && dispatch(setCurrentSectionItem(sectionNumber));
  }, [dispatch, isMobile, currentSectionNumber]);

  const classes = classNames(
    'section-item',
    {'active': currentSectionNumber === section.sectionNum && true},
    {'not-started': section.status === 'NOT-STARTED' && true},
    {'incompleted': section.status === 'INCOMPLETED' && true},
    {'completed': section.status === 'COMPLETED' && section.valid && true},
    {'invalid': section.status === 'COMPLETED' && section.valid === false && true},
  );

  return (
    <button
      type="button"
      disabled={!section.enabled}
      className={classes}
      onClick={() => dispatchCurrentSection(section.sectionNum)}
    >
      <i className="material-icons">{section.icon}</i>
      <span>{section.label}</span>
    </button>
  )
};

SectionItem.propTypes = {
  section: PropTypes.object.isRequired
};

export default SectionItem;