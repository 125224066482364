// Ssections
export const SECTIONS_LOADING = 'SECTIONS_LOADING';
export const SECTIONS_FAIL = 'SECTIONS_FAIL';
export const SECTIONS_SUCCESS = 'SECTIONS_SUCCESS';
export const SECTIONS_UPDATE_DISABLE = 'SECTIONS_UPDATE_DISABLE';
export const SECTIONS_SUCCESS_BY_ID = 'SECTIONS_SUCCESS_BY_ID';

// Section
export const SECTION_LOADING = 'SECTION_LOADING';
export const SECTION_FAIL = 'SECTION_FAIL';
export const SECTION_SUCCESS = 'SECTION_SUCCESS';
export const SECTION_COMPLETED = 'SECTION_COMPLETED';
export const SECTION_NUMBER = 'SECTION_NUMBER';
export const CURRENT_SECTION_ID = 'CURRENT_SECTION_ID';
export const CURRENT_SECTION_NUMBER = 'CURRENT_SECTION_NUMBER';
export const PREVIOUS_SECTION_ID = 'PREVIOUS_SECTION_ID';
export const PREVIOUS_SECTION_NUMBER = 'PREVIOUS_SECTION_NUMBER';
export const SECTION_RESET = 'SECTION_RESET';


// Steps
export const STEPS_LOADING = 'STEPS_LOADING';
export const STEPS_FAIL = 'STEPS_FAIL';
export const STEPS_SUCCESS = 'STEPS_SUCCESS';
export const STEPS_SUCCESS_BY_ID = 'STEPS_SUCCESS_BY_ID';
export const STEPS_SUCCESS_BY_ID_LOADING = 'STEPS_SUCCESS_BY_ID_LOADING';

// Step
export const STEP_LOADING = 'STEP_LOADING';
export const STEP_FAIL = 'STEP_FAIL';
export const STEP_SUCCESS = 'STEP_SUCCESS';
export const STEP_COMPLETED = 'STEP_COMPLETED';
export const STEP_NUMBER = 'STEP_NUMBER';
export const STEP_BACK = 'STEP_BACK';
export const NAV_DIRECTION = 'NAV_DIRECTION';
export const CURRENT_STEP_ID = 'CURRENT_STEP_ID';
export const CURRENT_STEP_NUMBER = 'CURRENT_STEP_NUMBER';
export const LAST_STEP = 'LAST_STEP';
export const RESET_CURRENT_STEP_ID = 'RESET_CURRENT_STEP_ID';

// Init
export const INIT_SUCCESS = 'INIT_SUCCESS';
export const INIT_LOADING = 'INIT_LOADING';
export const INIT_FAIL = 'INIT_FAIL';
export const INIT_ERROR = 'INIT_ERROR';
export const INIT_STATUS = 'INIT_STATUS';
export const SUCCESS_STATUS = 'SUCCESS_STATUS';
export const INIT_COMPLETED = 'INIT_COMPLETED';

// offCanvas
export const TOGGLE_MENU = 'TOGGLE_MENU';
export const CLOSE_MENU = 'CLOSE_MENU';

export const REDIRECT = 'REDIRECT';
