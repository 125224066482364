import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

// Enum
import { Events } from 'enums/events'
import { Actions } from 'enums/actions'

// utils
import { redirect } from 'utils/sharedFuncs'

// Components
import Heading from 'components/common/Heading';
import Image from 'components/common/Image';
import PdfBook from 'components/common/PdfBook';
import Video from 'components/common/Video';
import SectionsNav from 'components/sectionsNav/SectionsNav';
import TextContainer from 'components/common/TextContainer';
import Steps from 'components/flow/steps/Steps'
import StepForm from 'components/flow/steps/StepForm';
import OrgLogo from 'components/OrgLogo';
import OrgAbout from 'components/OrgAbout';
import FlowTitle from 'components/FlowTitle';
import LanguageSwitcher from 'components/LanguageSwitcher';
import NavToogle from 'components/NavToggle';
import BasicHtml from 'components/common/BasicHtml';
import RestrictedHtml from 'components/common/RestrictedHtml';
import FullHtml from 'components/common/FullHtml';
import Button from 'components/common/Button';
import { navigateBack, navigateTo } from 'store/actions/stepperActions';

interface ISchema {
  value?: string | null | [] | any
  settings?: [] | null | any,
  events?: [] | null | any,
  type?: string | any
}

interface IProps {
  elementSchema: ISchema
}

const eventBuilder = dispatch => (events, evnt) => {
  const { event: ev, type, params } = evnt;
  const event: string = Object.keys(Events).find(key => Events[key] === ev) || '';
  const action: string = Object.keys(Actions).find(key => Actions[key] === type) || '';

  switch (Actions[action]) {
    case Actions.NAVIGATE_TO:
      events[Events[event]] = () => dispatch(navigateTo(params));
      break;
    case Actions.NAVIGATE_BACK:
      events[event] = () => dispatch(navigateBack());
      break;
    case Actions.REDIRECT:
      events[Events[event]] = () => redirect(params);
      break;
    default: events[Events[event]] = () => {return false;}
      break;
  }
  return events;
}

const RenderComponent: React.FC<IProps> = ({ elementSchema }) => {
  const dispatch = useDispatch();
  const settings = elementSchema.settings?.reduce((obj, item) => Object.assign(obj, { [item.type]: item.param }), {});
  const events = elementSchema.events?.reduce(eventBuilder(dispatch), {}); 

  switch (elementSchema.type) {
    case 'heading':
      return <Heading text={elementSchema.value} {...settings} {...events} />;
    case 'textContainer':
      return <TextContainer text={elementSchema.value} {...settings} />;
    case 'image':
      return <Image src={elementSchema.value} {...settings} {...events} />;
    case 'video':
      return <Video src={elementSchema.value} {...settings} {...events} />;
    case 'pdfBook':
      return <PdfBook file={elementSchema.value} {...settings} />;
    case 'sectionsNav':
      return <SectionsNav {...settings} />;
    case 'steps':
      return <Steps {...settings} />;
    case 'form':
      return <StepForm components={elementSchema.value} {...settings} />;
    case 'orgLogo':
      return <OrgLogo {...settings} />;
    case 'orgAbout':
      return <OrgAbout {...settings} />;
    case 'flowTitle':
      return <FlowTitle {...settings} />;
    case 'languageSwitcher':
      return <LanguageSwitcher {...settings} />;
    case 'navToggle':
      return <NavToogle {...settings} />;
    case 'basicHtml':
      return <BasicHtml type={elementSchema.type} html={elementSchema.value} {...settings} />;
    case 'restrictedHtml':
      return <RestrictedHtml type={elementSchema.type} html={elementSchema.value} {...settings} />;
    case 'fullHtml':
      return <FullHtml html={elementSchema.value} {...settings} />;
    case 'button':
      return <Button value={elementSchema.value} {...events} />
    default:
      return null;
  }
};

RenderComponent.propTypes = {
  elementSchema: PropTypes.shape({
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.array,
      PropTypes.oneOf([null])
    ]),
    settings: PropTypes.oneOfType([
      PropTypes.array,
      PropTypes.oneOf([null])
    ]),
    events: PropTypes.oneOfType([
      PropTypes.array,
      PropTypes.oneOf([null])
    ]),
    type: PropTypes.string
  }).isRequired,
};

export default memo(RenderComponent);