import React from 'react';
import PropTypes from 'prop-types';
import uuid from 'react-uuid';

// HOC
import withRegion from 'hoc/withRegion';

// Components
import RenderComponent from 'components/flow/RenderComponent';

const Header = ({ classes, components }) => {
  return (
    <header className={classes} id="header">
      <div className="region__info">
        <div className="region-name">Region: Header</div>
      </div>
      <div className="region__content">
        {
          components?.map(component => (
            <RenderComponent
              key={uuid()}
              elementSchema={component}
            />
          ))
        }
      </div>
    </header>
  );
};

Header.propTypes = {
  classes: PropTypes.string,
  components: PropTypes.array,
};

Header.defaultProps = {
  classes: '',
  components: [],
};

export default withRegion(Header, 'header');
